import { useEffect, useState } from "react";
import NewsPage01 from "../compressed/NewsPage01.webp";
import ProjectPage01 from "../compressed/ProjectPage01.webp";
import CompanyPage01 from "../compressed/CompanyPage01.webp";
import RecruitPage01 from "../compressed/RecruitPage01.webp";
import ContactPage01 from "../compressed/ContactPage01.webp";
import Recruit03 from "../compressed/Recruit03.webp";
import Recruit02 from "../compressed/Recruit02.webp";

// メインビジュアルを表示
export const Content = () => {
  const capitalize = (text) => text.toUpperCase();

  return (
    <div className="w-full h-full relative">
      <div className="firstLoadedVisual absolute w-full h-full">
        <div className="flex h-full w-full">
          <div className="imageItem imageItem01 relative">
            <img loading="lazy" src={CompanyPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay01 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem02 relative">
            <img loading="lazy" src={ContactPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay02 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem03 relative">
            <img loading="lazy" src={ProjectPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay03 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem04 relative">
            <img loading="lazy" src={Recruit03} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay04 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem05 relative">
            <img loading="lazy" src={RecruitPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay05 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem06 relative">
            <img loading="lazy" src={NewsPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay06 absolute w-full h-full"></div>
          </div>
          <div className="imageItem imageItem07 relative">
            <img loading="lazy" src={Recruit02} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay07 absolute w-full h-full"></div>
          </div>
        </div>
      </div>
      <div className="firstLoadedText width100vw flex align-end justify-center w-full h-full">
        <span>{capitalize("the battle")}</span>
      </div>
      <div className="leftUpperText absolute">
        <div className="flex column align-center justify-center w-full">
          <span>{capitalize("the")}</span>
          <span>{capitalize("battle")}</span>
        </div>
      </div>
    </div>
  );
};

// THE BATTLEの文字を表示
export const Loading = () => {
  const capitalize = (text) => text.toUpperCase();

  return (
    <div className="firstText width100vw flex align-end justify-center w-full h-full">
      <span>{capitalize("the battle")}</span>
    </div>
  );
};

// SP版メインビジュアルを表示
export const SPContent = () => {
  const capitalize = (text) => text.toUpperCase();
  return (
    <div className="SPVisualContent w-full relative">
      <div className="absolute w-full h-full">
        <div className="flex column w-full h-full">
          <div className="SPImageItem SPImageItem01 relative">
            <img loading="lazy" src={CompanyPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay01 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem02 relative">
            <img loading="lazy" src={ContactPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay02 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem03 relative">
            <img loading="lazy" src={ProjectPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay03 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem04 relative">
            <img loading="lazy" src={Recruit03} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay04 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem05 relative">
            <img loading="lazy" src={RecruitPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay05 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem06 relative">
            <img loading="lazy" src={NewsPage01} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay06 absolute w-full h-full"></div>
          </div>
          <div className="SPImageItem SPImageItem07 relative">
            <img loading="lazy" src={Recruit02} alt="" className="w-full h-full" />
            <div className="whiteOverlay overlay07 absolute w-full h-full"></div>
          </div>
        </div>
      </div>
      <div className="SPfirstLoadedText flex column align-start justify-center w-full h-full">
        <div className="blank w-full"></div>
        <div className="blank w-full"></div>
        <div className="blank text_the w-full flex align-end justify-start">
          <span>{capitalize("the")}</span>
        </div>
        <div className="blank text_battle w-full flex align-end justify-start">
          <span>{capitalize("battle")}</span>
        </div>
        <div className="blank w-full"></div>
        <div className="blank w-full"></div>
        <div className="blank w-full"></div>
      </div>
      {/* <div className="SPleftUpperText absolute">
        <div className="flex column align-center justify-start w-full">
          <span>{capitalize("the")}</span>
          <span>{capitalize("battle")}</span>
        </div>
      </div> */}
      {/* <div className="dammy absolute"></div> */}
    </div>
  );
};

// THE BATTLEの文字を表示
export const SPLoading = () => {
  const capitalize = (text) => text.toUpperCase();

  return (
    <div className="SPfirstText width100vw flex column  align-start justify-center w-full h-full">
      <div className="blank w-full"></div>
      <div className="blank w-full"></div>
      <div className="blank text_the w-full flex align-end justify-start">
        <span>{capitalize("the")}</span>
      </div>
      <div className="blank text_battle w-full flex align-end justify-start">
        <span>{capitalize("battle")}</span>
      </div>
      <div className="blank w-full"></div>
      <div className="blank w-full"></div>
      <div className="blank w-full"></div>
      {/* <div className="SPleftUpperText absolute">
        <div className="flex column align-center justify-start w-full">
          <span>{capitalize("the")}</span>
          <span>{capitalize("battle")}</span>
        </div>
      </div> */}
    </div>
  );
};

// メインビジュアル全体のコンポーネント
const MainVisual = ({ useWindowWidth }) => {
  const [loaded, setLoaded] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 3000); // 3秒待つ
    return () => clearTimeout(timer); // コンポーネントがアンマウントされたときにタイマーをクリア
  }, []); // 空の配列を依存性リストとして指定することで、このuseEffectはコンポーネントがマウントされたときに一度だけ実行される

  if (windowWidth > 496) {
    return (
      <section id="MainVisual" className="scrollContent">
        {loaded ? <Content /> : <Loading />}
      </section>
    );
  } else {
    return (
      <section id="SPMainVisual" className="scrollContent">
        {loaded ? <SPContent /> : <SPLoading />}
      </section>
    );
  }
};

export default MainVisual;
