import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SliderButton } from "@typeform/embed-react";

import NewsPage01 from "../compressed/NewsPage01.webp";
import battleLogo from "../compressed/battleLogo.webp";
import SPNews01 from "../compressed/SPNews01.webp";
import articleImage2 from "../compressed/articleImage2.webp";
import articleImage3 from "../compressed/articleImage3.webp";
import articleImage4 from "../compressed/articleImage4.webp";
import articleImage5 from "../compressed/articleImage5.webp";

const NewsPage = ({
  useWindowWidth,
  handlerToggle,
  contactDetailBlurInView,
}) => {
  const capitalize = (text) => text.toUpperCase();
  const windowWidth = useWindowWidth();
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <>
        <section id="NewsPage" className="NewsPage flex" ref={blur.ref}>
          {contactDetailBlurInView ? (
            <></>
          ) : (
            <header className="flex column align-center justify-between PCHeaderDetailAnimation">
              <div className="headerImage flex align-center justify-center">
                <Link to="/">
                  <img loading="lazy" src={battleLogo} alt="" />
                </Link>
              </div>
              <ul className="flex align-center justify-center vertical_rl">
                <li>
                  <Link to="/">{capitalize("home")}</Link>
                </li>
                <li>
                  <Link to="/news" className="strike-out-line">
                    {capitalize("news")}
                  </Link>
                </li>
                <li>
                  <Link to="/projects">{capitalize("projects")}</Link>
                </li>
                <li>
                  <Link to="/company">{capitalize("company")}</Link>
                </li>
                {/* <li>
                  <Link to="/recruit">{capitalize("recruit")}</Link>
                </li> */}
              </ul>
            </header>
          )}
          <div className="Container page_contanier flex">
            <div className="upper flex column justify-between w-full h-full">
              {blur.inView && (
                <div className="BigNumber blurNumber w-full">
                  <h1 className="w-full user-select-none">01</h1>
                </div>
              )}
              <div className="downer flex">
                <div
                  className={`subTitle flex align-end ${
                    blur.inView ? "blurTitle" : "invisible"
                  }`}
                >
                  <span className="user-select-none">{capitalize("news")}</span>
                </div>
                {blur.inView && (
                  <div className="text flex column align-end justify-end gap-20 blurText">
                    <div className="jaText">
                      <span>
                        わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                      </span>
                    </div>
                    <div className="enText">
                      <span>
                        We continue to explore the possibilities of "web3 x
                        entertainment" utilizing blockchain and NFT
                        technologies. We sincerely hope that the "things" we
                        create will contribute to the continuation and
                        development of Japan's creative culture, and by
                        extension, the sustainability of Otaku culture around
                        the world.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="NewsPageLeft flex column">
              <img loading="lazy" src={NewsPage01} alt="" className="w-full" />
            </div>
          </div>
        </section>
        <section id="NewsDetail" className="NewsDetail flex Cormorant_Garamond">
          {/* 各ニュースコンテンツここから OK */}
          <div className="NewsDetailContainer flex w-full h-full">
            <div className="dateLine h-full flex align-start justify-end">
              <div className="vertical_rl">2023</div>
            </div>
            <div className="content align-center justify-between flex column w-full">
              <div className="subContent flex column align-start justify-center w-full">
                <div className="contentUp flex align-center justify-center">
                  <div className="date">
                    <span>{"06.02(fri)"}</span>
                  </div>
                </div>
                <div className="contentDown flex column align-start justify-between h-full">
                  <div className="newsImage flex align-start justify-center">
                    <Link to="/news/topic-01">
                      <img loading="lazy" src={articleImage2} alt="" />
                    </Link>
                  </div>
                  <div className="newsContent flex column">
                    <Link to="/news/topic-01" className="flex column gap-12">
                      <div className="mainTitle">
                        <span>
                          NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                        </span>
                      </div>
                      <div className="article">
                        <span>
                          The world's first TV anime series born from NFTs is
                          now a reality. The series, entitled "Shinobanai!
                          CryptoNinja Sakura," is based on the original
                          "CryptoNinja" and is set to premiere in October 2023.
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contentRight flex align-end justify-end w-full">
                <span>
                  <Link to="/news/topic-01">{capitalize("view more")}</Link>
                </span>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 各ニュースコンテンツここから OK */}
          <div className="NewsDetailContainer flex w-full h-full">
            <div className="dateLine h-full flex align-start justify-end">
              <div className="vertical_rl">2023</div>
            </div>
            <div className="content align-center justify-between flex column w-full">
              <div className="subContent flex column align-start justify-center w-full">
                <div className="contentUp flex align-center justify-center">
                  <div className="date">
                    <span>{"04.27 (thu)"}</span>
                  </div>
                </div>
                <div className="contentDown flex column align-start justify-between h-full">
                  <div className="newsImage flex align-start justify-center">
                    <Link to="/news/topic-02">
                      <img loading="lazy" src={articleImage4} alt="" />
                    </Link>
                  </div>
                  <div className="newsContent flex column">
                    <Link to="/news/topic-02" className="flex column gap-12">
                      <div className="mainTitle">
                        <span>
                          アニメーション事業を手掛ける「ABCアニメーション」とweb3法人「THE
                          BATTLE」が、NFTからIP展開を行う新法人「WAGMI
                          Records」設立と第一弾プロジェクトの採用を発表
                        </span>
                      </div>
                      <div className="article">
                        <span>
                          The animation business, "ABC Animation," and the web3
                          corporation, "THE BATTLE," have announced the
                          establishment of a new corporation, "WAGMI Records,"
                          aimed at IP development from NFTs, as well as the
                          adoption of their first project.
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contentRight flex align-end justify-end w-full">
                <span>
                  <Link to="/news/topic-02">{capitalize("view more")}</Link>
                </span>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 各ニュースコンテンツここから OK */}
          <div className="NewsDetailContainer flex w-full h-full">
            <div className="dateLine h-full flex align-start justify-end">
              <div className="vertical_rl">2022</div>
            </div>
            <div className="content align-center justify-between flex column w-full">
              <div className="subContent flex column align-start justify-center w-full">
                <div className="contentUp flex align-center justify-center">
                  <div className="date">
                    <span>{"12.09 (fri)"}</span>
                  </div>
                </div>
                <div className="contentDown flex column align-start justify-between h-full">
                  <div className="newsImage flex align-start justify-center">
                    <Link to="/news/topic-03">
                      <img loading="lazy" src={articleImage3} alt="" />
                    </Link>
                  </div>
                  <div className="newsContent flex column">
                    <Link to="/news/topic-03" className="flex column gap-12">
                      <div className="mainTitle">
                        <span>
                          フルオンチェーンゲーム『Isekai
                          Battle』メインネットで探索モードが解禁。未公開の新キャラが貰える「種」集めランキングイベント開催
                        </span>
                      </div>
                      <div className="article">
                        <span>
                          Full-on-chain game "Isekai Battle" exploration mode is
                          unlocked on the main net. A ranking event for
                          collecting "seeds" to receive an unrevealed new
                          character is held.
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contentRight flex align-end justify-end w-full">
                <span>
                  <Link to="/news/topic-03">{capitalize("view more")}</Link>
                </span>
              </div>
            </div>
          </div>
          {/* ここまで */}
          {/* 各ニュースコンテンツここから OK */}
          <div className="NewsDetailContainer flex w-full h-full">
            <div className="dateLine h-full flex align-start justify-end">
              <div className="vertical_rl">2022</div>
            </div>
            <div className="content align-center justify-between flex column w-full">
              <div className="subContent flex column align-start justify-center w-full">
                <div className="contentUp flex align-center justify-center">
                  <div className="date">
                    <span>{"11.14 (mon)"}</span>
                  </div>
                </div>
                <div className="contentDown flex column align-start justify-between h-full">
                  <div className="newsImage flex align-start justify-center">
                    <Link to="/news/topic-04">
                      <img loading="lazy" src={articleImage5} alt="" />
                    </Link>
                  </div>
                  <div className="newsContent flex column">
                    <Link to="/news/topic-04" className="flex column gap-12">
                      <div className="mainTitle">
                        <span>
                          NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                        </span>
                      </div>
                      <div className="article">
                        <span>
                          Next Generation Crowdfunding using NFT raises a total
                          of 38 Million yen in one minute. Realized immediate
                          cash arrival with crypto assets and intermediate
                          margin such as settlement fees.
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="contentRight flex align-end justify-end w-full">
                <span>
                  <Link to="/news/topic-04">{capitalize("view more")}</Link>
                </span>
              </div>
            </div>
          </div>
          {/* ここまで */}

          {/*  */}
        </section>
      </>
    );
  } else {
    return (
      <section className="spNewsPage" ref={blur.ref}>
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed mix_difference">
            <SliderButton id="wJm1bGdB" className="SPtypeformBtn">
              {capitalize("contact")}
            </SliderButton>
          </div>
          <div className="spDetailDowner flex column relative">
            {blur.inView && (
              <div className="titlenumber absolute blurNumber">
                <span>01</span>
              </div>
            )}
            <div className="titleimage relative">
              <div
                className={`absolute white mix_difference ${
                  blur.inView ? "blurTitle" : ""
                }`}
              >
                <span>{capitalize("news")}</span>
              </div>
              <div className="">
                <img loading="lazy" src={SPNews01} alt="" className="w-full" />
              </div>
            </div>
            {blur.inView && (
              <div className="titletext flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    We continue to explore the possibilities of "web3 x
                    entertainment" utilizing blockchain and NFT technologies. We
                    sincerely hope that the "things" we create will contribute
                    to the continuation and development of Japan's creative
                    culture, and by extension, the sustainability of Otaku
                    culture around the world.
                  </span>
                </div>
              </div>
            )}

            {/* ニュース1つ目 */}
            <div className="content flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="year w-full">
                  <span>2023</span>
                </div>
                <div className="date-image flex align-start justify-between w-full">
                  <div className="date">
                    <span className="first">06.02</span>
                    <span className="second">（fri）</span>
                  </div>
                  <div className="image">
                    <img loading="lazy" src={articleImage2} alt="" className="w-full" />
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      The world's first TV anime series born from NFTs is now a
                      reality. The series, entitled "Shinobanai! CryptoNinja
                      Sakura," is based on the original "CryptoNinja" and is set
                      to premiere in October 2023.
                    </span>
                  </div>
                </div>
              </div>
              <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link to="/news/topic-01">{capitalize("all view")}</Link>
                </span>
              </div>
            </div>
            {/* ニュース2つ目 OK */}
            <div className="content flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="year w-full">
                  <span>2023</span>
                </div>
                <div className="date-image flex align-start justify-between w-full">
                  <div className="date">
                    <span className="first">04.27</span>
                    <span className="second">（thu）</span>
                  </div>
                  <div className="image">
                    <img loading="lazy" src={articleImage4} alt="" className="w-full" />
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      アニメーション事業を手掛ける「ABCアニメーション」とweb3法人「THE
                      BATTLE」が、NFTからIP展開を行う新法人「WAGMI
                      Records」設立と第一弾プロジェクトの採用を発表
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      The animation business, "ABC Animation," and the web3
                      corporation, "THE BATTLE," have announced the
                      establishment of a new corporation, "WAGMI Records," aimed
                      at IP development from NFTs, as well as the adoption of
                      their first project.
                    </span>
                  </div>
                </div>
              </div>
              <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link to="/news/topic-02">{capitalize("all view")}</Link>
                </span>
              </div>
            </div>
            {/* ニュース3つ目 OK */}
            <div className="content flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="year w-full">
                  <span>2022</span>
                </div>
                <div className="date-image flex align-start justify-between w-full">
                  <div className="date">
                    <span className="first">12.09</span>
                    <span className="second">（fri）</span>
                  </div>
                  <div className="image">
                    <img loading="lazy" src={articleImage3} alt="" className="w-full" />
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      フルオンチェーンゲーム『Isekai
                      Battle』メインネットで探索モードが解禁。未公開の新キャラが貰える「種」集めランキングイベント開催
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Full-on-chain game "Isekai Battle" exploration mode is
                      unlocked on the main net. A ranking event for collecting
                      "seeds" to receive an unrevealed new character is held.
                    </span>
                  </div>
                </div>
              </div>
              <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link to="/news/topic-03">{capitalize("all view")}</Link>
                </span>
              </div>
            </div>
            {/* ニュース4つ目 OK */}
            <div className="content lastContent flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="year w-full">
                  <span>2023</span>
                </div>
                <div className="date-image flex align-start justify-between w-full">
                  <div className="date">
                    <span className="first">11.14</span>
                    <span className="second">（mon）</span>
                  </div>
                  <div className="image">
                    <img loading="lazy" src={articleImage5} alt="" className="w-full" />
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Next Generation Crowdfunding using NFT raises a total of
                      38 Million yen in one minute. Realized immediate cash
                      arrival with crypto assets and intermediate margin such as
                      settlement fees.
                    </span>
                  </div>
                </div>
              </div>
              <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link to="/news/topic-04">{capitalize("all view")}</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default NewsPage;
