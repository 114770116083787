import { Link } from "react-router-dom";
import YouTube from "react-youtube";

import battleLogo from "../compressed/battleLogo.webp";
import articleImage2 from "../compressed/articleImage2.webp";
import passportNft from "../compressed/passportNft.webp";

import HorimotoNagisa from "../compressed/HorimotoNagisa.webp";
import MotodaniShirori from "../compressed/MotodaniShirori.webp";
import UchidaAya from "../compressed/UchidaAya.webp";
import KinoshitaAkitsugu from "../compressed/KinoshitaAkitsugu.webp";
import Toko from "../compressed/Toko.webp";
import KanekoMakoto from "../compressed/KanekoMakoto.webp";
import ikehaya from "../compressed/ikehaya.webp";
import sakuya_tall from "../compressed/sakuya_tall.webp";
import nemu_tall from "../compressed/nemu_tall.webp";
import syaoran_tall from "../compressed/syaoran_tall.webp";
import ganzi_tall from "../compressed/ganzi_tall.webp";
import kohaku_tall from "../compressed/kohaku_tall.webp";
import hayate_tall from "../compressed/hayate_tall.webp";
import topic101 from "../compressed/topic101.webp";

const Topic1 = ({ useWindowWidth, handlerToggle }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();

  const opts = {
    height: "160",
    width: "300",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  if (windowWidth > 496) {
    return (
      <section id="EachNews" className="">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex gap10">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <img loading="lazy" src={articleImage2} alt="" className="w-full" />
            </div>
          </aside>
          <main className="main flex column align-start justify-start gap-100">
            {/* タイトル */}
            <div className="title flex column gap-20">
              <div className="bigTitle ja">
                <span>
                  NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                </span>
              </div>
              <div className="en">
                <span>
                  The world's first TV anime series born from NFTs is now a
                  reality. The series, entitled "Shinobanai! CryptoNinja
                  Sakura," is based on the original "CryptoNinja" and is set to
                  premiere in October 2023.
                </span>
              </div>
            </div>
            {/* アニメ作品オリジナルのジェネラティブNFT発売も決定、「パスポートNFT」と「手裏剣NFT」の追加申し込みも開始 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    アニメ作品オリジナルのジェネラティブNFT発売も決定、「パスポートNFT」と「手裏剣NFT」の追加申し込みも開始
                  </span>
                </div>
                <div className="article">
                  <span>
                    ブロックチェーンゲームやNFT等のIPを活用したアニメ制作などを手がける株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木
                    清龍）は、国内最大規模のNFTコミュニティーを誇る『CryptoNinja』のTVアニメシリーズとして「忍ばない！クリプトニンジャ咲耶」の2023年10月より放送が決定したことをお知らせいたします。本作品は世界初(※)のNFT発のTVアニメーションとなり、昨年11月に公開したPVに引き続き、アニメーション制作はファンワークスが担当いたします。
                  </span>
                </div>
                <div className="article">
                  <span>
                    ※各国NFT領域におけるコンテンツ展開として（2023年6月時点、自社調べ）
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    In addition, we've decided to release generative NFTs
                    originated from the anime. We have also started accepting
                    applications for additional "Passport NFTs" and "Shuriken
                    NFTs".
                  </span>
                </div>
                <div className="article">
                  <span>
                    The Battle Co., Ltd. (Headquartered in Nakano, Tokyo, CEO:
                    Kiyoryu Sasaki), a company that works on anime production
                    utilizing IPs such as blockchain games and NFTs, is proud to
                    announce that the TV anime series "Shinobanai! CryptoNinja
                    Sakura," which boasts the largest NFT community in Japan,
                    "CryptoNinja," is set to broadcast from October 2023. This
                    series is the world's first TV animation originating from
                    NFTs.* Following the preview released last November, the
                    animation production will be handled by Fanworks. *(as of
                    June 2023, according to our research).
                  </span>
                </div>
              </div>
            </div>
            {/* ジェネラティブNFT販売について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>ジェネラティブNFT販売について</span>
                </div>
                <div className="article">
                  <span>
                    「忍ばない！クリプトニンジャ咲耶」のキャラクターたちが描かれる、NFT(※)がTV放映にあわせて発売予定です。咲耶たちのかわいいデジタルアートをぜひ手に入れてみてください！
                  </span>
                </div>
                <div className="article">
                  <span>
                    ※NFT(Non-Fungible
                    Token、非代替性トークン)とはブロックチェーンを基盤にして作成された代替不可能なデジタルデータのことです。
                    ※各NFTの詳細につきましては、下記『CryptoAnime
                    Labs』公式Twitterからの追加情報をお待ちください。
                  </span>
                </div>
                <div className="article">
                  <span>
                    <a
                      href="https://twitter.com/CryptoAnimeLabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CryptoAnimeLabs
                    </a>
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売サイト</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;coming soon</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売開始日時</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;2023年秋</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売価格</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;0.1ETH前後</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>発行枚数</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;5555枚（予定）</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>ユーティリティー</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;coming soon</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    ※イーサリアムの価格など市況に応じて内容を変更する場合がございます。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>About Generative NFT Sales</span>
                </div>
                <div className="article">
                  <span>
                    NFTs* featuring the characters of "Shinobanai! CryptoNinja
                    Sakuya" are planned to be sold in conjunction with the TV
                    broadcast. Please consider acquiring these cute digital arts
                    of Sakuya and her friends!
                  </span>
                </div>
                <div className="article">
                  <span>
                    *NFT (Non-Fungible Token) refers to irreplaceable digital
                    data created based on blockchain technology. *For more
                    details about each NFT, please wait for additional
                    information from the official "CryptoAnime Labs" Twitter
                    account below.
                  </span>
                </div>
                <div className="article">
                  <span>
                    <a
                      href="https://twitter.com/CryptoAnimeLabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CryptoAnimeLabs
                    </a>
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Sale site</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;coming soon</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Sale date</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Autumn 2023</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Price</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;around 0.1ETH</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Number of supply</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;5555 (planned)</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Utility</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;coming soon</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    Please note that the price may change depending on the price
                    of Ethereum and market conditions.
                  </span>
                </div>
              </div>
            </div>
            {/* パスポートNFT&手裏剣NFTの追加販売について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>パスポートNFT&手裏剣NFTの追加販売について</span>
                </div>
                <div className="article">
                  <span>
                    昨年11月より販売しております第３期パスポートNFTと手裏剣NFTを新たに追加販売いたします。パスポートNFTは手裏剣NFTをステーキングした枚数に応じてランクアップし、Discordコミュニティ参加権や特別なチャンネルへの招待権、ジェネラティブNFTのAL獲得権などが付与されます。
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>第3期パスポートNFT事前申込URL</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://forms.gle/riaoNkXApnKK53nz6"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://forms.gle/riaoNkXApnKK53nz6
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>申込期限</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;6/18（日）23:59 [JST]</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売サイト</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://cryptoanimelabs.xyz"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://cryptoanimelabs.xyz
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売開始日時</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;2023年6月下旬</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    About Additional Sales of Passport NFT & Shuriken NFT
                  </span>
                </div>
                <div className="article">
                  <span>
                    We will be conducting new sales of the 3rd Passport NFT and
                    Shuriken NFT, which have been on sale since November last
                    year. The Passport NFT ranks up according to the number of
                    Shuriken NFTs staked, and grants rights such as
                    participation in the Discord community, invitation to
                    special channels, and the right to acquire AL of Generative
                    NFT.
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>3rd Passport NFT pre-registration URL</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://forms.gle/riaoNkXApnKK53nz6"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://forms.gle/riaoNkXApnKK53nz6
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Pre-registration deadline</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;6/18 (Sun) 23:59 [JST]</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Sale site</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://cryptoanimelabs.xyz"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://cryptoanimelabs.xyz
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Sale date</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Late June 2023</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  ■第3期ラボメンバー・パスポートNFT（イーサリアム・フルオンチェーン） */}
            <div className="topic flex column gap-20">
              <div className="ja flex column gap-20">
                <div className="subsubtitle">
                  <span>
                    ■&nbsp;第3期ラボメンバー・パスポートNFT（イーサリアム・フルオンチェーン）
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;3rd Lab Member Passport NFT
                    (Ethereum Full On Chain)
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={passportNft} alt="" className="w-90" />
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売価格</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;0.02ETH/1枚</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売枚数</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;1人1枚限定で販売</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>仕様</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;ERC-721 SBT形式</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    ※「初期ラボメンバー・パスポートNFT」保有者のかたは購入できません。
                  </span>
                </div>
              </div>
              <div className="ja flex column gap-20">
                <div className="subsubtitle">
                  <span>
                    ■&nbsp;手裏剣NFT（イーサリアム・フルオンチェーン）
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subsubtitle">
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;Shuriken NFT (Ethereum Full On
                    Chain)
                  </span>
                </div>
              </div>
              <div className="ja flex column gap-20">
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売価格</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;0.008ETH/1枚</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>販売枚数</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;10,000枚（予定）</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>仕様</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;ERC-721</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    ※手裏剣NFTは数に限りがあるため売り切れの可能性があります。ステーキング前に限り、二次流通での売買も可能です。また今後、随時再販を予定しております。
                    ※パスポートNFTは事前申込を経て、後日Twitterなどで購入権利を獲得されたウォレットアドレスを公開させていただきます。
                  </span>
                </div>
              </div>
            </div>
            {/* 公式Twitterアカウントがリニューアル・公式サイトが新たにオープン */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    公式Twitterアカウントがリニューアル・公式サイトが新たにオープン
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>公式 Twitter</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://twitter.com/CN_Sakuya_Anime"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://twitter.com/CN_Sakuya_Anime
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>公式サイト</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://cryptoninja-sakuya.xyz"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://cryptoninja-sakuya.xyz
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Official Twitter Account Renewal & Official Site Opening
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Official Twitter</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://twitter.com/CN_Sakuya_Anime"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://twitter.com/CN_Sakuya_Anime
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Official site</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://cryptoninja-sakuya.xyz"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://cryptoninja-sakuya.xyz
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 放送概要 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>放送概要</span>
                </div>
                <div className="article">
                  <span>2023年10月より TV シリーズ放送</span>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>原作</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;イケハヤ</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>監督</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;野中晶史</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>脚本</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;細川徹</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>音楽プロデューサー</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;安藤日出孝</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>音楽</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;KOSEN</span>
                          <br />
                          <span>妹尾達也/COWMAN/あしたか（CNP Band）</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>アニメーション制作</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;ファンワークス</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>【キャスト】</span>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        -+
                        <div className="flex align-start justify-start">
                          <span>
                            咲耶：堀本凪沙、ネム：本谷史織、シャオラン：内田彩、岩爺：木下章嗣、狐白：柊子、ハヤテ：金子誠
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>アニメ著作権表記</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;ⒸCryptoAnime
                            Labs/「クリプトニンジャ咲耶」製作委員会
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>Broadcast Overview</span>
                </div>
                <div className="article">
                  <span>TV series broadcast from October 2023</span>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Original</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Ikehaya</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Director</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Akihisa Nonaka</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Screenplay</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Toru Hosokawa</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Music Producer</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Hidetaka Ando</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Music</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;KOSEN</span>
                          <br />
                          <span>Tatsuya Senoo/COWMAN/Ashitaka (CNP Band)</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Animation Production</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Fanworks</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>【Cast】</span>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            Sakuya: Nagisa Horimoto, Nem: Shiori Hontani,
                            Shaolan: Aya Uchida, Iwaji: Akitsugu Kinoshita,
                            Kohaku: Shuuko, Hayate: Makoto Kaneko
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Anime Copyright</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;ⒸCryptoAnime Labs/"CryptoNinja Sakuya"
                            Production Committee
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* キャスト・音楽 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    キャスト第1弾・採用楽曲を発表。アニメーションPV完成版を初公開
                  </span>
                </div>
                <div className="article">
                  <span>
                    昨年11月より、声優と音楽の募集を開始いたしまして、フレッシュな魅力あふれる方、様々なジャンルで活躍される方、初めてのことにチャレンジされる方から多数のご応募をいただきました。そして厳正なる審査の結果、キャスト第1弾と採用楽曲が正式決定いたしました。
                  </span>
                </div>
                <div className="subtitle">
                  <span>
                    Announcement of First Cast & Adopted Songs. Completed
                    Version of Animation PV Released
                  </span>
                </div>
                <div className="article">
                  <span>
                    Since November last year, we have started recruiting voice
                    actors and music, and we have received many applications
                    from people with fresh charm, people active in various
                    genres, and people who are challenging for the first time.
                    As a result of strict screening, the first cast and adopted
                    songs have been officially decided.
                  </span>
                </div>
                <div className="article">
                  <span>【キャスト第1弾】</span>
                </div>
                <div className="article">
                  <span>【First Cast】</span>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>・咲耶&nbsp;&nbsp;CV&nbsp;:&nbsp;堀本凪沙</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Sakuya&nbsp;&nbsp;CV&nbsp;:&nbsp;Nagisa&nbsp;Horimoto
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={sakuya_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={HorimotoNagisa} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>・ネム&nbsp;&nbsp;CV&nbsp;:&nbsp;本谷史織</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Nem&nbsp;&nbsp;CV&nbsp;:&nbsp;Shiori&nbsp;Hontani
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={nemu_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={MotodaniShirori} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            ・シャオラン&nbsp;&nbsp;CV&nbsp;:&nbsp;内田彩
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Shaolan&nbsp;&nbsp;CV&nbsp;:&nbsp;Aya&nbsp;Uchida
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={syaoran_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={UchidaAya} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>・岩爺&nbsp;&nbsp;CV&nbsp;:&nbsp;木下章嗣</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Ganji&nbsp;&nbsp;CV&nbsp;:&nbsp;Akitsugu&nbsp;Kinoshita
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={ganzi_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={KinoshitaAkitsugu} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>・狐白&nbsp;&nbsp;CV&nbsp;:&nbsp;柊子</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Kohaku&nbsp;&nbsp;CV&nbsp;:&nbsp;Shuuko
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={kohaku_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={Toko} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
                <div className="article flex column gap-20">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>・ハヤテ&nbsp;&nbsp;CV&nbsp;:&nbsp;金子誠</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;&nbsp;&nbsp;Hayate&nbsp;&nbsp;CV&nbsp;:&nbsp;Makoto&nbsp;Kaneko
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="flex gap-12">
                    <div>
                      <img loading="lazy" src={hayate_tall} alt="" className="w-full" />
                    </div>
                    <div>
                      <img loading="lazy" src={KanekoMakoto} alt="" className="w-full" />
                    </div>
                  </div>
                </div>
              </div>
              {/* 音楽 */}
              <div className="article">
                <span>【音楽】</span>
                <ul>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>妹尾達也（主題歌「クリプトニンジャ咲耶」）</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>COWMAN</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>あしたか（CNP Band）</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="article">
                <span>
                  メインスタッフ・採用楽曲の決定に伴い、昨年11月に公開いたしましたPVに、新たに収録した音声・楽曲を加えた、アニメーションPV完成版を初公開いたします。
                </span>
              </div>
              <div className="article">
                  <span>
                    <a
                      href="https://youtu.be/b28xx9qSpf8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://youtu.be/b28xx9qSpf8
                    </a>
                  </span>
                </div>
              <div className="article">
                <span>【Music】</span>
                <ul>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>
                        Tatsuya Senoo (Theme Song "CryptoNinja Sakuya")
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>COWMAN</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex align-start justify-start">
                      <span>Ashitaka (CNP Band)</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="article">
                <span>
                  With the main staff set and songs adopted, we will release the
                  completed version of the animation PV, which was released in
                  November last year, with the newly recorded voices and songs.
                </span>
              </div>
              {/* <div className="article flex align-center justify-center w-full">
                <Video isPaused={!active}/>
              </div> */}
            </div>
            {/* TVアニメの正式タイトルが決定 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>TVアニメの正式タイトルが決定</span>
                </div>
                <div className="article">
                  <span>「忍ばない！クリプトニンジャ咲耶」とは？</span>
                </div>
                <div className="article">
                  <span>
                    メインキャストは「CryptoNinja」の人気女性キャラクター
                    咲耶・ネム・シャオランの三人組。忍ばない３人は日常をゆるく生きていたが、ある日街に伝わる暗号絵巻（クリプトえまき）が奪われ、ニンジャたちのバトルロイヤルが勃発する。忍者の街「甲賀シティ」でゆるく生きる彼女たちが力を合わせて立ち上がる姿をユーモアとアクションたっぷりに描きます。
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={topic101} alt="" className="w-90" />
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>Official Title of TV Anime Decided</span>
                </div>
                <div className="article">
                  <span>What is "Shinobanai! CryptoNinja Sakuya"?</span>
                </div>
                <div className="article">
                  <span>
                    The main cast is a trio of popular female characters from
                    "CryptoNinja": Sakuya, Nem, and Shaolan. The three, who
                    don't hide, were living their daily lives loosely, but one
                    day, the cryptographic picture scroll (Crypto Emaki) that is
                    passed down in the city is stolen, and a battle royale of
                    ninjas breaks out. The story is full of humor and action,
                    depicting how they fight together in the ninja city "Koga
                    City".
                  </span>
                </div>
              </div>
            </div>
            {/* クリプトニンジャとは？ */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>クリプトニンジャとは？</span>
                </div>
                <div className="article">
                  <span>
                    原作の「CryptoNinja
                    NFT」はインフルエンサー・イケハヤ氏プロデュースの元、人気イラストレーターのRii2氏によって描かれたNFT作品。「誰もが自由に使えるキャラクター」として、商用利用は原則的に自由となっています。イラスト、ゲーム、絵本、マンガ、小説、グッズ、演劇など、様々なクリエイター、企業が作品を継続的に発表しています。
                  </span>
                </div>
                <div className="article">
                  <span>
                    現在、発表された作品のNFT流通総額は15000ETH（日本円で37億円以上※本日時点）にのぼり、作品コミュニティ（DAO）に既に5万人以上のファンとクリエイターが集い日々二次創作が行われている、国内でもっとも活発なNFTプロジェクトです。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>What is CryptoNinja?</span>
                </div>
                <div className="article">
                  <span>
                    "CryptoNinja NFT" is an NFT work drawn by popular
                    illustrator Rii2 under the production of influencer Ikehaya.
                    As a "character that anyone can use freely", commercial use
                    is generally free. Various creators and companies continue
                    to release works such as illustrations, games, picture
                    books, manga, novels, goods, and theater.
                  </span>
                </div>
                <div className="article">
                  <span>
                    Currently, the total NFT circulation amounts to over
                    15,000ETH (over 3.7 billion yen as of today), and it is the
                    most active NFT project in Japan, where over 50,000 fans and
                    creators gather in the community (DAO) and derivative works
                    are made daily.
                  </span>
                </div>
              </div>
            </div>
            {/* 原作者情報・コメント */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>原作者情報・コメント</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={ikehaya} alt="" className="w-70" />
                </div>
                <div className="subtitle fontWeight500">
                  <span>原作：イケハヤ</span>
                </div>
                <div className="article">
                  <span>
                    Web3時代のIP「CryptoNinja」のマーケティングを担当。
                    <br />
                    総フォロワー70万人を超えるビジネス系インフルエンサー。
                    <br />
                    2018年からNFTに投資し、著書に「まだ東京で消耗してるの？（幻冬舎）」
                    <br />
                    「武器としての書く技術（KADOKAWA）」などがある。
                  </span>
                </div>
                <div className="article">
                  <span>
                    CryptoNinjaは、クリエイターによる二次創作、あるいは企業による二次利用を全面的に歓迎している珍しいキャラクターブランドです。
                    <br />
                    今回の作品では、日本を代表するアニメ制作会社に本気でCryptoNinjaを使っていただきました。
                    <br />
                    一足早く拝見させていただきましたが、すばらしいクオリティに興奮しています。ぜひCryptoNinjaの世界を楽しんでください！
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>Original Author Information & Comments</span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>Original work : Ikehaya</span>
                </div>
                <div className="article">
                  <span>
                    In charge of marketing for the IP of Web3 era "CryptoNinja".
                    <br />
                    A business influencer with over 700,000 total followers.
                    <br />
                    He has been investing in NFTs since 2018, and his books
                    include "Mada Tokyo de shoumou shiteru no? (Gentosha)" and
                    "Buki to shite no kaku gijutsu (KADOKAWA)".
                  </span>
                </div>
                <div className="article">
                  <span>
                    CryptoNinja is a rare character brand that fully welcomes
                    derivative works by creators or secondary use by companies.
                    In this work, a leading anime production company in Japan
                    seriously used CryptoNinja. I was allowed to see it a step
                    ahead, and I am excited about the wonderful quality. Please
                    enjoy the world of CryptoNinja!
                  </span>
                </div>
              </div>
            </div>
            {/* 監督・脚本家コメント */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>監督・脚本家コメント</span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>監督：野中晶史</span>
                </div>
                <div className="article">
                  <span>
                    PV「CRYPTONINJA
                    -咲耶-」の制作から引き続き監督を担当しています。1年前にPVを作っていた頃はテレビシリーズ化なんて想像もしていなかったので、お話をいただいた時はとても驚きました。
                    アニメ本編では、原作のかわいくてかっこいいキャラクターたちが、現代を生きる忍者として忍んだり忍ばなかったりしながらコミカルに活躍します。
                    原作ファンの方も、アニメから初めてCryptoNinjaに触れる方も、皆さんに楽しんでいただけるような作品にしていきたいです！
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>脚本：細川徹</span>
                </div>
                <div className="article">
                  <span>
                    NFTをアニメ化するという、まだ前例がない試み、短い尺の中で、どれだけ先が気になるストーリーを盛り込めるか、老若男女、日本人でも外国人でも楽しめる話など、いろいろ自分にとってチャレンジングな作品になってます。ギャグ満載で、意外に熱いストーリーです。
                    ぜひ、全部みて欲しい。この作品が、忍ばずに世の中で広がるといいなあ。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>Director & Screenwriter Comments</span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>Director: Akihisa Nonaka</span>
                </div>
                <div className="article">
                  <span>
                    I have been in charge of the director since the production
                    of the PV "CRYPTONINJA -Sakuya-". When I was making the PV a
                    year ago, I didn't even imagine that it would be made into a
                    TV series, so I was very surprised when I heard the story.
                    In the anime main story, the cute and cool characters of the
                    original work will comically play as ninjas living in the
                    present day, sometimes hiding and sometimes not hiding. I
                    want to make it a work that everyone, whether they are fans
                    of the original or those who touch CryptoNinja for the first
                    time from anime, can enjoy!
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>Script: Toru Hosokawa</span>
                </div>
                <div className="article">
                  <span>
                    This is an unprecedented attempt to animate NFT, and it is a
                    challenging work for me in many ways, such as how much I can
                    incorporate a story that makes me curious about the future
                    in a short length, a story that can be enjoyed by people of
                    all ages and nationalities, and so on. It's full of gags and
                    surprisingly hot stories. I really want everyone to see all
                    of it. I hope this work will spread out in the world.
                  </span>
                </div>
              </div>
            </div>
            {/* 『CryptoAnime Labs』参画法人について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>『CryptoAnime Labs』参画法人について</span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>一般社団法人オタクコイン協会</span>
                </div>
                <div className="article">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。2022年初夏には人気フルオンチェーンNFTゲーム『Isekai
                    Battle』のマーケティング・パートナーとして、企画やキャンペーンなどをサポートしています。
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>株式会社ファンワークス</span>
                </div>
                <div className="article">
                  <span>
                    ファンワークスは、2005年に公開したウェブアニメ『やわらか戦車』を皮切りに『がんばれ！ルルロロ
                    TINY☆TWIN☆BEARS』、『英国一家、日本を食べる』、『ざんねんないきもの事典』、『大家さんと僕』、『チキップダンサーズ』等、NHKでのテレビアニメシリーズなど多数のアニメを制作。2018年、サンリオ原作『アグレッシブ烈子（英語名：Aggretsuko）』がNetflixオリジナル作品として全世界配信。ワールドワイドな話題作となり、現在、第4シリーズが配信中。2019年11月、『映画
                    すみっコぐらし
                    とびだす絵本とひみつのコ』の興行収入15億円に迫るヒットを経て、2021年11月に『映画
                    すみっコぐらし
                    青い月夜のまほうのコ』、2022年7月に『映画ざんねんないきもの事典』を公開。
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>株式会社ツクリエ</span>
                </div>
                <div className="article">
                  <span>
                    ツクリエは事業を作る皆さまを応援するプロフェッショナルとしての起業支援サービス事業と、価値を創造するクリエイターの皆さまを支援するクリエイティブ創造事業を中心に事業展開をしています。
                    起業を目指す方、起業家の方を支援するイベント企画や相談事業、アクセラレーションプログラムの開発から、起業家の方との協同事業、商品開発、総再生数800万回を超えるYouTubeドラマ『おやじキャンプ飯』などの事業プロデュースを行っています。株式会社THE
                    BATTLEは、ツクリエが東京都から受託運営している創業支援施設「東京コンテンツインキュベーションセンター（TCIC）」の入居企業になります。
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>合同会社日本の田舎は資本主義のフロンティアだ</span>
                </div>
                <div className="article">
                  <span>
                    「日本の田舎は資本主義のフロンティアだ」を哲学とし、高知県の山奥から、NFT/Web3事業を展開。日本最大のWeb3系チャンネル「ikehaya
                    Web3
                    Univ.」など、Web3関係のメディアの運営も手掛けている。「CryptoNinja」「Ninja
                    DAO」Founderのikehayaが代表を務める。
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>株式会社THE BATTLE</span>
                </div>
                <div className="article">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクト、および「アニメバンク」など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    About "CryptoAnime Labs" Participating Corporations
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>Otaku Coin Association, INC</span>
                </div>
                <div className="article">
                  <span>
                    The Otaku Coin Association has been working since December
                    2017, including the predecessor Otaku Coin Preparation
                    Committee, to develop anime culture, aiming to penetrate,
                    introduce, and utilize blockchain technology in the anime
                    industry. In the early summer of 2022, it supported planning
                    and campaigns as a marketing partner of the popular
                    full-on-chain NFT game "Isekai Battle".
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>Fanworks, INC.</span>
                </div>
                <div className="article">
                  <span>
                    Fanworks have produced many animations, starting with the
                    web anime "Yawaraka Sensha" released in 2005, "Ganbare! Lulu
                    Lolo TINY☆TWIN☆BEARS", "A British Family Eats Japan",
                    "Zannen na Ikimono Jiten", "Ooya-san to Boku", "Chikip
                    Dancers", etc., and TV anime series on NHK. In 2018, the
                    Sanrio original "Aggressive Retsuko (English name:
                    Aggretsuko)" was distributed worldwide as a Netflix original
                    work. It became a worldwide topic, and the 4th series is
                    currently being broadcast. In November 2019, after the box
                    office hit of "Movie Sumikko Gurashi Tobidasu Picture Book
                    and Secret of Ko" approaching 1.5 billion yen, "Movie
                    Sumikko Gurashi Blue Moon Night Magic of Ko" was released in
                    November 2021, and "Movie Zannen na Ikimono Jiten" was
                    released in July 2022.
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>TSUCREA Co., Ltd.</span>
                </div>
                <div className="article">
                  <span>
                    TSUCREA is a company that focuses on business development
                    support services as well as creative creation enterprises
                    that help artists who provide value. From event planning and
                    consultation services to help those who aim to start a
                    business and entrepreneurs, development of acceleration
                    programs, joint ventures with entrepreneurs, product
                    development, business production of YouTube drama "Oyaji
                    Camp Rice" with over 8 million total views, etc. The Battle
                    Co., Ltd. is an incubation company of the "Tokyo Contents
                    Incubation Center (TCIC)" operated by TSUCREA from Tokyo.
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>
                    Goudou kaisha nihon no inaka wa shihon shugi no frontier da
                  </span>
                </div>
                <div className="article">
                  <span>
                    We are growing our NFT/Web3 business in the mountains of
                    Kochi Prefecture under the guiding principle that "Japan's
                    countryside is the frontier of capitalism." We also run
                    Web3-related media, like the biggest Web3 channel in Japan,
                    "ikehaya Web3 Univ." The representative is Ikehaya, the
                    founder of "CryptoNinja" and "Ninja DAO."
                  </span>
                </div>
                <div className="subtitle fontWeight500">
                  <span>THE BATTLE, INC.</span>
                </div>
                <div className="article">
                  <span>
                    THE BATTLE was established as a "web3 organization that
                    fights against conventional wisdom". The core members have
                    been active in the blockchain field since 2018 and have
                    handled various creative directions. Currently, in addition
                    to the anime project of the famous NFT work "CryptoNinja
                    NFT" in the web3 type anime production committee method
                    "CryptoAnime Labs", planning and development of the
                    full-on-chain blockchain game "Isekai Battle", IP creation
                    project with the anime studio "ABC Animation", and "Anime
                    Bank", "iconee", etc., we are working on multiple projects
                    with the main axis of entertainment x blockchain.
                  </span>
                </div>
              </div>
            </div>
            {/* All Newsリンク */}
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/news">
                <span>{capitalize("all news")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachNews">
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachNewsContainer flex column gap-50">
            <div className="titleContent flex column gap-30">
              <div className="jaTitle">
                <span>
                  NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                </span>
              </div>
              <div className="enTitle">
                <span>
                  The world's first TV anime series born from NFTs is now a
                  reality. The series, entitled "Shinobanai! CryptoNinja
                  Sakura," is based on the original "CryptoNinja" and is set to
                  premiere in October 2023.
                </span>
              </div>
              <div>
                <img loading="lazy" src={articleImage2} alt="" className="w-full" />
              </div>
            </div>
            {/* ニュースひとまとまり */}
            <article className="content flex column gap-50">
              {/* <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage1} alt="" className="w-80" />
              </div> */}
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    アニメ作品オリジナルのジェネラティブNFT発売も決定、「パスポートNFT」と「手裏剣NFT」の追加申し込みも開始
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ブロックチェーンゲームやNFT等のIPを活用したアニメ制作などを手がける株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木
                    清龍）は、国内最大規模のNFTコミュニティーを誇る『CryptoNinja』のTVアニメシリーズとして「忍ばない！クリプトニンジャ咲耶」の2023年10月より放送が決定したことをお知らせいたします。本作品は世界初(※)のNFT発のTVアニメーションとなり、昨年11月に公開したPVに引き続き、アニメーション制作はファンワークスが担当いたします。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ※各国NFT領域におけるコンテンツ展開として（2023年6月時点、自社調べ）
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>
                    In addition, we've decided to release generative NFTs
                    originated from the anime. We have also started accepting
                    applications for additional "Passport NFTs" and "Shuriken
                    NFTs".
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    The Battle Co., Ltd. (Headquartered in Nakano, Tokyo, CEO:
                    Kiyoryu Sasaki), a company that works on anime production
                    utilizing IPs such as blockchain games and NFTs, is proud to
                    announce that the TV anime series "Shinobanai! CryptoNinja
                    Sakura," which boasts the largest NFT community in Japan,
                    "CryptoNinja," is set to broadcast from October 2023. This
                    series is the world's first TV animation originating from
                    NFTs.*
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Following the preview released last November, the animation
                    production will be handled by Fanworks. *(as of June 2023,
                    according to our research).
                  </span>
                </div>
              </div>
            </article>
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>ジェネラティブNFT販売について</span>
                </div>
                <div className="jaContent">
                  <span>
                    「忍ばない！クリプトニンジャ咲耶」のキャラクターたちが描かれる、NFT(※)がTV放映にあわせて発売予定です。咲耶たちのかわいいデジタルアートをぜひ手に入れてみてください！
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ※NFT(Non-Fungible
                    Token、非代替性トークン)とはブロックチェーンを基盤にして作成された代替不可能なデジタルデータのことです。
                    ※各NFTの詳細につきましては、下記『CryptoAnime
                    Labs』公式Twitterからの追加情報をお待ちください。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    <a
                      href="https://twitter.com/CryptoAnimeLabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CryptoAnimeLabs
                    </a>
                  </span>
                </div>
                <div className="jaContent">
                  <ul>
                    <li>販売サイト : coming soon</li>
                    <li>販売開始日時 : 2023年秋</li>
                    <li>販売価格 : 0.1ETH前後</li>
                    <li>発行枚数 : 5555枚（予定）</li>
                    <li>ユーティリティー : coming soon</li>
                  </ul>
                </div>
                <div className="jaContent">
                  <span>
                    ※イーサリアムの価格など市況に応じて内容を変更する場合がございます。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>About Generative NFT Sales</span>
                </div>
                <div className="enContent">
                  <span>
                    NFTs* featuring the characters of "Shinobanai! CryptoNinja
                    Sakuya" are planned to be sold in conjunction with the TV
                    broadcast. Please consider acquiring these cute digital arts
                    of Sakuya and her friends!
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    *NFT (Non-Fungible Token) refers to irreplaceable digital
                    data created based on blockchain technology. *For more
                    details about each NFT, please wait for additional
                    information from the official "CryptoAnime Labs" Twitter
                    account below.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    <a
                      href="https://twitter.com/CryptoAnimeLabs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CryptoAnimeLabs
                    </a>
                  </span>
                </div>
                <div className="enContent">
                  <ul>
                    <li>Sale site : coming soon</li>
                    <li>Sale date : Autumn 2023</li>
                    <li>Price : around 0.1ETH</li>
                    <li>Number of supply : 5555 (planned)</li>
                    <li>Utility : coming soon</li>
                  </ul>
                </div>
                <div className="enContent">
                  <span>
                    Please note that the price may change depending on the price
                    of Ethereum and market conditions.
                  </span>
                </div>
              </div>
            </article>
            {/* パスポートNFT&手裏剣NFTの追加販売について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>パスポートNFT&手裏剣NFTの追加販売について</span>
                </div>
                <div className="jaContent">
                  <span>
                    昨年11月より販売しております第3期パスポートNFTと手裏剣NFTを新たに追加販売いたします。パスポートNFTは手裏剣NFTをステーキングした枚数に応じてランクアップし、Discordコミュニティ参加権や特別なチャンネルへの招待権、ジェネラティブNFTのAL獲得権などが付与されます。
                  </span>
                </div>
                <div className="jaContent">
                  <ul>
                    <li>
                      第3期パスポートNFT事前申込URL :
                      <a
                        href="https://forms.gle/riaoNkXApnKK53nz6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://forms.gle/riaoNkXApnKK53nz6
                      </a>
                    </li>
                    <li>申込期限 : 6/18（日）23:59 [JST]</li>
                    <li>
                      販売サイト :{" "}
                      <a
                        href="https://cryptoanimelabs.xyz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://cryptoanimelabs.xyz
                      </a>
                    </li>
                    <li>販売開始日時 : 2023年6月下旬</li>
                  </ul>
                </div>
                <div className="jaTitle">
                  <span>
                    ■第3期ラボメンバー・パスポートNFT（イーサリアム・フルオンチェーン）
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={passportNft} alt="" className="w-80" />
                </div>
                <div className="jaContent">
                  <ul>
                    <li>販売価格 : 0.02ETH/1枚</li>
                    <li>販売枚数 : 1人1枚限定で販売</li>
                    <li>仕様 : ERC-721 SBT形式</li>
                  </ul>
                </div>
                <div className="jaContent">
                  <span>
                    ※「初期ラボメンバー・パスポートNFT」保有者のかたは購入できません。
                  </span>
                </div>
                <div className="jaTitle">
                  <span>■ 手裏剣NFT（イーサリアム・フルオンチェーン）</span>
                </div>
                <div className="jaContent">
                  <ul>
                    <li>販売価格 : 0.008ETH/1枚</li>
                    <li>販売枚数 : 10,000枚（予定）</li>
                    <li>仕様 : ERC-721</li>
                  </ul>
                </div>
                <div className="jaContent">
                  <span>
                    ※手裏剣NFTは数に限りがあるため売り切れの可能性があります。ステーキング前に限り、二次流通での売買も可能です。また今後、随時再販を予定しております。
                    <br />
                    ※パスポートNFTは事前申込を経て、後日Twitterなどで購入権利を獲得されたウォレットアドレスを公開させていただきます。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>
                    About Additional Sales of Passport NFT & Shuriken NFT
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    We will be conducting new sales of the 3rd Passport NFT and
                    Shuriken NFT, which have been on sale since November last
                    year. The Passport NFT ranks up according to the number of
                    Shuriken NFTs staked, and grants rights such as
                    participation in the Discord community, invitation to
                    special channels, and the right to acquire AL of Generative
                    NFT.
                  </span>
                </div>
                <div className="enContent">
                  <ul>
                    <li>
                      3rd Passport NFT pre-registration URL :
                      <a
                        href="https://forms.gle/riaoNkXApnKK53nz6"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://forms.gle/riaoNkXApnKK53nz6
                      </a>
                    </li>
                    <li>Pre-registration deadline : 6/18 (Sun) 23:59 [JST]</li>
                    <li>
                      Sale site :{" "}
                      <a
                        href="https://cryptoanimelabs.xyz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://cryptoanimelabs.xyz
                      </a>
                    </li>
                    <li>Sale date : Late June 2023</li>
                  </ul>
                </div>
                <div className="enTitle">
                  <span>
                    ■3rd Lab Member Passport NFT (Ethereum Full On Chain)
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={passportNft} alt="" className="w-80" />
                </div>
                <div className="enContent">
                  <ul>
                    <li>Sale price : 0.02ETH/1 piece</li>
                    <li>Sale volume : Sold limited to one per person</li>
                    <li>Specification : ERC-721 SBT format</li>
                  </ul>
                </div>
                <div className="enContent">
                  <span>
                    *Please note that those who own the "Initial Lab Member
                    Passport NFT" will not be able to purchase.
                  </span>
                </div>
                <div className="enTitle">
                  <span>■ Shuriken NFT (Ethereum Full On Chain)</span>
                </div>
                <div className="enContent">
                  <ul>
                    <li>Sale price : 0.008ETH/1 piece</li>
                    <li>Sale volume : 10,000 (planned)</li>
                    <li>Specification : ERC-721</li>
                  </ul>
                </div>
                <div className="enContent">
                  <span>
                    *Please note that Shuriken NFTs are limited in number and
                    may sell out. Secondary trading is possible before staking.
                    We also plan to resell them from time to time.
                    <br />
                    *Passport NFTs are pre-registered and the wallet address
                    that has acquired the purchase right will be made public
                    later on Twitter, etc.
                  </span>
                </div>
              </div>
            </article>
            {/* 公式Twitterアカウントがリニューアル・公式サイトが新たにオープン */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    公式Twitterアカウントがリニューアル・公式サイトが新たにオープン
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    公式 Twitter :{" "}
                    <a
                      href="https://twitter.com/CN_Sakuya_Anime"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CN_Sakuya_Anime
                    </a>
                  </span>
                  <span>
                    公式サイト :{" "}
                    <a
                      href="https://cryptoninja-sakuya.xyz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://cryptoninja-sakuya.xyz
                    </a>
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>
                    公式Twitterアカウントがリニューアル・公式サイトが新たにオープン
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    Official Twitter :{" "}
                    <a
                      href="https://twitter.com/CN_Sakuya_Anime"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/CN_Sakuya_Anime
                    </a>
                  </span>
                  <span>
                    Official site :{" "}
                    <a
                      href="https://cryptoninja-sakuya.xyz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://cryptoninja-sakuya.xyz
                    </a>
                  </span>
                </div>
              </div>
            </article>
            {/* 放送概要 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>放送概要</span>
                </div>
                <div className="jaContent">
                  <span>2023年10月より TV シリーズ放送</span>
                </div>
                <div className="jaContent">
                  <ul>
                    <li>原作：イケハヤ</li>
                    <li>監督：野中晶史</li>
                    <li>脚本：細川徹</li>
                    <li>音楽プロデューサー：安藤日出孝</li>
                    <li className="flex">
                      <div className="flex align-start justify-start">
                        <span>音楽：</span>
                      </div>
                      <div className="flex column slign-start justify-start">
                        <span>KOSEN</span>
                        <span>妹尾達也/COWMAN</span>
                        <span>/あしたか（CNP Band）</span>
                      </div>
                    </li>
                    <li>アニメーション制作：ファンワークス</li>
                  </ul>
                </div>
                <div className="jaContent flex column">
                  <div>
                    <span>【キャスト】</span>
                  </div>
                  <div>
                    <span>
                      咲耶：堀本凪沙、ネム：本谷史織、
                      <br />
                      シャオラン：内田彩、岩爺：木下章嗣、
                      <br />
                      狐白：柊子、ハヤテ：金子誠
                    </span>
                  </div>
                </div>
                <div className="jaContent">
                  <span>
                    アニメ著作権表記：ⒸCryptoAnime Labs/
                    <br />
                    「クリプトニンジャ咲耶」製作委員会
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>Broadcast Overview</span>
                </div>
                <div className="enContent">
                  <span>TV series broadcast from October 2023</span>
                </div>
                <div className="enContent">
                  <ul>
                    <li>Original: Ikehaya</li>
                    <li>Director: Akihisa Nonaka</li>
                    <li>Screenplay: Toru Hosokawa</li>
                    <li>Music Producer: Hidetaka Ando</li>
                    <li className="flex">
                      <div className="flex align-start justify-start">
                        <span>Music：</span>
                      </div>
                      <div className="flex column slign-start justify-start">
                        <span>KOSEN</span>
                        <span>Tatsuya Senoo/COWMAN</span>
                        <span>/Ashitaka (CNP Band)</span>
                      </div>
                    </li>
                    <li>Animation Production: Fanworks</li>
                  </ul>
                </div>
                <div className="enContent flex column">
                  <div>
                    <span>[Cast]</span>
                  </div>
                  <div>
                    <span>
                      Sakuya: Nagisa Horimoto, Nem: Shiori Hontani,
                      <br />
                      Shaolan: Aya Uchida, Ganji: Akitsugu Kinoshita,
                      <br />
                      Kohaku: Shuuko, Hayate: Makoto Kaneko
                    </span>
                  </div>
                </div>
                <div className="enContent">
                  <span>
                    Anime Copyright：ⒸCryptoAnime Labs/
                    <br />
                    "CryptoNinja Sakuya" Production Committee
                  </span>
                </div>
              </div>
            </article>
            {/* キャスト第1弾・採用楽曲を発表。アニメーションPV完成版を初公開 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    キャスト第1弾・採用楽曲を発表。アニメーションPV完成版を初公開
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    昨年11月より、声優と音楽の募集を開始いたしまして、フレッシュな魅力あふれる方、様々なジャンルで活躍される方、初めてのことにチャレンジされる方から多数のご応募をいただきました。そして厳正なる審査の結果、キャスト第1弾と採用楽曲が正式決定いたしました。
                  </span>
                </div>
                <div className="enTitle">
                  <span>
                    Announcement of First Cast & Adopted Songs. Completed
                    Version of Animation PV Released
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Since November last year, we have started recruiting voice
                    actors and music, and we have received many applications
                    from people with fresh charm, people active in various
                    genres, and people who are challenging for the first time.
                    As a result of strict screening, the first cast and adopted
                    songs have been officially decided.
                  </span>
                </div>
                <div className="jaContent flex column gap-12">
                  <div className="flex column">
                    <span>【キャスト】</span>
                    <span>&nbsp;&nbsp;[First Cast]</span>
                  </div>
                  {/* 咲耶声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・咲耶&nbsp;CV：堀本凪沙</span>
                      <span>・Sakuya CV: Nagisa Horimoto</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={sakuya_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy" src={HorimotoNagisa} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>
                  {/* ネム声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・ネム&nbsp;CV：本谷史織</span>
                      <span>・Nem CV: Shiori Hontani</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={nemu_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy" src={MotodaniShirori} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>
                  {/* シャオラン声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・シャオラン&nbsp;CV：内田彩</span>
                      <span>・Shaolan CV: Aya Uchida</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={syaoran_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy" src={UchidaAya} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>
                  {/* 岩爺声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・岩爺&nbsp;CV：木下章嗣</span>
                      <span>・Ganji CV: Akitsugu Kinoshita</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={ganzi_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy"
                          src={KinoshitaAkitsugu}
                          alt=""
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                  {/* 狐白声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・狐白&nbsp;CV：柊子</span>
                      <span>・Kohaku CV: Shuuko</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={kohaku_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy" src={Toko} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>
                  {/* ハヤテ声優 */}
                  <div className="flex column gap-20">
                    <div className="flex column">
                      <span>・ハヤテ&nbsp;CV：金子誠</span>
                      <span>・Hayate CV: Makoto Kaneko</span>
                    </div>
                    <div className="flex">
                      <div>
                        <img loading="lazy" src={hayate_tall} alt="" className="w-full" />
                      </div>
                      <div>
                        <img loading="lazy" src={KanekoMakoto} alt="" className="w-full" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jaContent flex column gap-12">
                  <div>
                    <span>【音 楽】</span>
                  </div>
                  <ul>
                    <li>妹尾達也（主題歌「クリプトニンジャ咲耶」）</li>
                    <li>COWMAN</li>
                    <li>あしたか（CNP Band）</li>
                  </ul>
                </div>
                <div className="jaContent flex column gap-12">
                  <span>
                    メインスタッフ・採用楽曲の決定に伴い、昨年11月に公開いたしましたPVに、新たに収録した音声・楽曲を加えた、アニメーションPV完成版を初公開いたします。
                  </span>
                  <div className="flex align-center justify-center w-full">
                    <YouTube
                      videoId={"b28xx9qSpf8"}
                      opts={opts}
                      className="youtube"
                    />
                  </div>
                </div>
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="enContent flex column gap-12">
                  <div>
                    <span>[Music]</span>
                  </div>
                  <ul>
                    <li>Tatsuya Senoo (Theme Song "CryptoNinja Sakuya")</li>
                    <li>COWMAN</li>
                    <li>Ashitaka（CNP Band）</li>
                  </ul>
                </div>
                <div className="enContent flex column gap-12">
                  <span>
                    With the main staff set and songs adopted, we will release
                    the completed version of the animation PV, which was
                    released in November last year, with the newly recorded
                    voices and songs.
                  </span>
                </div>
              </div>
            </article>
            {/* TVアニメの正式タイトルが決定 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>TVアニメの正式タイトルが決定</span>
                </div>
                <div className="jaContent">
                  <span>「忍ばない！クリプトニンジャ咲耶」とは？</span>
                </div>
                <div className="jaContent">
                  <span>
                    メインキャストは「CryptoNinja」の人気女性キャラクター
                    咲耶・ネム・シャオランの三人組。忍ばない３人は日常をゆるく生きていたが、ある日街に伝わる暗号絵巻（クリプトえまき）が奪われ、ニンジャたちのバトルロイヤルが勃発する。忍者の街「甲賀シティ」でゆるく生きる彼女たちが力を合わせて立ち上がる姿をユーモアとアクションたっぷりに描きます。
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={topic101} alt="" className="w-80" />
                </div>
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="enTitle">
                  <span>Official Title of TV Anime Decided</span>
                </div>
                <div className="enContent">
                  <span>What is "Shinobanai! CryptoNinja Sakuya"?</span>
                </div>
                <div className="enContent">
                  <span>
                    The main cast is a trio of popular female characters from
                    "CryptoNinja": Sakuya, Nem, and Shaolan. The three, who
                    don't hide, were living their daily lives loosely, but one
                    day, the cryptographic picture scroll (Crypto Emaki) that is
                    passed down in the city is stolen, and a battle royale of
                    ninjas breaks out. The story is full of humor and action,
                    depicting how they fight together in the ninja city "Koga
                    City".
                  </span>
                </div>
              </div>
            </article>

            {/* クリプトニンジャとは？ */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>クリプトニンジャとは？</span>
                </div>
                <div className="jaContent">
                  <span>
                    原作の「CryptoNinja
                    NFT」はインフルエンサー・イケハヤ氏プロデュースの元、人気イラストレーターのRii2氏によって描かれたNFT作品。「誰もが自由に使えるキャラクター」として、商用利用は原則的に自由となっています。イラスト、ゲーム、絵本、マンガ、小説、グッズ、演劇など、様々なクリエイター、企業が作品を継続的に発表しています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    現在、発表された作品のNFT流通総額は15000ETH（日本円で37億円以上※本日時点）にのぼり、作品コミュニティ（DAO）に既に5万人以上のファンとクリエイターが集い日々二次創作が行われている、国内でもっとも活発なNFTプロジェクトです。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>What is CryptoNinja?</span>
                </div>
                <div className="enContent">
                  <span>
                    "CryptoNinja NFT" is an NFT work drawn by popular
                    illustrator Rii2 under the production of influencer Ikehaya.
                    As a "character that anyone can use freely", commercial use
                    is generally free. Various creators and companies continue
                    to release works such as illustrations, games, picture
                    books, manga, novels, goods, and theater.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Currently, the total NFT circulation amounts to over
                    15,000ETH (over 3.7 billion yen as of today), and it is the
                    most active NFT project in Japan, where over 50,000 fans and
                    creators gather in the community (DAO) and derivative works
                    are made daily.
                  </span>
                </div>
              </div>
            </article>
            {/* 原作者情報・コメント */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle fontWeight700">
                  <span>原作者情報・コメント</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={ikehaya} alt="" className="w-80" />
                </div>
                <div className="jaContent fontWeight500">
                  <span>原作：イケハヤ</span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    Web3時代のIP「CryptoNinja」のマーケティングを担当。
                  </span>
                  <span>
                    総フォロワー70万人を超えるビジネス系インフルエンサー。
                  </span>
                  <span>
                    2018年からNFTに投資し、著書に「まだ東京で消耗してるの？（幻冬舎）」
                    「武器としての書く技術（KADOKAWA）」などがある。
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    CryptoNinjaは、クリエイターによる二次創作、あるいは企業による二次利用を全面的に歓迎している珍しいキャラクターブランドです。
                  </span>
                  <span>
                    今回の作品では、日本を代表するアニメ制作会社に本気でCryptoNinjaを使っていただきました。
                    一足早く拝見させていただきましたが、すばらしいクオリティに興奮しています。ぜひCryptoNinjaの世界を楽しんでください！
                  </span>
                </div>
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle fontWeight700">
                  <span>Original Author Information & Comments</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>Original work: Ikehaya</span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    In charge of marketing for the IP of Web3 era "CryptoNinja".
                  </span>
                  <span>
                    A business influencer with over 700,000 total followers.
                  </span>
                  <span>
                    He has been investing in NFTs since 2018, and his books
                    include "Mada Tokyo de shoumou shiteru no? (Gentosha)" and
                    "Buki to shite no kaku gijutsu (KADOKAWA)".
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    CryptoNinja is a rare character brand that fully welcomes
                    derivative works by creators or secondary use by companies.
                  </span>
                  <span>
                    In this work, a leading anime production company in Japan
                    seriously used CryptoNinja. I was allowed to see it a step
                    ahead, and I am excited about the wonderful quality. Please
                    enjoy the world of CryptoNinja!
                  </span>
                </div>
              </div>
            </article>
            {/* 監督・脚本家コメント */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle fontWeight700">
                  <span>監督・脚本家コメント</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>監督：野中晶史</span>
                </div>
                <div className="jaContent">
                  <span>
                    PV「CRYPTONINJA
                    -咲耶-」の制作から引き続き監督を担当しています。1年前にPVを作っていた頃はテレビシリーズ化なんて想像もしていなかったので、お話をいただいた時はとても驚きました。
                    アニメ本編では、原作のかわいくてかっこいいキャラクターたちが、現代を生きる忍者として忍んだり忍ばなかったりしながらコミカルに活躍します。
                    原作ファンの方も、アニメから初めてCryptoNinjaに触れる方も、皆さんに楽しんでいただけるような作品にしていきたいです！
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>脚本：細川徹</span>
                </div>
                <div className="jaContent">
                  <span>
                    NFTをアニメ化するという、まだ前例がない試み、短い尺の中で、どれだけ先が気になるストーリーを盛り込めるか、老若男女、日本人でも外国人でも楽しめる話など、いろいろ自分にとってチャレンジングな作品になってます。ギャグ満載で、意外に熱いストーリーです。
                    ぜひ、全部みて欲しい。この作品が、忍ばずに世の中で広がるといいなあ。
                  </span>
                </div>
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle fontWeight700">
                  <span>Director & Screenwriter Comments</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>Director: Akihisa Nonaka</span>
                </div>
                <div className="jaContent">
                  <span>
                    I have been in charge of the director since the production
                    of the PV "CRYPTONINJA -Sakuya-". When I was making the PV a
                    year ago, I didn't even imagine that it would be made into a
                    TV series, so I was very surprised when I heard the story.
                    In the anime main story, the cute and cool characters of the
                    original work will comically play as ninjas living in the
                    present day, sometimes hiding and sometimes not hiding. I
                    want to make it a work that everyone, whether they are fans
                    of the original or those who touch CryptoNinja for the first
                    time from anime, can enjoy!
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>Script: Toru Hosokawa</span>
                </div>
                <div className="jaContent">
                  <span>
                    This is an unprecedented attempt to animate NFT, and it is a
                    challenging work for me in many ways, such as how much I can
                    incorporate a story that makes me curious about the future
                    in a short length, a story that can be enjoyed by people of
                    all ages and nationalities, and so on. It's full of gags and
                    surprisingly hot stories. I really want everyone to see all
                    of it. I hope this work will spread out in the world.
                  </span>
                </div>
              </div>
            </article>
            {/* 『CryptoAnime Labs』参画法人について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle fontWeight700">
                  <span>『CryptoAnime Labs』参画法人について</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>一般社団法人オタクコイン協会</span>
                </div>
                <div className="jaContent">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。2022年初夏には人気フルオンチェーンNFTゲーム『Isekai
                    Battle』のマーケティング・パートナーとして、企画やキャンペーンなどをサポートしています。
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>株式会社ファンワークス</span>
                </div>
                <div className="jaContent">
                  <span>
                    ファンワークスは、2005年に公開したウェブアニメ『やわらか戦車』を皮切りに『がんばれ！ルルロロ
                    TINY☆TWIN☆BEARS』、『英国一家、日本を食べる』、『ざんねんないきもの事典』、『大家さんと僕』、『チキップダンサーズ』等、NHKでのテレビアニメシリーズなど多数のアニメを制作。2018年、サンリオ原作『アグレッシブ烈子（英語名：Aggretsuko）』がNetflixオリジナル作品として全世界配信。ワールドワイドな話題作となり、現在、第4シリーズが配信中。2019年11月、『映画
                    すみっコぐらし
                    とびだす絵本とひみつのコ』の興行収入15億円に迫るヒットを経て、2021年11月に『映画
                    すみっコぐらし
                    青い月夜のまほうのコ』、2022年7月に『映画ざんねんないきもの事典』を公開。
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>株式会社ツクリエ</span>
                </div>
                <div className="jaContent">
                  <span>
                    ツクリエは事業を作る皆さまを応援するプロフェッショナルとしての起業支援サービス事業と、価値を創造するクリエイターの皆さまを支援するクリエイティブ創造事業を中心に事業展開をしています。
                    起業を目指す方、起業家の方を支援するイベント企画や相談事業、アクセラレーションプログラムの開発から、起業家の方との協同事業、商品開発、総再生数800万回を超えるYouTubeドラマ『おやじキャンプ飯』などの事業プロデュースを行っています。株式会社THE
                    BATTLEは、ツクリエが東京都から受託運営している創業支援施設「東京コンテンツインキュベーションセンター（TCIC）」の入居企業になります。
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>合同会社日本の田舎は資本主義のフロンティアだ</span>
                </div>
                <div className="jaContent">
                  <span>
                    「日本の田舎は資本主義のフロンティアだ」を哲学とし、高知県の山奥から、NFT/Web3事業を展開。日本最大のWeb3系チャンネル「ikehaya
                    Web3
                    Univ.」など、Web3関係のメディアの運営も手掛けている。「CryptoNinja」「Ninja
                    DAO」Founderのikehayaが代表を務める。
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>株式会社THE BATTLE</span>
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクト、および「アニメバンク」「iconee」など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle fontWeight700">
                  <span>
                    About "CryptoAnime Labs" Participating Corporations
                  </span>
                </div>
                <div className="enContent fontWeight500">
                  <span>Otaku Coin Association, INC</span>
                </div>
                <div className="enContent">
                  <span>
                    The Otaku Coin Association has been working since December
                    2017, including the predecessor Otaku Coin Preparation
                    Committee, to develop anime culture, aiming to penetrate,
                    introduce, and utilize blockchain technology in the anime
                    industry. In the early summer of 2022, it supported planning
                    and campaigns as a marketing partner of the popular
                    full-on-chain NFT game "Isekai Battle".
                  </span>
                </div>
                <div className="enContent fontWeight500">
                  <span>Fanworks, INC.</span>
                </div>
                <div className="enContent">
                  <span>
                    Fanworks have produced many animations, starting with the
                    web anime "Yawaraka Sensha" released in 2005, "Ganbare! Lulu
                    Lolo TINY☆TWIN☆BEARS", "A British Family Eats Japan",
                    "Zannen na Ikimono Jiten", "Ooya-san to Boku", "Chikip
                    Dancers", etc., and TV anime series on NHK. In 2018, the
                    Sanrio original "Aggressive Retsuko (English name:
                    Aggretsuko)" was distributed worldwide as a Netflix original
                    work. It became a worldwide topic, and the 4th series is
                    currently being broadcast. In November 2019, after the box
                    office hit of "Movie Sumikko Gurashi Tobidasu Picture Book
                    and Secret of Ko" approaching 1.5 billion yen, "Movie
                    Sumikko Gurashi Blue Moon Night Magic of Ko" was released in
                    November 2021, and "Movie Zannen na Ikimono Jiten" was
                    released in July 2022.
                  </span>
                </div>
                <div className="enContent fontWeight500">
                  <span>TSUCREA Co., Ltd.</span>
                </div>
                <div className="enContent">
                  <span>
                    TSUCREA is a company that focuses on business development
                    support services as well as creative creation enterprises
                    that help artists who provide value. From event planning and
                    consultation services to help those who aim to start a
                    business and entrepreneurs, development of acceleration
                    programs, joint ventures with entrepreneurs, product
                    development, business production of YouTube drama "Oyaji
                    Camp Rice" with over 8 million total views, etc. The Battle
                    Co., Ltd. is an incubation company of the "Tokyo Contents
                    Incubation Center (TCIC)" operated by TSUCREA from Tokyo.
                  </span>
                </div>
                <div className="enContent fontWeight500">
                  <span>
                    Goudou kaisha nihon no inaka wa shihon shugi no frontier da
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    We are growing our NFT/Web3 business in the mountains of
                    Kochi Prefecture under the guiding principle that "Japan's
                    countryside is the frontier of capitalism." We also run
                    Web3-related media, like the biggest Web3 channel in Japan,
                    "ikehaya Web3 Univ." The representative is Ikehaya, the
                    founder of "CryptoNinja" and "Ninja DAO."
                  </span>
                </div>
                <div className="enContent fontWeight500">
                  <span>THE BATTLE, INC.</span>
                </div>
                <div className="enContent">
                  <span>
                    THE BATTLE was established as a "web3 organization that
                    fights against conventional wisdom". The core members have
                    been active in the blockchain field since 2018 and have
                    handled various creative directions. Currently, in addition
                    to the anime project of the famous NFT work "CryptoNinja
                    NFT" in the web3 type anime production committee method
                    "CryptoAnime Labs", planning and development of the
                    full-on-chain blockchain game "Isekai Battle", IP creation
                    project with the anime studio "ABC Animation", and "Anime
                    Bank", "iconee", etc., we are working on multiple projects
                    with the main axis of entertainment x blockchain.
                  </span>
                </div>
              </div>
            </article>

            {/* All View ボタン */}
            <div className="allView flex align-center justify-center w-full">
              <span>
                <Link to="/news">{capitalize("all news")}</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Topic1;
