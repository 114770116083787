import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import CompanyPage01 from "../compressed/CompanyPage01.webp";

const Company = ({ companyBlur, scrollCompanyRef, useWindowWidth }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const blur = useInView({
    threshold: 0.1, //10%見えたら検知
    triggerOnce: true,
  });
  const matrix = useInView({
    threshold: 0.4, //40%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section id="Company" className="flex  Cormorant_Garamond" ref={blur.ref}>
        <div
          className="CompanyContainer flex relative h-full"
          ref={scrollCompanyRef}
        >
          <div className="CompanyImage">
            <img loading="lazy" src={CompanyPage01} alt="" className="h-full" />
          </div>
          <div className="CompanyLeft flex align-end relative">
            {blur.inView && (
              <div className="BigNumber absolute blurNumber user-select-none">
                <h1>03</h1>
              </div>
            )}
            <div className="TopNewsLeft flex column justify-end h-full">
              <div className="TopNewsText">
                <div
                  className={`subTitle ${
                    blur.inView ? "blurTitle" : "invisible"
                  }`}
                >
                  <span className="user-select-none">
                    {capitalize("company")}
                  </span>
                </div>
                {blur.inView && (
                  <div className="flex column gap-20 blurText">
                    <div className="jaText">
                      <span>
                        THE BATTLE
                        は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、エンタメ×web3領域のプロフェッショナルとして様々なクリエイティブディレクションを手がけております。
                      </span>
                    </div>
                    <div className="enText">
                      <span>
                        THE BATTLE was founded as a "web3 organization that
                        challenges conventional norms." Its core members have
                        been active in the blockchain space since 2018 and have
                        been involved in various creative directions in the
                        intersection of entertainment and web3 as professionals.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="CompanyMiddle flex justify-center align-center h-full w-full"
            ref={matrix.ref}
          >
            <div className="MiddleContent flex align-center justify-center column relative">
              <div
                className={`matrix flex justify-center w-full ${
                  matrix.inView ? "is-animated" : ""
                }`}
              >
                <span className="text_wrap">
                  <span className="inn inn1 user-select-none">
                    {capitalize("battle")}
                  </span>
                </span>
              </div>
              <div
                className={`matrix flex justify-start w-full ${
                  matrix.inView ? "is-animated" : ""
                }`}
              >
                <span className="text_wrap">
                  <span className="inn inn2 user-select-none">
                    {capitalize("against")}
                  </span>
                </span>
              </div>
              <div
                className={`matrix flex justify-end w-full ${
                  matrix.inView ? "is-animated" : ""
                }`}
              >
                <span className="text_wrap">
                  <span className="inn inn3 user-select-none">
                    {capitalize("society")}
                  </span>
                </span>
              </div>
              {matrix.inView && (
                <div className="absolute">
                  <div className="flex column gap-20">
                    <div className="subTitle blurNumber fontWeight700">
                      <span>{capitalize("concept")}</span>
                    </div>
                    <div className="blurTitle italic">
                      <span>
                        Questioning the "existing ways."
                        <br />
                        Loving the creator culture.
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="CompanyBackground relative h-full">
            <div className="CompanyRight absolute">
              <div className="marquee_container absolute">
                <Marquee
                  speed={15}
                  autoFill={true}
                  className="marquee_text"
                >
                  <div>
                    <Link to="/company">{capitalize("view detail ")}</Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    );
  } else {
    return (
      <section className="spCompany" ref={blur.ref}>
        <div className="container flex column w-full h-full">
          <div className="">
            <img loading="lazy" src={CompanyPage01} alt="" className="w-full" />
          </div>
          <div className="downContainer flex column w-full h-full relative">
            {blur.inView && (
              <div className="spNumber absolute blurNumber user-select-none">
                <span>03</span>
              </div>
            )}
            {/*  */}
            <div className="content flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column">
                <div
                  className={`spTitle ${
                    blur.inView ? "blurTitle" : "invisible"
                  }`}
                >
                  <span className="user-select-none">
                    {capitalize("company")}
                  </span>
                </div>
              </div>
              {blur.inView && (
                <div className="flex column gap-20 blurText">
                  <div className="jaText">
                    <span>
                      THE BATTLE
                      は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、エンタメ×web3領域のプロフェッショナルとして様々なクリエイティブディレクションを手がけております。
                    </span>
                  </div>
                  <div className="enText">
                    <span>
                      THE BATTLE was founded as a "web3 organization that
                      challenges conventional norms." Its core members have been
                      active in the blockchain space since 2018 and have been
                      involved in various creative directions in the
                      intersection of entertainment and web3 as professionals.
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/*  */}
            <div className="content message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle fontWeight700">
                  <span>{capitalize("concept")}</span>
                </div>
                <div className="enText flex column w-full italic">
                  <span>Questioning the "existing ways."</span>
                  <span>Loving the creator culture.</span>
                </div>
              </div>
              <div className="companyMessage flex column">
                <div className="flex align-start justify-start w-full user-select-none">
                  <span>{capitalize("battle")}</span>
                </div>
                <div className="flex align-start justify-start w-full user-select-none">
                  <span>{capitalize("against")}</span>
                </div>
                <div className="flex align-start justify-start w-full user-select-none">
                  <span>{capitalize("society")}</span>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="sp_marquee_container flex align-center jusfity-center">
              <div className="sp_marquee_content relative">
                <Marquee speed={20} className="marquee_text">
                  <div className="user-select-none">
                    <Link to="/company">{capitalize("partner and member ")}</Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Company;
