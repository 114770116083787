import { Link } from "react-router-dom";

import battleLogo from "../compressed/battleLogo.webp";
import articleImage2 from "../compressed/articleImage2.webp";
import articleImage5 from "../compressed/articleImage5.webp";
import passportNft from "../compressed/passportNft.webp";
import articleImage6 from "../compressed/articleImage6.webp";
import cryptoninja from "../compressed/cryptoninja.webp";
import tsucrea from "../compressed/tsucrea.webp";
import fanworks from "../compressed/fanworks.webp";
import frontier from "../compressed/frontier.webp";
import OtakuCoin from "../compressed/OtakuCoin.webp";
import firmLogo from "../compressed/firmLogo.webp";

const Topic4 = ({ useWindowWidth, handlerToggle }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  if (windowWidth > 496) {
    return (
      <section id="EachNews" className="">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex gap10">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <img loading="lazy" src={articleImage5} alt="" className="w-full" />
            </div>
          </aside>
          {/* 日本語版 */}
          <main className="main flex column align-start justify-start gap-100">
            {/* タイトル */}
            <div className="title flex column gap-20">
              <div className="bigTitle ja">
                <span>
                  NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                </span>
              </div>
              <div className="en">
                <span>
                  web3で人気のIP“CryptoNinja”のアニメ化に向けた資金調達。“応援枠”の即時完売を受けてTwitterで「#応援させろ」がトレンド入り。
                </span>
              </div>
            </div>
            {/* 要約 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="article">
                  <span>
                    株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木清龍、以下当社）は、一般社団法人オタクコイン協会、株式会社ツクリエ、株式会社ファンワークス、合同会社日本の田舎は資本主義のフロンティアだ
                    と共同設立した、web3時代のアニメ”制作”委員会『CryptoAnime
                    Labs（以下当組織）』より販売した「初期ラボメンバー・パスポートNFT&手裏剣NFT」が、販売時間総計1分で2万枚以上のNFTが完売し、累計3,800万円相当の資金を調達したことを発表いたします。販売したNFTはweb3領域で人気のIP“CryptoNinja（読み：クリプトニンジャ）”のアニメ化プロジェクトの“応援”に使用することができ、NFTの即完を受けて「#応援させろ」がTwitterのトレンドに掲載されるなど、販売直後からSNSを中心に話題となりました。NFTを支えるブロックチェーン技術を活用し「国内外からの世界共通の暗号資産の即時着金」「為替・決済手数料などの中抜きゼロ」「応援活動の可視化と永続的な履歴保持」などを実現し、web3時代の新たなクラウドファンディングのありかたを示しました。
                  </span>
                </div>
              </div>
            </div>
            {/* 販売されたNFTの仕様について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>販売されたNFTの仕様について</span>
                </div>
                <div className="image flex column align-center justify-center w-full">
                  <img loading="lazy" src={passportNft} alt="" className="w-70" />
                  <span className="lightGray flex justify-start w-70">
                    <span>初期ラボメンバー・パスポートNFT</span>
                  </span>
                </div>
                <div className="article">
                  <span>
                    今回実施されたNFT活用クラウドファンディングでは、これまでの一般的なNFTで課題とされてきた”投機性”を取り除いたプロジェクトとなっている点が特徴です。
                  </span>
                </div>
                <div className="article">
                  <span>
                    通常のNFTはユーザー間での売買など、“二次流通”が行われることにより、資産性を帯び、投機が過熱してしまう側面がありました。しかしながら、今回販売した「パスポートNFT」はSBT形式（ソウルバウンドトークン・Soul
                    Bound
                    Token）での開発がなされており、NFTの購入後、一切の二次流通ができない仕様となっています。また、同時販売された「手裏剣NFT」もステーキング後（後述）は二次流通ができなくなる形式で開発がなされるなど、いずれのNFTも“投機性”を排除した健全なNFTの活用スキームとしてデザインされています。
                  </span>
                </div>
              </div>
            </div>
            {/* web3時代のクラウドファンディングの実現について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>web3時代のクラウドファンディングの実現について</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src="" alt="" className="w-90" />
                </div>
                <div className="article">
                  <span>
                    当組織からはアニメ制作をはじめとするIPを用いた企画の発表が段階的に行われ、ファンが推したい企画があった際には、パスポートNFTと同時販売された「手裏剣NFT」を各企画に応援ステーキング（預ける）することで、制作や開発の進行に直接的に関わることができます。
                    そして、ファンの保有するパスポートNFTはステーキングした手裏剣NFTの枚数に応じてランクやデザインが変化する仕様となっており、ファンは作品やキャラクターを応援すればするほど特別な特典（ユーティリティ）を受けることができます。
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={articleImage6} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    既存のクラウドファンディングの課題としては「着金までに一定の時間を要する」「決済手数料を払う必要性がある」「応援へのリワード（お返し）の物理グッズ制作や配送でコストがかさむ」ことが挙げられますが、当スキームでは「国内外からの世界共通の暗号資産の即時着金」「為替・決済手数料などの中抜きゼロ」「NFTによるデジタルアセットをリワード（お返し）になることで製作コストや配送コストが最小化」など、これまでのクラウドファンディングのデメリットを払拭し、”クラウドファンディング2.0”ともいうべき新しいフォーマットを示したといえます。
                  </span>
                </div>
                <div className="article">
                  <span>
                    また、パスポートNFT、手裏剣NFTとも、ビジュアルを含むすべてのデータがブロックチェーン上に登録されている「フルオンチェーンNFT」として設計されているため、応援活動の半永久的な履歴の保持や、世界中の誰もがその応援履歴を確認することが可能になりました。購入したパスポートには、保有者のウォレットアドレス（ブロックチェーン上の利用者を示す住所のような存在）が掲示される仕組みになっており、ファンの応援活動が対外的に可視化されるほか、NFTの”所有感”を増す工夫が施されています。
                  </span>
                </div>
              </div>
            </div>
            {/* 『CryptoAnime Labs』について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>『CryptoAnime Labs』について</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={cryptoninja} alt="" className="w-90" />
                </div>
                <div className="article">
                  <span>
                    当組織は「web3時代のアニメ制作委員会」をコンセプトに掲げ、アニメ制作やNFT開発のプロフェッショナルメンバーが集まり「LLP（有限責任事業組合）」を組成しています。これまでのクローズドな「製作委員会方式」では実現が難しかった、web3時代のオープンなアニメ制作資金の調達や、資金使途・アニメ制作のプロセスを透明化しながら、ファンのみなさまとともにアニメ作品を世界中に広める支援活動を行っていきます。
                  </span>
                </div>
              </div>
            </div>
            {/*  CryptoNinja NFTとアニメーション作品について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span> CryptoNinja NFTとアニメーション作品について</span>
                </div>
                <div className="article">
                  <span>
                    「CryptoNinja」は、昨今のトレンドである自動生成されたNFTではなく、一枚一枚丁寧に描かれた国内屈指のNFTコレクションです。初期版は世界に50体のみの希少性の高い型で販売、将来的にゲーム化される可能性を見据えて、忍術、流派、武器などのパラメータが設定してあるなど、派生プロジェクトに繋がる設計が成されています。「CryptoAnime
                    Labs」では世界に先駆け、NFT発のアニメ化コンテンツとして、本年1月より新しい形でのアニメ制作に取り組んでいます。
                  </span>
                </div>
              </div>
            </div>

            {/* 運営チーム */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>運営チーム</span>
                </div>
                <div className="subsubtitle">
                  <span>一般社団法人オタクコイン協会</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-50" />
                </div>
                <div className="article">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。2022年初夏には人気フルオンチェーンNFTゲーム『Isekai
                    Battle』のマーケティング・パートナーとして、企画やキャンペーンなどをサポートしています。
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>株式会社ファンワークス</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={fanworks} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    ファンワークスは、2005年に公開したウェブアニメ『やわらか戦車』を皮切りに『がんばれ！ルルロロ
                    TINY☆TWIN☆BEARS』、『英国一家、日本を食べる』、『ざんねんないきもの事典』、『大家さんと僕』、『チキップダンサーズ』等、NHKでのテレビアニメシリーズなど多数のアニメを制作。2018年、サンリオ原作『アグレッシブ烈子（英語名：Aggretsuko）』がNetflixオリジナル作品として全世界配信。ワールドワイドな話題作となり、現在、第4シリーズが配信中。2019年11月、『映画
                    すみっコぐらし
                    とびだす絵本とひみつのコ』の興行収入15億円に迫るヒットを経て、2021年11月に『映画
                    すみっコぐらし
                    青い月夜のまほうのコ』、2022年7月に『映画ざんねんないきもの事典』を公開。
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>株式会社ツクリエ</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={tsucrea} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    ツクリエは事業を作る皆さまを応援するプロフェッショナルとしての起業支援サービス事業と、価値を創造するクリエイターの皆さまを支援するクリエイティブ創造事業を中心に事業展開をしています。
                    起業を目指す方、起業家の方を支援するイベント企画や相談事業、アクセラレーションプログラムの開発から、起業家の方との協同事業、商品開発、総再生数800万回を超えるYouTubeドラマ『おやじキャンプ飯』などの事業プロデュースを行っています。株式会社THE
                    BATTLEは、ツクリエが東京都から受託運営している創業支援施設「東京コンテンツインキュベーションセンター（TCIC）」の入居企業になります。
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>合同会社日本の田舎は資本主義のフロンティアだ</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={frontier} alt="" className="w-60" />
                </div>
                <div className="article">
                  <span>
                    「日本の田舎は資本主義のフロンティアだ」を哲学とし、高知県の山奥から、NFT/Web3事業を展開。日本最大のWeb3系チャンネル「ikehaya
                    Web3
                    Univ.」など、Web3関係のメディアの運営も手掛けている。「CryptoNinja」「Ninja
                    DAO」Founderのikehayaが代表を務める。
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>株式会社THE BATTLE</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={firmLogo} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクトなど、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
              </div>
            </div>
            {/* All view */}
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/news">
                <span>{capitalize("all news")}</span>
              </Link>
            </div>
            {/* タイトル */}
            <div className="title flex column gap-20">
              <div className="bigTitle ja">
                <span>
                  Next Generation Crowdfunding using NFT raises a total of 38
                  Million yen in one minute. Realized immediate cash arrival
                  with crypto assets and intermediate margin such as settlement
                  fees.
                </span>
              </div>
              <div className="en">
                <span>
                  Fundraising for the animation of "CryptoNinja", a popular IP
                  on web3. The "supporter slot sale" sold out immediately, and
                  the phrase "#応援させろ(let me support)" started trending on
                  Twitter.
                </span>
              </div>
            </div>
            {/* 要約 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="article">
                  <span>
                    THE BATTLE Co., Ltd. (Headquarter: Nakano, Tokyo; CEO:
                    Seiryu Sasaki), Otaku Coin Association, Inc., TSUCREA Co.,
                    Ltd., Fanworks Inc., and Japanese countryside is the
                    frontier of capitalism LLC. have launched a web3 era anime
                    "production" committee called "CryptoAnime Labs". The
                    "Initial Lab Member Passport NFT & Shuriken NFT" sold out in
                    one minute, raising a total of 38 million yen. The NFT can
                    be used to "support" the popular web3 IP "CryptoNinja" anime
                    project, and the NFT has become a hot topic on SNS
                    immediately after its sale with “#応援させろ(Let me
                    support)” trending on Twitter. The blockchain technology
                    that supports NFT has been utilized to realize
                    "instantaneous receipt of cryptographic assets from Japan
                    and overseas," "zero middleman in exchange and settlement
                    fees," and "visualization of support activities and
                    permanent history retention," demonstrating the new
                    crowdfunding model of the web3 era.
                  </span>
                </div>
              </div>
            </div>
            {/* Specifications of NFTs */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>Specifications of NFTs</span>
                </div>
                <div className="image flex column align-center justify-center w-full">
                  <img loading="lazy" src={passportNft} alt="" className="w-70" />
                  {/* <span className="lightGray flex justify-start w-70">
                    <span></span>
                  </span> */}
                </div>
                <div className="article">
                  <span>
                    The NFT crowdfunding project is unique in that it removes
                    the "speculative" nature that has been an issue with
                    conventional NFT.
                  </span>
                </div>
                <div className="article">
                  <span>
                    NFTs are usually traded among users for "secondary
                    distribution," and this has the effect of making them
                    assets, and speculation can become heated. However, the
                    "Passport NFT" sold this time was developed in the SBT
                    format (Soul Bound Token), and secondary distribution is not
                    allowed at all after the NFT is purchased. The "Shuriken
                    NFT" sold at the same time was also developed in a format
                    that prevents secondary distribution after staking (see
                    below), and both NFTs are designed as a sound NFT
                    utilization scheme that eliminates "speculation.
                  </span>
                </div>
              </div>
            </div>
            {/* The Realization of Crowdfunding in the web3 Era */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>The Realization of Crowdfunding in the web3 Era</span>
                </div>
                <div className="article">
                  <span>
                    We will announce projects using its IP, including animation
                    production, in stages. When there is a project that fans
                    want to promote, they can be directly involved in the
                    production and development process by supporting each
                    project with a "Shuriken NFT" (staking), which is sold at
                    the same time as the Passport NFT. The Passport NFTs held by
                    fans are designed to change in rank and design according to
                    the number of Shuriken NFTs they have staked, and the more
                    fans support a work or character, the more special benefits
                    (utilities) they will receive.
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={articleImage6} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    The challenges of existing crowdfunding are that "it takes a
                    certain amount of time to receive funds," "there is a need
                    to pay settlement fees," and "the cost of producing and
                    delivering physical goods as rewards for support is high. We
                    have eliminated the disadvantages of conventional
                    crowdfunding, such as "immediate arrival of crypto assets
                    from Japan and abroad," "no middleman in terms of exchange
                    rates and settlement fees," and "minimized production and
                    delivery costs by rewarding digital assets through NFT," and
                    has shown a new format that can be called "Crowdfunding 2.0.
                  </span>
                </div>
                <div className="article">
                  <span>
                    In addition, both Passport NFT and Shuriken NFT are designed
                    as "full-on-chain NFT" where all data, including visuals,
                    are registered on the blockchain, making it possible to
                    maintain a semi-permanent history of support activities and
                    to check the history of such support by anyone in the world.
                  </span>
                </div>
                <div className="article">
                  <span>
                    The passport NFT purchased by the user has the holder's
                    wallet address (a kind of address that indicates the user on
                    the blockchain) posted on the passport, making the fan's
                    support activities visible to the outside world and
                    increasing the "sense of ownership" of the NFT.
                  </span>
                </div>
              </div>
            </div>
            {/* About CryptoAnime Labs */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>About CryptoAnime Labs</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={cryptoninja} alt="" className="w-90" />
                </div>
                <div className="article">
                  <span>
                    Under the concept of an "Animation Production Committee for
                    the web3 era", our organization is an "LLP (Limited
                    Liability Partnership)" formed by a group of professional
                    members in the animation production and NFT development
                    fields.We will support the spread of animation around the
                    world together with fans through open procurement of funds
                    for animation production in the web3 era, which has been
                    difficult to achieve with the conventional closed
                    "production committee" method, and by making the use of
                    funds and the animation production process transparent.
                  </span>
                </div>
              </div>
            </div>
            {/*  About CryptoNinja NFT and its animated films */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>About CryptoNinja NFT and its animated films</span>
                </div>
                <div className="article">
                  <span>
                    CryptoNinja is one of the best NFT collections in Japan,
                    carefully drawn piece by piece, rather than automatically
                    generated NFT, which is the current trend. The initial
                    version is sold as a rare type with only 50 pieces in the
                    world, and is designed to lead to derivative projects, with
                    parameters such as ninjutsu, schools, and weapons set with
                    the possibility of being made into a game in the future.
                    CryptoAnime Labs" has been working on a new form of anime
                    production since January of this year, as the world's first
                    NFT-animated content.
                  </span>
                </div>
              </div>
            </div>

            {/* About the participating companies in CryptoAnime Labs */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    About the participating companies in CryptoAnime Labs
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>Otaku Coin Association, Inc.</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-50" />
                </div>
                <div className="article">
                  <span>
                    The Otaku Coin Association has been working for the
                    development of anime culture since December 2017, including
                    its predecessor, the Otaku Coin Preparation Committee, with
                    the goal of penetrating, introducing, and utilizing
                    blockchain technology within the anime industry in order to
                    spread Japanese anime culture throughout the world. In early
                    summer of 2022, Otaku Coin Association, Inc. is the
                    marketing partner for the popular full-on chain NFT game
                    "Isekai Battle", supporting its planning and campaigns.
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>Fanworks, Inc.</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={fanworks} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    Fanworks began with the web animation "The Legend of the
                    Yawaraka Tank" released in 2005, followed by "Lulu Lolo
                    TINY☆TWIN☆BEARS", "Sushi & Beyond: What the Japanese Know
                    About Cooking", "Sad Animal Facts", "The Landlord and I",
                    "Chickip Dancers", and many other animated TV series on NHK,
                    etc. In 2018, "Aggretsuko" based on Sanrio original work was
                    distributed worldwide as a Netflix original. In November
                    2019, "Sumikko Gurashi The Movie : The Pop-up Book and the
                    Secret Child" became a hit, earning close to 1.5 billion yen
                    at the box office, followed by "Sumikko Gurashi the Movie:
                    Mahounoko of the Blue Moonlight Night" in November 2021 and
                    "Sad Animal Facts the movie" in July 2022.
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>TSUCREA Co., Ltd.</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={tsucrea} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    TSUCREA is developing its business with a focus on
                    entrepreneurship support services for professionals who
                    support business creators, and creative creation services
                    for creators who create value. TSUCREA also provides event
                    planning and consultation services to support aspiring
                    entrepreneurs and entrepreneurs, develop acceleration
                    programs, collaborate with entrepreneurs, develop products,
                    and produce businesses such as "Oyaji Camp Meshi," a YouTube
                    drama that has received more than 8 million views. THE
                    BATTLE Inc. is a tenant of the Tokyo Contents Incubation
                    Center (TCIC), a start-up support facility commissioned and
                    operated by the Tokyo Metropolitan Government.
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>
                    Goudou kaisha nihon no inaka wa shihon shugi no frontier da
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={frontier} alt="" className="w-60" />
                </div>
                <div className="article">
                  <span>
                    With the philosophy that "Japan's countryside is the
                    frontier of capitalism," he is developing his NFT/Web3
                    business from deep in the mountains of Kochi Prefecture. He
                    also manages Web3-related media, including Japan's largest
                    Web3 channel "ikehaya Web3 Univ.” The company is headed by
                    ikehaya, founder of CryptoNinja and Ninja DAO.
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>THE BATTLE Inc.</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={firmLogo} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    THE BATTLE was founded as a "web3 organization, fighting
                    against conventional wisdom". The core members have been
                    active in the blockchain area since 2018, and are involved
                    in various creative directions. Currently, we are working on
                    an anime adaptation project of the popular NFT work
                    "CryptoNinja NFT" at the web3 type anime production
                    committee method "CryptoAnime Labs", as well as planning and
                    developing a full on-chain blockchain game "Isekai Battle".
                    We are also working on several projects centered on
                    entertainment x blockchain, including an IP creation project
                    with animation studio ABC ANIMATION, INC.
                  </span>
                </div>
              </div>
            </div>
            {/* All view */}
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/news">
                <span>{capitalize("all news")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachNews">
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachNewsContainer flex column gap-50">
            {/* タイトル */}
            <div className="titleContent flex column gap-30">
              <div className="jaTitle">
                <span>
                  NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                </span>
              </div>
            </div>
            {/* web3で人気のIP“CryptoNinja”のアニメ化に向けた資金調達。 */}
            <article className="content flex column gap-50">
              <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage5} alt="" className="w-80" />
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    web3で人気のIP“CryptoNinja”のアニメ化に向けた資金調達。“応援枠”の即時完売を受けてTwitterで「#応援させろ」がトレンド入り。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木清龍、以下当社）は、一般社団法人オタクコイン協会、株式会社ツクリエ、株式会社ファンワークス、合同会社日本の田舎は資本主義のフロンティアだ
                    と共同設立した、web3時代のアニメ”制作”委員会『CryptoAnime
                    Labs（以下当組織）』より販売した「初期ラボメンバー・パスポートNFT&手裏剣NFT」が、販売時間総計1分で2万枚以上のNFTが完売し、累計3,800万円相当の資金を調達したことを発表いたします。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    販売したNFTはweb3領域で人気のIP“CryptoNinja（読み：クリプトニンジャ）”のアニメ化プロジェクトの“応援”に使用することができ、NFTの即完を受けて「#応援させろ」がTwitterのトレンドに掲載されるなど、販売直後からSNSを中心に話題となりました。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    NFTを支えるブロックチェーン技術を活用し「国内外からの世界共通の暗号資産の即時着金」「為替・決済手数料などの中抜きゼロ」「応援活動の可視化と永続的な履歴保持」などを実現し、web3時代の新たなクラウドファンディングのありかたを示しました。
                  </span>
                </div>
              </div>
            </article>
            {/* 販売されたNFTの仕様について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>販売されたNFTの仕様について</span>
                </div>
                <div className="flex column align-center justify-center">
                  <img loading="lazy" src={passportNft} alt="" className="w-80" />
                  <span className="flex justify-start w-80">
                    <span className="lightGray">
                      初期ラボメンバー・パスポートNFT
                    </span>
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    今回実施されたNFT活用クラウドファンディングでは、これまでの一般的なNFTで課題とされてきた”投機性”を取り除いたプロジェクトとなっている点が特徴です。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    通常のNFTはユーザー間での売買など、“二次流通”が行われることにより、資産性を帯び、投機が過熱してしまう側面がありました。しかしながら、今回販売した「パスポートNFT」はSBT形式（ソウルバウンドトークン・Soul
                    Bound
                    Token）での開発がなされており、NFTの購入後、一切の二次流通ができない仕様となっています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    また、同時販売された「手裏剣NFT」もステーキング後（後述）は二次流通ができなくなる形式で開発がなされるなど、いずれのNFTも“投機性”を排除した健全なNFTの活用スキームとしてデザインされています。
                  </span>
                </div>
              </div>
            </article>
            {/* web3時代のクラウドファンディングの実現について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>web3時代のクラウドファンディングの実現について</span>
                </div>
                <div className="jaContent">
                  <span>
                    当組織からはアニメ制作をはじめとするIPを用いた企画の発表が段階的に行われ、ファンが推したい企画があった際には、パスポートNFTと同時販売された「手裏剣NFT」を各企画に応援ステーキング（預ける）することで、制作や開発の進行に直接的に関わることができます。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    そして、ファンの保有するパスポートNFTはステーキングした手裏剣NFTの枚数に応じてランクやデザインが変化する仕様となっており、ファンは作品やキャラクターを応援すればするほど特別な特典（ユーティリティ）を受けることができます。
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={articleImage6} alt="" className="w-80" />
                </div>
                <div className="jaContent">
                  <span>
                    既存のクラウドファンディングの課題としては「着金までに一定の時間を要する」「決済手数料を払う必要性がある」「応援へのリワード（お返し）の物理グッズ制作や配送でコストがかさむ」ことが挙げられますが、当スキームでは「国内外からの世界共通の暗号資産の即時着金」「為替・決済手数料などの中抜きゼロ」「NFTによるデジタルアセットをリワード（お返し）になることで製作コストや配送コストが最小化」など、これまでのクラウドファンディングのデメリットを払拭し、”クラウドファンディング2.0”ともいうべき新しいフォーマットを示したといえます。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    また、パスポートNFT、手裏剣NFTとも、ビジュアルを含むすべてのデータがブロックチェーン上に登録されている「フルオンチェーンNFT」として設計されているため、応援活動の半永久的な履歴の保持や、世界中の誰もがその応援履歴を確認することが可能になりました。購入したパスポートには、保有者のウォレットアドレス（ブロックチェーン上の利用者を示す住所のような存在）が掲示される仕組みになっており、ファンの応援活動が対外的に可視化されるほか、NFTの”所有感”を増す工夫が施されています。
                  </span>
                </div>
              </div>
            </article>
            {/* 『CryptoAnime Labs』について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>『CryptoAnime Labs』について</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={cryptoninja} alt="" className="w-90" />
                </div>
                <div className="jaContent">
                  <span>
                    当組織は「web3時代のアニメ制作委員会」をコンセプトに掲げ、アニメ制作やNFT開発のプロフェッショナルメンバーが集まり「LLP（有限責任事業組合）」を組成しています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    これまでのクローズドな「製作委員会方式」では実現が難しかった、web3時代のオープンなアニメ制作資金の調達や、資金使途・アニメ制作のプロセスを透明化しながら、ファンのみなさまとともにアニメ作品を世界中に広める支援活動を行っていきます。
                  </span>
                </div>
              </div>
            </article>
            {/* CryptoNinja NFTとアニメーション作品について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>CryptoNinja NFTとアニメーション作品について</span>
                </div>
                <div className="jaContent">
                  <span>
                    「CryptoNinja」は、昨今のトレンドである自動生成されたNFTではなく、一枚一枚丁寧に描かれた国内屈指のNFTコレクションです。初期版は世界に50体のみの希少性の高い型で販売、将来的にゲーム化される可能性を見据えて、忍術、流派、武器などのパラメータが設定してあるなど、派生プロジェクトに繋がる設計が成されています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    「CryptoAnime
                    Labs」では世界に先駆け、NFT発のアニメ化コンテンツとして、本年1月より新しい形でのアニメ制作に取り組んでいます。
                  </span>
                </div>
              </div>
            </article>
            {/* 運営チーム */}
            {/* オタクコイン協会 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>運営チーム</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>一般社団法人オタクコイン協会</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-60" />
                </div>
                <div className="jaContent">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    2022年初夏には人気フルオンチェーンNFTゲーム『Isekai
                    Battle』のマーケティング・パートナーとして、企画やキャンペーンなどをサポートしています。
                  </span>
                </div>
                {/* ファンワークス */}
                <div className="jaContent fontWeight500">
                  <span>株式会社ファンワークス</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={fanworks} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    ファンワークスは、2005年に公開したウェブアニメ『やわらか戦車』を皮切りに『がんばれ！ルルロロ
                    TINY☆TWIN☆BEARS』、『英国一家、日本を食べる』、『ざんねんないきもの事典』、『大家さんと僕』、『チキップダンサーズ』等、NHKでのテレビアニメシリーズなど多数のアニメを制作。2018年、サンリオ原作『アグレッシブ烈子（英語名：Aggretsuko）』がNetflixオリジナル作品として全世界配信。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ワールドワイドな話題作となり、現在、第4シリーズが配信中。2019年11月、『映画
                    すみっコぐらし
                    とびだす絵本とひみつのコ』の興行収入15億円に迫るヒットを経て、2021年11月に『映画
                    すみっコぐらし
                    青い月夜のまほうのコ』、2022年7月に『映画ざんねんないきもの事典』を公開。
                  </span>
                </div>
                {/* 株式会社ツクリエ */}
                <div className="jaContent fontWeight500">
                  <span>株式会社ツクリエ</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={tsucrea} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    ツクリエは事業を作る皆さまを応援するプロフェッショナルとしての起業支援サービス事業と、価値を創造するクリエイターの皆さまを支援するクリエイティブ創造事業を中心に事業展開をしています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    起業を目指す方、起業家の方を支援するイベント企画や相談事業、アクセラレーションプログラムの開発から、起業家の方との協同事業、商品開発、総再生数800万回を超えるYouTubeドラマ『おやじキャンプ飯』などの事業プロデュースを行っています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    株式会社THE
                    BATTLEは、ツクリエが東京都から受託運営している創業支援施設「東京コンテンツインキュベーションセンター（TCIC）」の入居企業になります。
                  </span>
                </div>
                {/* 合同会社日本の田舎は資本主義のフロンティアだ */}
                <div className="jaContent fontWeight500">
                  <span>合同会社日本の田舎は資本主義のフロンティアだ</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={frontier} alt="" className="w-60" />
                </div>
                <div className="jaContent">
                  <span>
                    「日本の田舎は資本主義のフロンティアだ」を哲学とし、高知県の山奥から、NFT/Web3事業を展開。日本最大のWeb3系チャンネル「ikehaya
                    Web3
                    Univ.」など、Web3関係のメディアの運営も手掛けている。「CryptoNinja」「Ninja
                    DAO」Founderのikehayaが代表を務める。
                  </span>
                </div>
                {/* 株式会社THE BATTLE */}
                <div className="jaContent fontWeight500">
                  <span>株式会社THE BATTLE</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={firmLogo} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクトなど、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
                {/* All view */}
                <div className="allView flex align-center justify-center w-full">
                  <span>
                    <Link to="/news">{capitalize("all news")}</Link>
                  </span>
                </div>
              </div>
            </article>
            {/* タイトル */}
            <div className="titleContent flex column gap-30">
              <div className="jaTitle">
                <span>
                  Next Generation Crowdfunding using NFT raises a total of 38
                  Million yen in one minute. Realized immediate cash arrival
                  with crypto assets and intermediate margin such as settlement
                  fees.
                </span>
              </div>
            </div>
            {/* web3で人気のIP“CryptoNinja”のアニメ化に向けた資金調達。 */}
            <article className="content flex column gap-50">
              <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage5} alt="" className="w-80" />
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    Fundraising for the animation of "CryptoNinja", a popular IP
                    on web3. The "supporter slot sale" sold out immediately, and
                    the phrase "#応援させろ(let me support)" started trending on
                    Twitter.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE Co., Ltd. (Headquarter: Nakano, Tokyo; CEO:
                    Seiryu Sasaki), Otaku Coin Association, Inc., TSUCREA Co.,
                    Ltd., Fanworks Inc., and Japanese countryside is the
                    frontier of capitalism LLC. have launched a web3 era anime
                    "production" committee called "CryptoAnime Labs". The
                    "Initial Lab Member Passport NFT & Shuriken NFT" sold out in
                    one minute, raising a total of 38 million yen. The NFT can
                    be used to "support" the popular web3 IP "CryptoNinja" anime
                    project, and the NFT has become a hot topic on SNS
                    immediately after its sale with “#応援させろ(Let me
                    support)” trending on Twitter. The blockchain technology
                    that supports NFT has been utilized to realize
                    "instantaneous receipt of cryptographic assets from Japan
                    and overseas," "zero middleman in exchange and settlement
                    fees," and "visualization of support activities and
                    permanent history retention," demonstrating the new
                    crowdfunding model of the web3 era.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    The NFT can be used to "support" the popular web3 IP
                    "CryptoNinja" anime project, and the NFT has become a hot
                    topic on SNS immediately after its sale with
                    “#応援させろ(Let me support)” trending on Twitter. The
                    blockchain technology that supports NFT has been utilized to
                    realize "instantaneous receipt of cryptographic assets from
                    Japan and overseas," "zero middleman in exchange and
                    settlement fees," and "visualization of support activities
                    and permanent history retention," demonstrating the new
                    crowdfunding model of the web3 era.
                  </span>
                </div>
              </div>
            </article>
            {/* Specifications of NFTs */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>Specifications of NFTs</span>
                </div>
                <div className="flex column align-center justify-center">
                  <img loading="lazy" src={passportNft} alt="" className="w-80" />
                </div>
                <div className="jaContent">
                  <span>
                    The NFT crowdfunding project is unique in that it removes
                    the "speculative" nature that has been an issue with
                    conventional NFT.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    NFTs are usually traded among users for "secondary
                    distribution," and this has the effect of making them
                    assets, and speculation can become heated. However, the
                    "Passport NFT" sold this time was developed in the SBT
                    format (Soul Bound Token), and secondary distribution is not
                    allowed at all after the NFT is purchased.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    The "Shuriken NFT" sold at the same time was also developed
                    in a format that prevents secondary distribution after
                    staking (see below), and both NFTs are designed as a sound
                    NFT utilization scheme that eliminates "speculation.
                  </span>
                </div>
              </div>
            </article>
            {/* The Realization of Crowdfunding in the web3 Era */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>The Realization of Crowdfunding in the web3 Era</span>
                </div>
                <div className="jaContent">
                  <span>
                    We will announce projects using its IP, including animation
                    production, in stages. When there is a project that fans
                    want to promote, they can be directly involved in the
                    production and development process by supporting each
                    project with a "Shuriken NFT" (staking), which is sold at
                    the same time as the Passport NFT.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    The Passport NFTs held by fans are designed to change in
                    rank and design according to the number of Shuriken NFTs
                    they have staked, and the more fans support a work or
                    character, the more special benefits (utilities) they will
                    receive.
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={articleImage6} alt="" className="w-80" />
                </div>
                <div className="jaContent">
                  <span>
                    The challenges of existing crowdfunding are that "it takes a
                    certain amount of time to receive funds," "there is a need
                    to pay settlement fees," and "the cost of producing and
                    delivering physical goods as rewards for support is high. We
                    have eliminated the disadvantages of conventional
                    crowdfunding, such as "immediate arrival of crypto assets
                    from Japan and abroad," "no middleman in terms of exchange
                    rates and settlement fees," and "minimized production and
                    delivery costs by rewarding digital assets through NFT," and
                    has shown a new format that can be called "Crowdfunding 2.0.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    In addition, both Passport NFT and Shuriken NFT are designed
                    as "full-on-chain NFT" where all data, including visuals,
                    are registered on the blockchain, making it possible to
                    maintain a semi-permanent history of support activities and
                    to check the history of such support by anyone in the world.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    The passport NFT purchased by the user has the holder's
                    wallet address (a kind of address that indicates the user on
                    the blockchain) posted on the passport, making the fan's
                    support activities visible to the outside world and
                    increasing the "sense of ownership" of the NFT.
                  </span>
                </div>
              </div>
            </article>
            {/* About CryptoAnime Labs */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>About CryptoAnime Labs</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={cryptoninja} alt="" className="w-90" />
                </div>
                <div className="jaContent">
                  <span>
                    Under the concept of an "Animation Production Committee for
                    the web3 era", our organization is an "LLP (Limited
                    Liability Partnership)" formed by a group of professional
                    members in the animation production and NFT development
                    fields.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    We will support the spread of animation around the world
                    together with fans through open procurement of funds for
                    animation production in the web3 era, which has been
                    difficult to achieve with the conventional closed
                    "production committee" method, and by making the use of
                    funds and the animation production process transparent.
                  </span>
                </div>
              </div>
            </article>
            {/* About CryptoNinja NFT and its animated films */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>About CryptoNinja NFT and its animated films</span>
                </div>
                <div className="jaContent">
                  <span>
                    CryptoNinja is one of the best NFT collections in Japan,
                    carefully drawn piece by piece, rather than automatically
                    generated NFT, which is the current trend. The initial
                    version is sold as a rare type with only 50 pieces in the
                    world, and is designed to lead to derivative projects, with
                    parameters such as ninjutsu, schools, and weapons set with
                    the possibility of being made into a game in the future.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    CryptoAnime Labs" has been working on a new form of anime
                    production since January of this year, as the world's first
                    NFT-animated content.
                  </span>
                </div>
              </div>
            </article>
            {/*  About the participating companies in CryptoAnime Labs */}
            {/* オタクコイン協会 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    About the participating companies in CryptoAnime Labs
                  </span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>Otaku Coin Association, Inc.</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-60" />
                </div>
                <div className="jaContent">
                  <span>
                    The Otaku Coin Association has been working for the
                    development of anime culture since December 2017, including
                    its predecessor, the Otaku Coin Preparation Committee, with
                    the goal of penetrating, introducing, and utilizing
                    blockchain technology within the anime industry in order to
                    spread Japanese anime culture throughout the world.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    In early summer of 2022, Otaku Coin Association, Inc. is the
                    marketing partner for the popular full-on chain NFT game
                    "Isekai Battle", supporting its planning and campaigns.
                  </span>
                </div>
                {/* Fanworks, Inc. */}
                <div className="jaContent fontWeight500">
                  <span>Fanworks, Inc.</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={fanworks} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    Fanworks began with the web animation "The Legend of the
                    Yawaraka Tank" released in 2005, followed by "Lulu Lolo
                    TINY☆TWIN☆BEARS", "Sushi & Beyond: What the Japanese Know
                    About Cooking", "Sad Animal Facts", "The Landlord and I",
                    "Chickip Dancers", and many other animated TV series on NHK,
                    etc. In 2018, "Aggretsuko" based on Sanrio original work was
                    distributed worldwide as a Netflix original.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    In November 2019, "Sumikko Gurashi The Movie : The Pop-up
                    Book and the Secret Child" became a hit, earning close to
                    1.5 billion yen at the box office, followed by "Sumikko
                    Gurashi the Movie: Mahounoko of the Blue Moonlight Night" in
                    November 2021 and "Sad Animal Facts the movie" in July 2022.
                  </span>
                </div>
                {/* TSUCREA Co., Ltd. */}
                <div className="jaContent fontWeight500">
                  <span>TSUCREA Co., Ltd.</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={tsucrea} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    TSUCREA is developing its business with a focus on
                    entrepreneurship support services for professionals who
                    support business creators, and creative creation services
                    for creators who create value.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    TSUCREA also provides event planning and consultation
                    services to support aspiring entrepreneurs and
                    entrepreneurs, develop acceleration programs, collaborate
                    with entrepreneurs, develop products, and produce businesses
                    such as "Oyaji Camp Meshi," a YouTube drama that has
                    received more than 8 million views.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE Inc. is a tenant of the Tokyo Contents Incubation
                    Center (TCIC), a start-up support facility commissioned and
                    operated by the Tokyo Metropolitan Government.
                  </span>
                </div>
                {/* Goudou kaisha nihon no inaka wa shihon shugi no frontier da */}
                <div className="jaContent fontWeight500">
                  <span>
                    Goudou kaisha nihon no inaka wa shihon shugi no frontier da
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={frontier} alt="" className="w-60" />
                </div>
                <div className="jaContent">
                  <span>
                    With the philosophy that "Japan's countryside is the
                    frontier of capitalism," he is developing his NFT/Web3
                    business from deep in the mountains of Kochi Prefecture. He
                    also manages Web3-related media, including Japan's largest
                    Web3 channel "ikehaya Web3 Univ.” The company is headed by
                    ikehaya, founder of CryptoNinja and Ninja DAO.
                  </span>
                </div>
                {/* THE BATTLE Inc. */}
                <div className="jaContent fontWeight500">
                  <span>THE BATTLE Inc.</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={firmLogo} alt="" className="w-70" />
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE was founded as a "web3 organization, fighting
                    against conventional wisdom". The core members have been
                    active in the blockchain area since 2018, and are involved
                    in various creative directions.
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    Currently, we are working on an anime adaptation project of
                    the popular NFT work "CryptoNinja NFT" at the web3 type
                    anime production committee method "CryptoAnime Labs", as
                    well as planning and developing a full on-chain blockchain
                    game "Isekai Battle". We are also working on several
                    projects centered on entertainment x blockchain, including
                    an IP creation project with animation studio ABC ANIMATION,
                    INC.
                  </span>
                </div>
              </div>
            </article>
            {/* All view */}
            <div className="allView flex align-center justify-center w-full">
              <span>
                <Link to="/news">{capitalize("all news")}</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Topic4;
