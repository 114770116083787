import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import Contact01 from "../compressed/Contact01.webp";
import SPContact01 from "../compressed/SPContact01.webp";

const Contact = (props) => {
  const capitalize = (text) => text.toUpperCase();
  const windowWidth = props.useWindowWidth();
  const matrix = useInView({
    threshold: 0.4, //40%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section
        id="Contact"
        className="flex  Cormorant_Garamond relative"
        ref={matrix.ref}
      >
        <div
          className="mainText absolute w-full"
          ref={props.contactDetailBlurRef}
        >
          <div
            className={`matrix flex align-center justify-center w-full ${
              matrix.inView ? "is-animated" : ""
            }`}
          >
            <span className="text_wrap">
              <span className="inn inn1 user-select-none">
                {capitalize("the battle")}
              </span>
            </span>
          </div>
        </div>
        <div className="ContactContainer flex column align-center justify-center relative h-full">
          <div className="upperContent flex align-end justify-center w-full">
            <div className="ContactLeft flex align-end">
              {props.page === "main" && (
                <ul className="flex column justify-end align-start gap-12 cursor w-full">
                  <li>
                    <a href="/">{capitalize("home")}</a>
                  </li>
                  <li onClick={props.scrollNews}>{capitalize("news")}</li>
                  <li onClick={props.scrollProject}>
                    {capitalize("projects")}
                  </li>
                  <li onClick={props.scrollCompany}>{capitalize("company")}</li>
                  {/* <li onClick={props.scrollRecruit}>{capitalize("recruit")}</li> */}
                </ul>
              )}
              {props.page === "detail" && (
                <ul className="flex column justify-end align-start gap-12 cursor w-full">
                  <li>
                    <a href="/">{capitalize("home")}</a>
                  </li>
                  <li>
                    <Link
                      to="/news"
                      className={
                        props.sectionPage === "news"
                          ? "strike_out_line_contact_white"
                          : ""
                      }
                    >
                      {capitalize("news")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/projects"
                      className={
                        props.sectionPage === "projects"
                          ? "strike_out_line_contact_white"
                          : ""
                      }
                    >
                      {capitalize("projects")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/company"
                      className={
                        props.sectionPage === "company"
                          ? "strike_out_line_contact_white"
                          : ""
                      }
                    >
                      {capitalize("company")}
                    </Link>
                  </li>
                  {/* <li><Link to="/recruit" className={props.sectionPage === "recruit" ? "strike_out_line_contact_white" : ""}>{capitalize("recruit")}</Link></li> */}
                </ul>
              )}
            </div>
            <div className="ContactMiddle flex align-end justify-center">
              <img loading="lazy" src={Contact01} alt="" className="h-75vh" />
            </div>
            <div className="ContactRight flex column align-end justify-end gap-12 w-full">
              <div className="jaText">
                <span>
                  わたしたちは『web3×エンタメ領域』の可能性を模索しつづけます。あなたの声をお待ちしております。
                </span>
              </div>
              <div className="enText">
                <span>
                  We continue to explore the possibilities of "web3 x
                  entertainment". We are looking forward to hearing from you.
                </span>
              </div>
            </div>
          </div>
          <div className="downerContent flex align-end justify-center">
            <div className="">©2023 THE BATTLE Inc. All Rights Reserved.</div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spContact">
        <div className="spContactContainer flex column w-full h-full relative">
          <div className="contactBigText absolute w-full">
            <div className="flex align-center justify-center">
              <span className="user-select-none">
                {capitalize("the battle")}
              </span>
            </div>
          </div>
          <div className="contactDownContent flex column w-full">
            <div className="image">
              <img loading="lazy" src={SPContact01} alt="" className="w-full" />
            </div>
            <div className="w-70 flex column gap-30">
              <div className="ja">
                <span>
                  わたしたちは『web3×エンタメ領域』の可能性を模索しつづけます。あなたの声をお待ちしております。
                </span>
              </div>
              <div className="en">
                <span>
                  We continue to explore the possibilities of "web3 x
                  entertainment". We are looking forward to hearing from you.
                </span>
              </div>
            </div>
            <div className="menu">
              <ul>
                <li>
                  <a href="/">{capitalize("home")}</a>
                </li>
                <li>
                  <Link
                    to="/news"
                    className={
                      props.sectionPage === "news" ? "strike_out_line_sp" : ""
                    }
                  >
                    {capitalize("news")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/projects"
                    className={
                      props.sectionPage === "projects"
                        ? "strike_out_line_sp"
                        : ""
                    }
                  >
                    {capitalize("projects")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/company"
                    className={
                      props.sectionPage === "company"
                        ? "strike_out_line_sp"
                        : ""
                    }
                  >
                    {capitalize("company")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/recruit" className={props.sectionPage === "recruit" ? "strike_out_line_sp" : ""}>{capitalize("recruit")}</Link>
                </li> */}
              </ul>
            </div>
            <div className="copyright flex align-center justify-center">
              <span>©2023 THE BATTLE Inc. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Contact;
