import React from "react";
import { Link } from "react-router-dom";
import battleLogoWhite from "../compressed/battleLogoWhite.webp";
import ContactPage01 from "../compressed/ContactPage01.webp";

const ContactPage = ({ useWindowWidth }) => {
  const capitalize = (text) => text.toUpperCase();
  const windowWidth = useWindowWidth();

  if (windowWidth > 496) {
  return (
    <section id="ContactPage" className="flex">
      <header className="flex column align-center justify-between">
        <div className="headerImage flex align-center justify-center">
          <Link to="/">
            <img loading="lazy" src={battleLogoWhite} alt="" />
          </Link>
        </div>
        <ul className="flex align-center justify-center vertical_rl">
          <li>
            <Link to="/">{capitalize("home")}</Link>
          </li>
          <li>
            <Link to="/news">{capitalize("news")}</Link>
          </li>
          <li>
            <Link to="/projects">{capitalize("projects")}</Link>
          </li>
          <li>
            <Link to="/company">{capitalize("company")}</Link>
          </li>
          <li>
            <Link to="/recruit">{capitalize("recruit")}</Link>
          </li>
        </ul>
      </header>
      <div className="Container page_contanier flex">
        <div className="upper flex column justify-end w-full h-full">
          <div className="BigNumber w-full">
            <h1 className="w-full">05</h1>
          </div>
          <div className="downer flex">
            <div className="subTitle flex align-end">
              <span>{capitalize("contact")}</span>
            </div>
            <div className="text flex column align-end justify-end gap-20">
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私はほか無論その一言士についてのの時から這入りだ私はほか無論その一言士についてのの時から這入りだます。
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="NewsPageLeft">
          <img loading="lazy" src={ContactPage01} alt="" className="w-full" />
        </div>
      </div>
    </section>
  );
  }
};

export default ContactPage;
