import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SliderButton } from "@typeform/embed-react";

import RecruitPage01 from "../compressed/RecruitPage01.webp";
import battleLogo from "../compressed/battleLogo.webp";
import SPRecruit03 from "../compressed/SPRecruit03.webp";
import SPRecruitDetail01 from "../compressed/SPRecruitDetail01.webp";

const RecruitPage = ({
  useWindowWidth,
  handlerToggle,
  contactDetailBlurInView,
}) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section id="RecruitPage" className="flex" ref={blur.ref}>
        {contactDetailBlurInView ? (
          <></>
        ) : (
          <header className="flex column align-center justify-between">
            <div className="headerImage flex align-center justify-center">
              <Link to="/">
                <img loading="lazy" src={battleLogo} alt="" />
              </Link>
            </div>
            <ul className="flex align-center justify-center vertical_rl">
              <li>
                <Link to="/">{capitalize("home")}</Link>
              </li>
              <li>
                <Link to="/news">{capitalize("news")}</Link>
              </li>
              <li>
                <Link to="/projects">{capitalize("projects")}</Link>
              </li>
              <li>
                <Link to="/company">{capitalize("company")}</Link>
              </li>
              <li>
                <Link to="/recruit">{capitalize("recruit")}</Link>
              </li>
            </ul>
          </header>
        )}
        <div className="Container page_contanier flex">
          <div className="upper flex column justify-end w-full h-full">
            {blur.inView && (
              <div className="BigNumber blurNumber w-full user-select-none">
                <h1 className="w-full">04</h1>
              </div>
            )}
            <div className="downer flex justify-center w-full">
              <div
                className={`subTitle flex align-end ${
                  blur.inView ? "blurTitle" : "invisible"
                }`}
              >
                <span className="user-select-none">
                  {capitalize("recruit")}
                </span>
              </div>
              {blur.inView && (
                <div className="text flex column align-end justify-end gap-20 blurText">
                  <div className="jaText">
                    <span>
                      私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私はほか無論その一言士についてのの時から這入りだ私はほか無論その一言士についてのの時から這入りだます。
                    </span>
                  </div>
                  <div className="enText">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in Ut enim Lorem ipsum dolor sit
                      amet, consectetur adipisicing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="NewsPageLeft flex column">
            <img loading="lazy" src={RecruitPage01} alt="" className="w-full h-full" />
            {/* <div className="flex align-center justify-center">
              <span>{capitalize("contact")}</span>
            </div> */}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spRecruitPage" ref={blur.ref}>
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed mix_difference">
            <SliderButton id="wJm1bGdB" className="SPtypeformBtn">
              {capitalize("contact")}
            </SliderButton>
          </div>
          <div className="spDetailDowner flex column relative">
            {blur.inView && (
              <div className="titlenumber absolute blurNumber">
                <span>04</span>
              </div>
            )}
            <div className="titleimage relative">
              <div
                className={`absolute mix_difference ${
                  blur.inView ? "blurTitle" : ""
                }`}
              >
                <span>{capitalize("recruit")}</span>
              </div>
              <div className="">
                <img loading="lazy" src={SPRecruit03} alt="" className="w-full" />
              </div>
            </div>
            {blur.inView && (
              <div className="titletext flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私はほか無論その一言士についてのの時から這入りだ私はほか無論その一言士についてのの時から這入りだます。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                  </span>
                </div>
              </div>
            )}

            <div className="spConfronting flex column">
              <div className="flex w-full">
                <img loading="lazy" src={SPRecruitDetail01} alt="" className="w-full" />
              </div>
              <div className="spMessageText mix_difference flex column align-center justify-center w-full">
                {blur.inView && (
                  <div className="en flex column w-full blurNumber">
                    <div className="flex align-center justify-center w-full">
                      <span>{capitalize("confronting")}</span>
                    </div>
                    <div className="flex align-center justify-center w-full">
                      <span>{capitalize("society")}</span>
                    </div>
                    <div className="flex align-center justify-center w-full">
                      <span>{capitalize("together")}</span>
                    </div>
                  </div>
                )}
                {blur.inView && (
                  <div className="ja flex align-center justify-center w-full blurTitle">
                    <span>共に、高め合える仲間を。</span>
                  </div>
                )}
              </div>
            </div>
            <div className="content flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("careers")}</span>
                </div>
                <div className="enText w-60">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  </span>
                </div>
              </div>
              {/* 求人内容 */}
              <div className="spRecruitContent flex column gap-50 w-full">
                <div className="spRecruitRoll flex column gap-30 w-full">
                  <div className="number flex align-end justify-end w-full">
                    <span>01</span>
                  </div>
                  <div className="roll flex column align-start justify-start gap-20">
                    <div className="en w-70">
                      <span>{iniCapitalize("design engineering")}</span>
                    </div>
                    <div className="ja w-70">
                      <span>ダミーエンジニア</span>
                    </div>
                  </div>
                </div>
                <div className="spRecruitText flex column gap-30 w-full">
                  <div className="ja">
                    <span>
                      私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in Ut enim Lorem ipsum dolor sit
                      amet, consectetur
                    </span>
                  </div>
                </div>
                <div className="allview flex align-end justify-end w-full">
                  <Link to="/recruit/design-engineer">
                    {capitalize("all view")}
                  </Link>
                </div>
              </div>
              {/* 求人内容 */}
              <div className="spRecruitContent flex column gap-50 w-full">
                <div className="spRecruitRoll flex column gap-30 w-full">
                  <div className="number flex align-end justify-end w-full">
                    <span>02</span>
                  </div>
                  <div className="roll flex column align-start justify-start gap-20">
                    <div className="en w-70">
                      <span>{iniCapitalize("design engineering")}</span>
                    </div>
                    <div className="ja w-70">
                      <span>ダミーエンジニア</span>
                    </div>
                  </div>
                </div>
                <div className="spRecruitText flex column gap-30 w-full">
                  <div className="ja">
                    <span>
                      私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in Ut enim Lorem ipsum dolor sit
                      amet, consectetur
                    </span>
                  </div>
                </div>
                <div className="allview flex align-end justify-end w-full">
                  <Link to="/recruit/design-engineer">
                    {capitalize("all view")}
                  </Link>
                </div>
              </div>
              {/* 求人内容 */}
              <div className="spRecruitContent lastContent flex column gap-50 w-full">
                <div className="spRecruitRoll flex column gap-30 w-full">
                  <div className="number flex align-end justify-end w-full">
                    <span>03</span>
                  </div>
                  <div className="roll flex column align-start justify-start gap-20">
                    <div className="en w-70">
                      <span>{iniCapitalize("design engineering")}</span>
                    </div>
                    <div className="ja w-70">
                      <span>ダミーエンジニア</span>
                    </div>
                  </div>
                </div>
                <div className="spRecruitText flex column gap-30 w-full">
                  <div className="ja">
                    <span>
                      私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in Ut enim Lorem ipsum dolor sit
                      amet, consectetur
                    </span>
                  </div>
                </div>
                <div className="allview flex align-end justify-end w-full">
                  <Link to="/recruit/design-engineer">
                    {capitalize("all view")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default RecruitPage;
