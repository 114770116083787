import { Link } from "react-router-dom";

import articleImage1 from "../compressed/articleImage1.webp";
import battleLogo from "../compressed/battleLogo.webp";

const EachRecruit = ({ useWindowWidth }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);

  if (windowWidth > 496) {
    return (
      <section id="EachRecruit">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex gap10">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                  <li>
                    <Link to="/recruit">{capitalize("recruit")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <div className="flex column gap-30">
                <div className="number">
                  <span>01</span>
                </div>
                <div className="roll flex column gap-20">
                  <div className="en">
                    <span>
                      Design
                      <br />
                      engineering
                    </span>
                  </div>
                  <div className="ja">
                    <span>ダミーエンジニア</span>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          <main className="main flex column align-start justify-start gap-100">
            {/* 業務内容 */}
            <div className="jobs flex column gap-50 relative">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>業務内容</span>
                </div>
                <div className="article">
                  <div>
                    入社後は、スキルや専門性に応じてさまざまなプロジェクトにアサインされ、下記のような業務をプロジェクト内で担っていただく予定です。
                    <ul>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                      <li>
                        消費トレンドや顧客インサイト把握のための定性リサーチの設計・実施
                      </li>
                      <li>ビジネスモデルの分析、事業コンセプトの策定</li>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                      <li>
                        複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                      </li>
                    </ul>
                    {/* <br/>コンセプト実証のための実験モデルの作成 Go-to-Market戦略の策定
                  <br/>コミュニケーション戦略の策定
                  <br/>クライアント企業とそれを取り巻く数十年後の環境変化の概観した上での中長期戦略の策定（ビジョンデザイン）
                  <br/>ビジネスデザイン分野の新たなコンサルティングアプローチやコンセプト検討のためのフレームワークの開発
                  <br/>ビジネスデザインについての啓発、メディア発信 */}
                  </div>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
                <div className="article">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in Ut enim Lorem
                    ipsum dolor sit amet, consectetur
                  </span>
                </div>
              </div>
            </div>
            {/* 求人内容 */}
            <div className="jobs flex column gap-50 relative">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>応募に必要なスキル</span>
                </div>
                <div className="article">
                  <div>
                    いずれかのスキルは必須となります。
                    <ul>
                      <li>
                        デザイン思考のプロセスを深く理解しており、それを活用したプロジェクトマネジネント経験
                      </li>
                      <li>
                        ファイナンス、経営学や社会学、経済学などに深い知見をお持ちで、それらの知識を新しいビジネスに生かした経験
                      </li>
                      <li>ビジネスレベルの日本語力</li>
                    </ul>
                    {/* <br/>コンセプト実証のための実験モデルの作成 Go-to-Market戦略の策定
                  <br/>コミュニケーション戦略の策定
                  <br/>クライアント企業とそれを取り巻く数十年後の環境変化の概観した上での中長期戦略の策定（ビジョンデザイン）
                  <br/>ビジネスデザイン分野の新たなコンサルティングアプローチやコンセプト検討のためのフレームワークの開発
                  <br/>ビジネスデザインについての啓発、メディア発信 */}
                  </div>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
                <div className="article">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in Ut enim Lorem
                    ipsum dolor sit amet, consectetur
                  </span>
                </div>
              </div>
            </div>
            <div className="image flex align-center justify-center w-full">
              <img loading="lazy" src={articleImage1} alt="" className="w-90" />
            </div>
            <div className="contact flex align-center justify-center w-full">
              <span>{capitalize("contact")}</span>
            </div>
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/recruit">
                <span>{capitalize("all careers")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachRecruit">
        <div className="spEachRecruitContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachRecruitContent flex column">
            <div className="upper flex column gap-20">
              <div className="number">
                <span>01</span>
              </div>
              <div className="roll flex column align-start justify-start gap-20">
                <div className="en w-70">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="ja w-70">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
            </div>
            {/* 求人内容 */}
            <div className="downer flex column gap-50">
              <div className="jaRecruiting flex column gap-20">
                <div className="title">
                  <span>業務内容</span>
                </div>
                <div className="flex column gap-20">
                  <div className="">
                    <span>
                      入社後は、スキルや専門性に応じてさまざまなプロジェクトにアサインされ、下記のような業務をプロジェクト内で担っていただく予定です。
                    </span>
                  </div>
                  <ul className="flex column align-start justify-start">
                    <li>
                      複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                    </li>
                    <li>
                      複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                    </li>
                    <li>
                      複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                    </li>
                    <li>
                      複雑な課題を抱えるクライアントに対するプロジェクト要件の（リ）フレーミング
                    </li>
                  </ul>
                </div>
              </div>
              <div className="enRecruiting flex column gap-30">
                <div className="title">
                  <span>orem ipsum</span>
                </div>
                <div className="flex column gap-20">
                  <div className="">
                    <span>
                      orem ipsum dolor sit amet, consectetur adipisicing,
                    </span>
                  </div>
                  <ul className="flex column align-start justify-start">
                    <li>
                      orem ipsum dolor sit amet, consectetur adipisicing
                    </li>
                    <li>
                      orem ipsum dolor sit amet, consectetur adipisicing
                    </li>
                    <li>
                      orem ipsum dolor sit amet, consectetur adipisicing
                    </li>
                    <li>
                      orem ipsum dolor sit amet, consectetur adipisicing
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="allView flex align-center justify-center">
              <Link to="/recruit">
                {capitalize("all careers")}
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default EachRecruit;