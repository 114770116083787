import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import RecruitDetail01 from "../compressed/RecruitDetail01.webp";

const RecruitDetail = ({ first, useWindowWidth }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    if (first) {
      return (
        <section
          id="RecruitDetail"
          className="flex align-center justify-center"
          ref={blur.ref}
        >
          <div className="RecruitDetailContainer flex align-center justify-center w-full h-full">
            <div className="Message flex column h-full w-full">
              <div className="image flex align-center justify-center w-full">
                <img loading="lazy" src={RecruitDetail01} alt="" />
              </div>
              <div className="en mix_difference flex align-center justify-center w-full">
                {blur.inView && (
                  <span className="blurNumber user-select-none">
                    {capitalize("Confronting")}
                    <br />
                    {capitalize("Society Together")}
                  </span>
                )}
              </div>
              <div className="ja flex align-center justify-center w-full user-select-none">
                {blur.inView && <span className="blurTitle">共に、高め合える仲間を。</span>}
              </div>
            </div>
            <div className="careersTitle flex column h-full">
              <div className="right flex column w-full h-full gap-20">
                <div className="flex align-center justify-start">
                  <span>{capitalize("careers")}</span>
                </div>
                {/*  */}
                <div className="flex align-center justify-start">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="RecruitDetailSecond" className="flex">
          <div className="RecruitDetailSecondContainer flex column align-center justify-between w-full h-full">
            <div className="upContent flex align-start justify-start w-full">
              <span>01</span>
            </div>
            <div className="downContent flex column align-start justify-center">
              <div className="roll flex column align-start justify-center gap-12">
                <div className="enRoll">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="jaRoll">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim
                </span>
              </div>
              <div className="viewMore flex justify-end align-end w-full">
                <Link to="/recruit/design-engineer">
                  {capitalize("view more")}
                </Link>
              </div>
            </div>
          </div>
          {/* 2つ目 */}
          <div className="RecruitDetailSecondContainer flex column align-center justify-between w-full h-full">
            <div className="upContent flex align-start justify-start w-full">
              <span>02</span>
            </div>
            <div className="downContent flex column align-start justify-center">
              <div className="roll flex column align-start justify-center gap-12">
                <div className="enRoll">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="jaRoll">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim
                </span>
              </div>
              <div className="viewMore flex justify-end align-end w-full">
                <a href="#">{capitalize("view more")}</a>
              </div>
            </div>
          </div>
          {/* 3つ目 */}
          <div className="RecruitDetailSecondContainer flex column align-center justify-between w-full h-full">
            <div className="upContent flex align-start justify-start w-full">
              <span>03</span>
            </div>
            <div className="downContent flex column align-start justify-center">
              <div className="roll flex column align-start justify-center gap-12">
                <div className="enRoll">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="jaRoll">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim
                </span>
              </div>
              <div className="viewMore flex justify-end align-end w-full">
                <a href="#">{capitalize("view more")}</a>
              </div>
            </div>
          </div>
          {/* 4つ目 */}
          <div className="RecruitDetailSecondContainer flex column align-center justify-between w-full h-full">
            <div className="upContent flex align-start justify-start w-full">
              <span>04</span>
            </div>
            <div className="downContent flex column align-start justify-center">
              <div className="roll flex column align-start justify-center gap-12">
                <div className="enRoll">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="jaRoll">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim
                </span>
              </div>
              <div className="viewMore flex justify-end align-end w-full">
                <a href="#">{capitalize("view more")}</a>
              </div>
            </div>
          </div>
          {/* 5つ目 */}
          <div className="RecruitDetailSecondContainer lastContainer flex column align-center justify-between w-full h-full">
            <div className="upContent flex align-start justify-start w-full">
              <span>05</span>
            </div>
            <div className="downContent flex column align-start justify-center">
              <div className="roll flex column align-start justify-center gap-12">
                <div className="enRoll">
                  <span>{iniCapitalize("design engineering")}</span>
                </div>
                <div className="jaRoll">
                  <span>ダミーエンジニア</span>
                </div>
              </div>
              <div className="jaText">
                <span>
                  私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私
                </span>
              </div>
              <div className="enText">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in Ut enim
                </span>
              </div>
              <div className="viewMore flex justify-end align-end w-full">
                <a href="#">{capitalize("view more")}</a>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
};

export default RecruitDetail;
