import React from "react";
import { Link } from "react-router-dom";

import battleLogo from "../compressed/battleLogo.webp";
import articleImage1 from "../compressed/articleImage1.webp";
import articleImage4 from "../compressed/articleImage4.webp";
import firmLogo from "../compressed/firmLogo.webp";
import abcanimation02 from "../compressed/abcanimation02.webp";

const Topic2 = ({ useWindowWidth, handlerToggle }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();

  if (windowWidth > 496) {
    return (
      <section id="EachNews" className="">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex gap10">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <img loading="lazy" src={articleImage4} alt="" className="w-full" />
            </div>
          </aside>
          <main className="main flex column align-start justify-start gap-100">
            <div className="title flex column gap-20">
              <div className="bigTitle ja">
                <span>
                  アニメーション事業を手掛ける「ABCアニメーション」とweb3法人「THE
                  BATTLE」が、NFTからIP展開を行う新法人「WAGMI
                  Records」設立と第一弾プロジェクトの採用を発表
                </span>
              </div>
              <div className="en">
                <span>
                  The animation business, "ABC Animation," and the web3
                  corporation, "THE BATTLE," have announced the establishment of
                  a new corporation, "WAGMI Records," aimed at IP development
                  from NFTs, as well as the adoption of their first project.
                </span>
              </div>
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    第一弾プロジェクトはアイドルNFTプロジェクト『Selfies』。今回の「WAGMI
                    Records（ワグミレコード）」との統合で新たなIP創出・成長モデルの形を目指す。
                  </span>
                </div>
                <div className="article">
                  <span>
                    ブロックチェーンゲームやNFT等のIPを活用したアニメ制作などを手がける株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木
                    清龍）は朝日放送グループホールディングスの子会社であり、TV・劇場アニメーションの企画・製作、コンテンツ事業展開を行う株式会社ABCアニメーション
                    （本社：東京都新宿区、代表取締役：西出
                    将之）と、NFTを主軸にIP展開を目指す新法人であるWAGMI Records
                    LLP（本社：東京都新宿区、組合員：株式会社ABCアニメーション・株式会社THE
                    BATTLE）を設立し、第一弾コンテンツとして株式会社THE
                    BATTLEが昨年2月から行ってきたNFTプロジェクト『Selfies』を共同で行っていくことをお知らせいたします。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    The first project is the idol NFT project "Selfies." With
                    the integration with "WAGMI Records," they aim to form a new
                    model of IP creation and growth.
                  </span>
                </div>
                <div className="article">
                  <span>
                    Blockchain game and NFT IP animation production company, The
                    Battle, INC. (Headquarters: Nakano-ku, Tokyo, Representative
                    Director: Kiyoryu Sasaki), in collaboration with ABC
                    Animation, INC. (Headquarters: Shinjuku-ku, Tokyo,
                    Representative Director: Masayuki Nishide), a subsidiary of
                    Asahi Broadcasting Group Holdings that plans and produces TV
                    and theater animations and develops content businesses, have
                    established a new corporation, WAGMI Records LLP
                    (Headquarters: Shinjuku-ku, Tokyo, Members: ABC Animation,
                    THE BATTLE), aimed at IP development with NFT as the main
                    axis. We are pleased to announce that we will jointly carry
                    out the NFT project "Selfies", which THE BATTLE has been
                    conducting since February last year, as the first project.
                  </span>
                </div>
              </div>
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>新法人「WAGMI Records」設立の目的</span>
                </div>
                <div className="article">
                  <span>
                    株式会社ABCアニメーションと株式会社THE
                    BATTLEは昨年9月に業務提携を行い、株式会社ABCアニメーションが持つアニメ制作ナレッジと、株式会社THE
                    BATTLEの豊富なweb3ナレッジを活かした取り組みを検討してまいりました。
                  </span>
                </div>
                <div className="article">
                  <span>
                    WAGMI
                    Recordsは従来のような、一方的なIPコンテンツ発信していくモデルとは異なり、web3ならではのコミュニティの意見が反映されやすいDAOモデルで、IPの共創を行っていくための新法人です。
                  </span>
                </div>
                <div className="article">
                  <span>
                    第一弾IPコンテンツとして、株式会社THE
                    BATTLEが昨年2月から運営を行ってきたNFTプロジェクトの『Selfies』をWAGMI
                    Recordsで共同運営していくことを発表し、株式会社ABCアニメーションが培ってきたアニメーション制作のノウハウや、朝日放送グループの持つ、マスメディア機能の連携等でIPを育てていく、未来志向の創造を推し進めてまいります。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Purpose of Establishing the New Corporation "WAGMI Records"
                  </span>
                </div>
                <div className="article">
                  <span>
                    ABC Animation and THE BATTLE formed a business alliance in
                    September last year and have been considering initiatives
                    that utilize the animation production knowledge of ABC
                    Animation and the abundant web3 knowledge of THE BATTLE.
                    WAGMI Records is a new corporation for co-creating IPs in a
                    DAO model, which is easy to reflect the opinions of the
                    community unique to web3, unlike the traditional model of
                    unilaterally disseminating IP content.
                  </span>
                </div>
                <div className="article">
                  <span>
                    As the first IP content, we announce that we will jointly
                    operate the NFT project "Selfies," on which THE BATTLE has
                    been working since February of last year, at WAGMI Records.
                    We also announce that we will support future-oriented
                    creation by nurturing IPs through the collaboration of mass
                    media functions held by the Asahi Broadcasting Group and the
                    animation production know-how cultivated by ABC Animation.
                  </span>
                </div>
              </div>
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>新法人「WAGMI Records」設立の概要</span>
                </div>
                <div className="article">
                  <span>
                    WAGMI Recordsは各社と以下のような展開を検討してまいります。
                  </span>
                </div>
                <div className="article flex column">
                  <span>
                    ・マルチメディア展開に伴う、新規IPの創出およびコンテンツの新規制作
                  </span>
                  <span>
                    ・NFT活用によるIP創出と次世代アーティストの育成、及び発掘を見据えたマルチプラットフォーム企画の推進
                  </span>
                </div>
                <div className="article">
                  <span>
                    当提携は、朝日放送グループの持つ、テレビ・ラジオなどの様々なメディア機能の活用と、TVアニメ『プリキュアシリーズ』などを手がけるABCアニメーションによるIPクリエイティブ、さらに
                    THE BATTLE
                    がこれまで培ってきたweb3ナレッジを集結させ、新たな自社IPの制作スキームを構築するとともに、国内外を含む新規顧客層の獲得を目指すことを目的としています。
                  </span>
                </div>
                <div className="article">
                  <span>
                    今後、朝日放送グループのweb3領域開拓を、THE BATTLE
                    とともに推進してまいります。
                  </span>
                </div>
                <div className="article flex column">
                  <span>
                    詳細はTwitterやプレスリリースにて随時発表いたします。
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/WAGMI_Records"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/WAGMI_Records
                    </a>
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Overview of the Establishment of the New Corporation "WAGMI
                    Records"
                  </span>
                </div>
                <div className="article">
                  <span>
                    WAGMI Records will consider the following developments with
                    each company:
                  </span>
                </div>
                <div className="article flex column">
                  <span>
                    ・Creation of new IPs and new content production associated
                    with multimedia development
                  </span>
                  <span>
                    ・Promotion of multi-platform planning aimed at nurturing
                    and discovering next-generation artists, with a view to
                    creating IPs using NFT
                  </span>
                </div>
                <div className="article">
                  <span>
                    The purpose of this alliance is to build a new production
                    scheme for our own IP by integrating the various media
                    functions of the Asahi Broadcasting Group, the IP creative
                    by ABC Animation, which has been involved in the TV anime
                    "Pretty Cure series", and the web3 knowledge that THE BATTLE
                    has cultivated so far, and to aim to acquire new customer
                    segments, including domestic and overseas.
                  </span>
                </div>
                <div className="article">
                  <span>
                    In the future, we will promote the development of the web3
                    area of the Asahi Broadcasting Group together with THE
                    BATTLE.
                  </span>
                </div>
                <div className="article flex column">
                  <span>
                    Details will be announced from time to time on Twitter and
                    press releases.
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/WAGMI_Records"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/WAGMI_Records
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="topic flex column gap-50 w-full">
              <div className="ja flex column gap-30">
                <div className="subtitle">
                  <span>株式会社THE BATTLE について</span>
                </div>
                <div className="article">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。主なプロエジェクトとしてはweb3型アニメ“制作”委員会方式「CryptoAnime
                    Labs」にて人気NFT作品『CryptoNinja
                    NFT』のアニメ化プロジェクトで約8千万円の資金調達をNFT販売で行ったほか、同じくNFT販売で約9千万円を売り上げたフルオンチェーンブロックチェーンゲーム『Isekai
                    Battle』の運営開発など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={firmLogo} alt="" className="w-80" />
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>会社名</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;株式会社 THE BATTLE</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>代表取締役</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;佐々木清龍</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>所在地</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;東京都中野区弥生町 2-41-17
                            東京コンテンツインキュベーションセンター
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>設立</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;2021年7月</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>事業内容</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;ブロックチェーンゲーム事業、NFT×アニメ制作事業、他
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>About THE BATTLE Corporation</span>
                </div>
                <div className="article">
                  <span>
                    Notable projects include the "CryptoAnime Labs," a
                    web3-style committee for the "production" of animation,
                    which earned over 80 million yen through the sale of NFTs
                    for the anime adaption project of the well-known NFT work
                    "CryptoNinja NFT." The operation and development of the
                    full-on-chain blockchain game "Isekai Battle" were also
                    successfully funded through the sale of NFTs for about 90
                    million yen. With blockchain technology and entertainment as
                    their primary axes, they have been working on a number of
                    initiatives.
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Company name</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;THE BATTLE Co, INC. </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>CEO</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Seiryu Sasaki</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Location</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;2-41-17 Yayoi-cho, Nakano-ku, Tokyo,
                            Tokyo Content Incubation Center Established: July
                            2021
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Established</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;July 2021</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Business content</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;Blockchain game business, NFT x
                            animation production business, etc.
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>株式会社ABCアニメーション について</span>
                </div>
                <div className="article">
                  <span>
                    株式会社ABCアニメーション（エービーシーアニメーション、ABC
                    ANIMATION,
                    INC.）は、朝日放送グループホールディングスの100％子会社です。TV・劇場アニメーションの企画・製作、コンテンツ事業展開を手がける企業で、グループのアニメーション事業を担っています。
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={abcanimation02} alt="" className="w-60" />
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>会社名</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;株式会社ABCアニメーション</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>代表取締役</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;西出 将之</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>所在地</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;東京都新宿区新宿2-1-12 PMO新宿御苑前4階
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>設立</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;2016年4月</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>事業内容</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;TV・劇場アニメーションの企画・製作、およびそれに派生するビデオグラム販売、海外販売、物販、イベントなどのコンテンツ事業展開、他
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>ホームページ</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://www.abc-anime.co.jp/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.abc-anime.co.jp/
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>About ABC Animation Corporation</span>
                </div>
                <div className="article">
                  <span>
                    ABC Animation (ABC Animation, ABC ANIMATION, INC.) is a 100%
                    subsidiary of Asahi Broadcasting Group Holdings. It is a
                    company that handles the planning and production of TV and
                    theater animations and the development of content
                    businesses, and is responsible for the animation business of
                    the group.
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Company name</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;ABC Animation, INC.</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>CEO</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Masayuki Nishide</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Location</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;4th floor, PMO Shinjuku Gyoenmae,
                            2-1-12 Shinjuku, Shinjuku-ku, Tokyo
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Established</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;April 2016</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Business content</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;Planning and production of TV and
                            theater animations, and development of content
                            businesses such as videogram sales, overseas sales,
                            merchandise sales, events derived from them, etc.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>Homepage</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;</span>
                          <span>
                            <a
                              href="https://www.abc-anime.co.jp/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.abc-anime.co.jp/
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/news">
                <span>{capitalize("all news")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachNews">
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachNewsContainer flex column gap-50">
            <div className="titleContent flex column gap-30">
              {/* <div>
                <img loading="lazy" src={articleImage1} alt="" className="w-full" />
              </div> */}
              <div className="jaTitle">
                <span>
                  朝日放送グループHDでアニメ制作事業を手掛ける「ABCアニメーション」がweb3領域進出に伴い、コンテンツ事業に特化したweb3法人「THE
                  BATTLE」との業務提携を発表
                </span>
              </div>
              <div className="enTitle">
                <span>
                  The animation business, "ABC Animation," and the web3
                  corporation, "THE BATTLE," have announced the establishment of
                  a new corporation, "WAGMI Records," aimed at IP development
                  from NFTs, as well as the adoption of their first project.
                </span>
              </div>
            </div>
            {/* 第一弾プロジェクトは */}
            <article className="content flex column gap-50">
              <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage4} alt="" className="w-80" />
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    第一弾プロジェクトはアイドルNFTプロジェクト『Selfies』。今回の「WAGMI
                    Records（ワグミレコード）」との統合で新たなIP創出・成長モデルの形を目指す。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ブロックチェーンゲームやNFT等のIPを活用したアニメ制作などを手がける株式会社THE
                    BATTLE（本社：東京都中野区、代表取締役：佐々木
                    清龍）は朝日放送グループホールディングスの子会社であり、TV・劇場アニメーションの企画・製作、コンテンツ事業展開を行う株式会社ABCアニメーション
                    （本社：東京都新宿区、代表取締役：西出
                    将之）と、NFTを主軸にIP展開を目指す新法人であるWAGMI Records
                    LLP（本社：東京都新宿区、組合員：株式会社ABCアニメーション・株式会社THE
                    BATTLE）を設立し、第一弾コンテンツとして株式会社THE
                    BATTLEが昨年2月から行ってきたNFTプロジェクト『Selfies』を共同で行っていくことをお知らせいたします。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle w-70">
                  <span>
                    The first project is the idol NFT project "Selfies." With
                    the integration with "WAGMI Records," they aim to form a new
                    model of IP creation and growth.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Blockchain game and NFT IP animation production company, The
                    Battle, INC. (Headquarters: Nakano-ku, Tokyo, Representative
                    Director: Kiyoryu Sasaki), in collaboration with ABC
                    Animation, INC. (Headquarters: Shinjuku-ku, Tokyo,
                    Representative Director: Masayuki Nishide), a subsidiary of
                    Asahi Broadcasting Group Holdings that plans and produces TV
                    and theater animations and develops content businesses, have
                    established a new corporation, WAGMI Records LLP
                    (Headquarters: Shinjuku-ku, Tokyo, Members: ABC Animation,
                    THE BATTLE), aimed at IP development with NFT as the main
                    axis. We are pleased to announce that we will jointly carry
                    out the NFT project "Selfies", which THE BATTLE has been
                    conducting since February last year, as the first project.
                  </span>
                </div>
              </div>
            </article>
            {/* 新法人「WAGMI Records」設立の目的 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>新法人「WAGMI Records」設立の目的</span>
                </div>
                <div className="jaContent">
                  <span>
                    株式会社ABCアニメーションと株式会社THE
                    BATTLEは昨年9月に業務提携を行い、株式会社ABCアニメーションが持つアニメ制作ナレッジと、株式会社THE
                    BATTLEの豊富なweb3ナレッジを活かした取り組みを検討してまいりました。
                    WAGMI
                    Recordsは従来のような、一方的なIPコンテンツ発信していくモデルとは異なり、web3ならではのコミュニティの意見が反映されやすいDAOモデルで、IPの共創を行っていくための新法人です。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    第一弾IPコンテンツとして、株式会社THE
                    BATTLEが昨年2月から運営を行ってきたNFTプロジェクトの『Selfies』をWAGMI
                    Recordsで共同運営していくことを発表し、株式会社ABCアニメーションが培ってきたアニメーション制作のノウハウや、朝日放送グループの持つ、マスメディア機能の連携等でIPを育てていく、未来志向の創造を推し進めてまいります。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>
                    Purpose of Establishing the New Corporation "WAGMI Records"
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    ABC Animation and THE BATTLE formed a business alliance in
                    September last year and have been considering initiatives
                    that utilize the animation production knowledge of ABC
                    Animation and the abundant web3 knowledge of THE BATTLE.
                    WAGMI Records is a new corporation for co-creating IPs in a
                    DAO model, which is easy to reflect the opinions of the
                    community unique to web3, unlike the traditional model of
                    unilaterally disseminating IP content.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    As the first IP content, we announce that we will jointly
                    operate the NFT project "Selfies," on which THE BATTLE has
                    been working since February of last year, at WAGMI Records.
                    We also announce that we will support future-oriented
                    creation by nurturing IPs through the collaboration of mass
                    media functions held by the Asahi Broadcasting Group and the
                    animation production know-how cultivated by ABC Animation.
                  </span>
                </div>
              </div>
            </article>
            {/* 新法人「WAGMI Records」設立の概要 */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>新法人「WAGMI Records」設立の概要</span>
                </div>
                <div className="jaContent">
                  <span>
                    WAGMI Recordsは各社と以下のような展開を検討してまいります。
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    ・マルチメディア展開に伴う、新規IPの創出およびコンテンツの新規制作
                  </span>
                  <span>
                    ・NFT活用によるIP創出と次世代アーティストの育成、及び発掘を見据えたマルチプラットフォーム企画の推進
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    当提携は、朝日放送グループの持つ、テレビ・ラジオなどの様々なメディア機能の活用と、TVアニメ『プリキュアシリーズ』などを手がけるABCアニメーションによるIPクリエイティブ、さらに
                    THE BATTLE
                    がこれまで培ってきたweb3ナレッジを集結させ、新たな自社IPの制作スキームを構築するとともに、国内外を含む新規顧客層の獲得を目指すことを目的としています。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    今後、朝日放送グループのweb3領域開拓を、THE BATTLE
                    とともに推進してまいります。
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    詳細はTwitterやプレスリリースにて随時発表いたします。
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/WAGMI_Records"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/WAGMI_Records
                    </a>
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>
                    Overview of the Establishment of the New Corporation "WAGMI
                    Records"
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    WAGMI Records will consider the following developments with
                    each company:
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    ・Creation of new IPs and new content production associated
                    with multimedia development
                  </span>
                  <span>
                    ・Promotion of multi-platform planning aimed at nurturing
                    and discovering next-generation artists, with a view to
                    creating IPs using NFT
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    The purpose of this alliance is to build a new production
                    scheme for our own IP by integrating the various media
                    functions of the Asahi Broadcasting Group, the IP creative
                    by ABC Animation, which has been involved in the TV anime
                    "Pretty Cure series", and the web3 knowledge that THE BATTLE
                    has cultivated so far, and to aim to acquire new customer
                    segments, including domestic and overseas.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    In the future, we will promote the development of the web3
                    area of the Asahi Broadcasting Group together with THE
                    BATTLE.
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    Details will be announced from time to time on Twitter and
                    press releases.
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/WAGMI_Records"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/WAGMI_Records
                    </a>
                  </span>
                </div>
              </div>
            </article>
            {/* 株式会社THE BATTLE について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>株式会社THE BATTLE について</span>
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。主なプロエジェクトとしてはweb3型アニメ“制作”委員会方式「CryptoAnime
                    Labs」にて人気NFT作品『CryptoNinja
                    NFT』のアニメ化プロジェクトで約8千万円の資金調達をNFT販売で行ったほか、同じくNFT販売で約9千万円を売り上げたフルオンチェーンブロックチェーンゲーム『Isekai
                    Battle』の運営開発など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={firmLogo} alt="" className="w-80" />
                </div>
                <div className="jaContent flex column">
                  <span>会社名：株式会社 THE BATTLE</span>
                  <span>代表取締役：佐々木清龍</span>
                  <span>
                    所在地：東京都中野区弥生町 2-41-17
                    東京コンテンツインキュベーションセンター
                  </span>
                  <span>設立：2021年7月</span>
                  <span>
                    事業内容：ブロックチェーンゲーム事業、NFT×アニメ制作事業、他
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>About THE BATTLE Corporation</span>
                </div>
                <div className="enContent">
                  <span>
                    Notable projects include the "CryptoAnime Labs," a
                    web3-style committee for the "production" of animation,
                    which earned over 80 million yen through the sale of NFTs
                    for the anime adaption project of the well-known NFT work
                    "CryptoNinja NFT." The operation and development of the
                    full-on-chain blockchain game "Isekai Battle" were also
                    successfully funded through the sale of NFTs for about 90
                    million yen. With blockchain technology and entertainment as
                    their primary axes, they have been working on a number of
                    initiatives.
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>Company name: THE BATTLE Co, INC.</span>
                  <span>CEO: Kiyoryu Sasaki</span>
                  <span>
                    Location: 2-41-17 Yayoi-cho, Nakano-ku, Tokyo, Tokyo Content
                    Incubation Center
                  </span>
                  <span>Established: July 2021</span>
                  <span>
                    Business content: Blockchain game business, NFT x animation
                    production business, etc.
                  </span>
                </div>
              </div>
            </article>
            {/* 株式会社ABCアニメーション について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>株式会社ABCアニメーション について</span>
                </div>
                <div className="jaContent">
                  <span>
                    株式会社ABCアニメーション（エービーシーアニメーション、ABC
                    ANIMATION,
                    INC.）は、朝日放送グループホールディングスの100％子会社です。TV・劇場アニメーションの企画・製作、コンテンツ事業展開を手がける企業で、グループのアニメーション事業を担っています。
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={abcanimation02} alt="" className="w-70" />
                </div>
                <div className="jaContent flex column">
                  <span>会社名：株式会社ABCアニメーション</span>
                  <span>代表取締役：西出 将之</span>
                  <span>所在地：東京都新宿区新宿2-1-12 PMO新宿御苑前4階</span>
                  <span>設立：2016年4月</span>
                  <span>
                    事業内容：TV・劇場アニメーションの企画・製作、およびそれに派生するビデオグラム販売、海外販売、物販、イベントなどのコンテンツ事業展開、他
                  </span>
                  <span>
                    ホームページ：
                    <a
                      href="https://www.abc-anime.co.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.abc-anime.co.jp/
                    </a>
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>About ABC Animation Corporation</span>
                </div>
                <div className="enContent">
                  <span>
                    ABC Animation (ABC Animation, ABC ANIMATION, INC.) is a 100%
                    subsidiary of Asahi Broadcasting Group Holdings. It is a
                    company that handles the planning and production of TV and
                    theater animations and the development of content
                    businesses, and is responsible for the animation business of
                    the group.
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>Company name: ABC Animation, INC.</span>
                  <span>CEO: Masayuki Nishide</span>
                  <span>
                    Location: 4th floor, PMO Shinjuku Gyoenmae, 2-1-12 Shinjuku,
                    Shinjuku-ku, Tokyo
                  </span>
                  <span>Established: April 2016</span>
                  <span>
                    Business content: Planning and production of TV and theater
                    animations, and development of content businesses such as
                    videogram sales, overseas sales, merchandise sales, events
                    derived from them, etc.
                  </span>
                  <span>
                    Homepage：
                    <a
                      href="https://www.abc-anime.co.jp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.abc-anime.co.jp/
                    </a>
                  </span>
                </div>
              </div>
            </article>
            <div className="allView flex align-center justify-center w-full">
              <span>
                <Link to="/news">{capitalize("all news")}</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Topic2;
