import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SliderButton } from "@typeform/embed-react";

import battleLogoWhite from "../compressed/battleLogoWhite.webp";
import CompanyPage03 from "../compressed/CompanyPage03.webp";
import SPCompany01 from "../compressed/SPCompany01.webp";
// founder画像
import SeiryuSasaki from "../compressed/SeiryuSasaki.webp";
import syou from "../compressed/syou.png";
// member画像
import haruyu from "../compressed/memberharuyu.webp";
import minui from "../compressed/memberminui.webp";
import hagi from "../compressed/memberhagi.webp";
import kudo from "../compressed/memberkudo.webp";
import shiho from "../compressed/membershiho.webp";
import kazu from "../compressed/memberkazu.webp";
import ray from "../compressed/memberray.webp";
// partner画像
import microsoft from "../compressed/microsoft.webp";
import abcanimation02 from "../compressed/abcanimation02.webp";
import hikkiy from "../compressed/hikkiy.webp";
import fanworks from "../compressed/fanworks.webp";

const CompanyPage = ({
  useWindowWidth,
  handlerToggle,
  contactDetailBlurInView,
}) => {
  const capitalize = (text) => text.toUpperCase();
  const windowWidth = useWindowWidth();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section id="CompanyPage" className="flex" ref={blur.ref}>
        {contactDetailBlurInView ? (
          <></>
        ) : (
          <header className="flex column align-center justify-between">
            <div className="headerImage flex align-center justify-center">
              <Link to="/">
                <img loading="lazy" src={battleLogoWhite} alt="" />
              </Link>
            </div>
            <ul className="flex align-center justify-center vertical_rl">
              <li>
                <Link to="/">{capitalize("home")}</Link>
              </li>
              <li>
                <Link to="/news">{capitalize("news")}</Link>
              </li>
              <li>
                <Link to="/projects">{capitalize("projects")}</Link>
              </li>
              <li>
                <Link to="/company" className="strike-out-line-white">
                  {capitalize("company")}
                </Link>
              </li>
              {/* <li>
                <Link to="/recruit">{capitalize("recruit")}</Link>
              </li> */}
            </ul>
          </header>
        )}
        <div className="Container page_contanier flex">
          <div className="upper flex column justify-between w-full h-full">
            {blur.inView && (
              <div className="BigNumber blurNumber w-full user-select-none">
                <h1 className="w-full">03</h1>
              </div>
            )}
            <div className="downer flex">
              <div
                className={`subTitle flex align-end ${
                  blur.inView ? "blurTitle" : "invisible"
                }`}
              >
                <span className="user-select-none">
                  {capitalize("company")}
                </span>
              </div>
              {blur.inView && (
                <div className="text flex column align-end justify-end gap-20 blurText">
                  <div className="jaText">
                    <span>
                      THE BATTLE
                      は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、エンタメ×web3領域のプロフェッショナルとして様々なクリエイティブディレクションを手がけております。
                    </span>
                  </div>
                  <div className="enText">
                    <span>
                      THE BATTLE was founded as a "web3 organization that
                      challenges conventional norms." Its core members have been
                      active in the blockchain space since 2018 and have been
                      involved in various creative directions in the
                      intersection of entertainment and web3 as professionals.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="NewsPageLeft flex column">
            <img loading="lazy" src={CompanyPage03} alt="" className="w-full" />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spCompanyPage" ref={blur.ref}>
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img
                  loading="lazy"
                  src={battleLogoWhite}
                  alt=""
                  className="w-80"
                />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle} className="white">
                {capitalize("menu")}
              </button>
            </div>
          </header>
          <div className="contactText fixed mix_difference">
            <SliderButton id="wJm1bGdB" className="SPtypeformBtn">
              {capitalize("contact")}
            </SliderButton>
          </div>
          <div className="spDetailDowner flex column relative">
            {blur.inView && (
              <div className="titlenumber absolute blurNumber">
                <span>03</span>
              </div>
            )}
            <div className="titleimage relative">
              <div
                className={`absolute black mix_difference ${
                  blur.inView ? "blurTitle" : ""
                }`}
              >
                <span>{capitalize("company")}</span>
              </div>
              <div className="">
                <img
                  loading="lazy"
                  src={SPCompany01}
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            {blur.inView && (
              <div className="titletext flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、エンタメ×web3領域のプロフェッショナルとして様々なクリエイティブディレクションを手がけております。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    THE BATTLE was founded as a "web3 organization that
                    challenges conventional norms." Its core members have been
                    active in the blockchain space since 2018 and have been
                    involved in various creative directions in the intersection
                    of entertainment and web3 as professionals.
                  </span>
                </div>
              </div>
            )}
            <div className="content message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("concept")}</span>
                </div>
                <div className="enText w-full italic">
                  <span>
                    Questioning the "existing ways."
                    <br />
                    Loving the creator culture.
                  </span>
                </div>
              </div>
              <div className="companyMessage flex column">
                <div className="flex align-start justify-start w-full">
                  <span>{capitalize("battle")}</span>
                </div>
                <div className="flex align-start justify-start w-full">
                  <span>{capitalize("against")}</span>
                </div>
                <div className="flex align-start justify-start w-full">
                  <span>{capitalize("society")}</span>
                </div>
              </div>
            </div>
            <div className="content message flex column gap-50">
              <div className="jaMessage flex">
                <span>
                  既存の仕組みに向き合い、
                  <br />
                  破壊的イノベーションを。
                </span>
              </div>
              <div className="jaText flex">
                <span>
                  世界には受け継がれるべき文化や仕組みとともに、一度解体して再構築するべきものが溢れています。わたしたちは現存するコンテキストを読み解き、web3の理念に根ざした『破壊的イノベーション』を提起し、戦いつづけます。
                </span>
              </div>
              <div className="enText flex">
                <span>
                  In the world, there are traditions and systems that should be
                  passed down, but there are also things that need to be
                  dismantled and rebuilt. We decipher the existing context and
                  strive for "disruptive innovation" rooted in the principles of
                  web3, continuing our battle.
                </span>
              </div>
            </div>
            {/* ファウンダー紹介エリア */}
            <div className="content lastspFounder message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("founder")}</span>
                </div>
                <div className="enText w-full italic">
                  <span>
                    Continuing to challenge,
                    <br />
                    paving the way for the future.
                  </span>
                </div>
              </div>
              <div className="fouderProfile flex column gap-30">
                <div className="image flex align-start justify-start">
                  <img
                    loading="lazy"
                    src={SeiryuSasaki}
                    alt=""
                    className="w-60"
                  />
                </div>
                <div className="director">
                  <span>{capitalize("ceo")}</span>
                </div>
                <div className="jaProfile flex column gap-20">
                  <div className="name flex">
                    <span>佐々木&nbsp;清龍</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/0xBTL"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="discription">
                    <span>
                      コンテンツ業界に2010年から従事しており、2014年からデジタル領域に参画。2018年に独⽴、ブロックチェーン事業にも携わる。2021年7⽉に「従来の常識と戦う、web3組織」として”THE
                      BATTLE”を設⽴。
                    </span>
                  </div>
                </div>
                <div className="enProfile flex column gap-20">
                  <div className="name">
                    <span>Seiryu&nbsp;Sasaki</span>
                  </div>
                  <div className="discription">
                    <span>
                      I have been working in the content industry since 2010 and
                      have been involved in the digital industry since 2014. I
                      went independent in 2018 and got involved in the
                      blockchain projects. In July 2021, I established “THE
                      BATTLE” as a “Web3 organization that fights against
                      conventional wisdom.”
                    </span>
                  </div>
                </div>
              </div>
              <div className="fouderProfile lastSP flex column gap-30">
                <div className="image flex align-start justify-start">
                  <img loading="lazy" src={syou} alt="" className="w-60" />
                </div>
                <div className="director">
                  <span>{capitalize("cto")}</span>
                </div>
                <div className="jaProfile flex column gap-20">
                  <div className="name flex">
                    <span>syou</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/nft_syou"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="discription">
                    <span>
                      ブロックチェーン技術に精通し、2022年より『Pixel Heroes
                      DAO』などを含む20以上のweb3プロジェクトに携わる。国内最大級のジェネラティブNFTプロジェクト『CryptoNinja
                      Partners(CNP)』ではリードエンジニアとしても活躍し、その技術的な洞察力と実行力で業界内で高い評価を受けている。2022年1月にTHE
                      BATTLEに参画。培った知識と技術力を最大限に活用し、ブロックチェーンの可能性を具現化しつづけ、同社の革新的な動きに貢献。後進の育成にも務める。
                    </span>
                  </div>
                </div>
                <div className="enProfile flex column gap-20">
                  <div className="discription">
                    <span>
                      With deep blockchain knowledge, I've worked on 20+ web3
                      projects, including "Pixel Heroes DAO" since 2022. As lead
                      engineer, I've excelled at Japan's largest generative NFT
                      project, "CryptoNinja Partners (CNP)," earning industry
                      acclaim for my technical insights and execution. I joined
                      THE BATTLE in Jan 2022 to maximize blockchain potential,
                      drive innovation, and mentor emerging talent.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* メンバー紹介エリア */}
            <div className="content message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("member")}</span>
                </div>
                <div className="enText w-full italic">
                  <span>
                    Gathering of creative professionals from various fields.
                  </span>
                </div>
              </div>
              <div className="spMemberContent flex column gap-50">
                <div className="memberProfile flex">
                  {/* はるユさん */}
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={haruyu}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Illustrator")}</span>
                    </div>
                    <div className="name flex">
                      <span>はるユ</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/ha_ru_ha_ru1192"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="memberProfile flex">
                  {/* minuiさん */}
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={minui}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Illustrator")}</span>
                    </div>
                    <div className="name flex">
                      <span>minui</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/MinuiNft"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* hagiさん */}
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={hagi}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Illustrator")}</span>
                    </div>
                    <div className="name flex">
                      <span>hagi</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/hagi_artnft"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="memberProfile flex">
                  {/* kudoさん */}
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={kudo}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Designer")}</span>
                    </div>
                    <div className="name flex">
                      <span>kudoshun</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/kdooshun"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                  {/* SHIHOさん */}
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={shiho}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Moderator")}</span>
                    </div>
                    <div className="name flex">
                      <span>SHIHO</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/shiho_shigun"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="memberProfile flex">
                  <div className="flex column gap-12">
                    <div className="image">
                      <img
                        loading="lazy"
                        src={kazu}
                        alt=""
                        className="w-full"
                      />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Moderator")}</span>
                    </div>
                    <div className="name flex">
                      <span>Kazu</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/Kazuzxc"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="flex column gap-12">
                    <div className="image">
                      <img loading="lazy" src={ray} alt="" className="w-full" />
                    </div>
                    <div className="director">
                      <span>{iniCapitalize("Engineer")}</span>
                    </div>
                    <div className="name flex">
                      <span>ray</span>
                      <span className="flex align-center justify-center">
                        <span className="twitter">
                          <a
                            href="https://twitter.com/rayrayra1n"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="13"
                              viewBox="0 0 27 22"
                            >
                              <path
                                className="snsIcon"
                                id="white_background"
                                d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                                transform="translate(-1 -1.497)"
                              />
                            </svg>
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* パートナー紹介エリア */}
            <div className="content message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("partner")}</span>
                </div>
                <div className="enText w-full italic">
                  <span>
                    For the future of web3,
                    <br />
                    Thinking together, building continuously
                  </span>
                </div>
              </div>
              <div className="spMemberContent flex column gap-50">
                <div className="spPartner flex column gap-12">
                  <div>
                    <img
                      loading="lazy"
                      src={microsoft}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="name">
                    <span>Microsoft</span>
                  </div>
                </div>
                <div className="spPartner flex column gap-12">
                  <div>
                    <img
                      loading="lazy"
                      src={abcanimation02}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="name">
                    <span>ABC&nbsp;animation</span>
                  </div>
                </div>
                <div className="spPartner flex column gap-12">
                  <div>
                    <img
                      loading="lazy"
                      src={hikkiy}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="name">
                    <span>HIKKY</span>
                  </div>
                </div>
                <div className="spPartner flex column gap-12">
                  <div>
                    <img
                      loading="lazy"
                      src={fanworks}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="name">
                    <span>FAN&nbsp;WORKS</span>
                  </div>
                </div>
                <div className="andMore flex align-end justify-end w-full">
                  <span>and&nbsp;more...</span>
                </div>
              </div>
            </div>
            {/* 会社紹介エリア */}
            <div className="content message flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column gap-20">
                <div className="spCompanyTitle">
                  <span>{capitalize("about")}</span>
                </div>
                {/* <div className="enText w-full italic">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                  </span>
                </div> */}
              </div>
              <div className="spMemberContent flex column gap-50">
                <div className="flex column gap-12">
                  <div className="spAboutTitle">
                    <span>{capitalize("firm name")}</span>
                  </div>
                  <div className="spAboutName">
                    <span>{capitalize("the battle")}</span>
                  </div>
                </div>
                <div className="flex column gap-12">
                  <div className="spAboutTitle">
                    <span>{capitalize("DATE OF ESTABLISHMENT")}</span>
                  </div>
                  <div className="spAboutName">
                    <span>2021/07/01</span>
                  </div>
                </div>
                <div className="flex column gap-12">
                  <div className="spAboutTitle">
                    <span>CEO/Founder</span>
                  </div>
                  <div className="spAboutName">
                    <span>Seiryu Sasaki</span>
                  </div>
                </div>
                <div className="flex column gap-12">
                  <div className="spAboutTitle">
                    <span>{capitalize("BUSINESS OVERVIEW")}</span>
                  </div>
                  <div className="spAboutName">
                    <span>Digital Creative</span>
                  </div>
                </div>
                <div className="flex column gap-12">
                  <div className="spAboutTitle">
                    <span>{capitalize("ADDRESS")}</span>
                  </div>
                  <div className="spAboutName">
                    <span>
                      TCIC-No23 2-41-17 Yayoicho, <br />
                      Nakano-ku, Tokyo 164-0013 JAPAN
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default CompanyPage;
