import { Link } from "react-router-dom";

import articleImage1 from "../compressed/articleImage1.webp";
import battleLogo from "../compressed/battleLogo.webp";
import ProjectDetail01 from "../compressed/ProjectDetail01.webp";
import ProjectDetail02 from "../compressed/ProjectDetail02.webp";

const EachProject = ({ useWindowWidth }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();

  if (windowWidth > 496) {
    return (
      <section id="EachProject" className="">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                  <li>
                    <Link to="/recruit">{capitalize("recruit")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <img loading="lazy" src={ProjectDetail01} alt="" className="h-full" />
            </div>
          </aside>
          <main className="main flex column align-start justify-start gap-100">
            {/* タイトル */}
            <div className="title flex column gap-20">
              <div className="en">
                <span>
                  Dummy
                  <br />
                  Special Battle
                </span>
              </div>
            </div>
            {/* 記事内容 */}
            <div className="topic flex column gap-50">
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
              </div>
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>“Fanfare"が織りなすNFTプロジェクト</span>
                </div>
                <div className="article">
                  <span>
                    私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
                <div className="article">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in Ut enim Lorem
                    ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    Ut enim Lorem ipsum dolor sit amet, consectetur Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure
                    dolor in Ut enim Lorem ipsum dolor sit amet, consectetur
                  </span>
                </div>
              </div>
            </div>
            <div className="image flex align-center justify-center w-full">
              <img loading="lazy" src={articleImage1} alt="" className="w-90" />
            </div>
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>“Fanfare"が織りなすNFTプロジェクト</span>
                </div>
                <div className="article">
                  <span>
                    私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
                <div className="article">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in Ut enim Lorem
                    ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    Ut enim Lorem ipsum dolor sit amet, consectetur Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure
                    dolor in Ut enim Lorem ipsum dolor sit amet, consectetur
                  </span>
                </div>
              </div>
            </div>
            <div className="toNewsPage flex align-center justify-center w-full">
              <a href="#" target="_blank" rel="noopener noreferrer">
                <span>{capitalize("visit service site")}</span>
              </a>
            </div>
            <div className="nextProject flex column">
              <div className="upper">
                <div>
                  <div className="projectNumber flex column align-center justify-center">
                    <div className="numerator relative">01</div>
                    <div className="denominator">06</div>
                  </div>
                </div>
              </div>
              <Link to="/projects/nextproject" className="block">
                <div className="downer flex column">
                  <div className="relative">
                    <div className="nextText absolute w-full">
                      <span className="flex align-center justify-center w-full">
                        <span>{capitalize("next project")}</span>
                      </span>
                    </div>
                    <div>
                      <img loading="lazy" src={ProjectDetail02} alt="" className="w-full" />
                    </div>
                  </div>
                  <div className="text flex column align-center justify-center">
                    <span>Dummy</span>
                    <span>Special Battle</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="toProjectPage flex align-center justify-center w-full">
              <Link to="/projects">
                <span>{capitalize("all projects")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachProjects">
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachProjectsContainer flex column">
            <div className="upper flex column gap-50">
              <div className="flex column gap-30">
                <div className="title flex column w-full">
                  <div className="flex column align-end justify-end w-full">
                    <div className="child relative">
                      <span>01</span>
                    </div>
                    <div className="">
                      <span>06</span>
                    </div>
                  </div>
                  <div className="flex align-start justify-start w-full">
                    <span className="projectName w-70">Selfies</span>
                  </div>
                </div>
                <div className="text flex column gap-30">
                  <div className="en">
                    <span>
                      orem ipsum dolor sit amet, consectetur adipisicing,
                    </span>
                  </div>
                  <div className="ja">
                    <span>“Fanfare"が織りなすNFTプロジェクト</span>
                  </div>
                </div>
              </div>
              <div className="">
                <img loading="lazy" src={ProjectDetail01} alt="" className="w-full" />
              </div>
              <div className=""></div>
            </div>
            <div className="downer">
              <div className="titletext flex column gap-20">
                <div className="jaText">
                  <span>
                    私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれな。私はほか無論その一言士についてのの時から這入りだ私はほか無論その一言士についてのの時から這入りだます。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                  </span>
                </div>
              </div>
            </div>
            {/* プロジェクト説明内容 */}
            <article className="content flex column gap-50">
              <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage1} alt="" className="w-full" />
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>“Fanfare"が織りなすNFTプロジェクト</span>
                </div>
                <div className="jaContent">
                  <span>
                    私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ私はほか無論その一言士についてのの時から這入りだます。よく毎日に始末者はとうとうそうした通用たたかもを合うて来るたがは活動しですなかっが、あまりには連れないなうで。がたに云った事はそのうち十一月がともかくたなけれ
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle w-70">
                  <span>
                    orem ipsum dolor sit amet, consectetur adipisicing,
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in Ut enim Lorem ipsum dolor sit amet,
                    consectetur Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in Ut enim Lorem
                    ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    Ut enim Lorem ipsum dolor sit amet, consectetur Lorem ipsum
                    dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure
                    dolor in Ut enim Lorem ipsum dolor sit amet, consectetur
                  </span>
                </div>
              </div>
            </article>
            <div className="links flex align-center justify-center">
              <span>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  {capitalize("visit service site")}
                </a>
              </span>
            </div>
            <div className="nextProject flex column">
              <div className="upper">
                <div>
                  <div className="projectNumber flex column align-center justify-center">
                    <div className="numerator relative">02</div>
                    <div className="denominator">06</div>
                  </div>
                </div>
              </div>
              <Link to="/projects/nextproject" className="block">
                <div className="downer flex column">
                  <div className="relative">
                    <div className="nextText absolute w-full">
                      <span className="flex align-center justify-center w-full">
                        <span>{capitalize("next project")}</span>
                      </span>
                    </div>
                    <div>
                      <img loading="lazy" src={ProjectDetail02} alt="" className="w-full" />
                    </div>
                  </div>
                  <div className="text flex column align-center justify-center">
                    <span>Dummy</span>
                    <span>Special Battle</span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="toProjectPage flex align-center justify-center w-full">
              <Link to="/projects">
                <span>{capitalize("all projects")}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default EachProject;
