import React from "react";
import { useInView } from "react-intersection-observer";

// founder画像
import SeiryuSasaki from "../compressed/SeiryuSasaki.webp";
import syou from "../compressed/syou.png";
// member画像
import haruyu from "../compressed/memberharuyu.webp";
import minui from "../compressed/memberminui.webp";
import hagi from "../compressed/memberhagi.webp";
import kudo from "../compressed/memberkudo.webp";
import shiho from "../compressed/membershiho.webp";
import kazu from "../compressed/memberkazu.webp";
import ray from "../compressed/memberray.webp";
// partner画像
import microsoft from "../compressed/microsoft.webp";
import abcanimation02 from "../compressed/abcanimation02.webp";
import hikkiy from "../compressed/hikkiy.webp";
import fanworks from "../compressed/fanworks.webp";

const CompanyDetail = ({ first, useWindowWidth }) => {
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const windowWidth = useWindowWidth();
  const blurObj = {
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  };
  const blur = useInView({
    ...blurObj,
  });
  const matrix = useInView({
    threshold: 0.3, //30%見えたら検知
    triggerOnce: true,
  });
  const blur2 = useInView({
    ...blurObj,
  });
  const blur3 = useInView({
    ...blurObj,
  });
  const blur4 = useInView({
    ...blurObj,
  });
  const blur5 = useInView({
    ...blurObj,
  });
  const blur6 = useInView({
    ...blurObj,
  });

  if (windowWidth > 496) {
    if (first) {
      return (
        <section
          id="CompanyDetail"
          className="flex justify-center"
          ref={blur.ref}
        >
          <div
            className="CompanyDetailContainer flex align-center justify-center"
            ref={matrix.ref}
          >
            <div className="CompanyMiddle flex justify-center align-center h-full w-full relative">
              <div className="absolute w-full">
                <div className="MiddleContent flex align-center justify-center column">
                  <div
                    className={`matrix flex justify-center w-full ${
                      matrix.inView ? "is-animated" : ""
                    }`}
                  >
                    <span className="text_wrap">
                      <span className="inn inn1">{capitalize("battle")}</span>
                    </span>
                  </div>
                  <div
                    className={`matrix flex justify-start w-full ${
                      matrix.inView ? "is-animated" : ""
                    }`}
                  >
                    <span className="text_wrap">
                      <span className="inn inn2">{capitalize("against")}</span>
                    </span>
                  </div>
                  <div
                    className={`matrix flex justify-end w-full ${
                      matrix.inView ? "is-animated" : ""
                    }`}
                  >
                    <span className="text_wrap">
                      <span className="inn inn3">{capitalize("society")}</span>
                    </span>
                  </div>
                </div>
                <div className="leftParent absolute">
                  <div className="left flex column h-full gap-20">
                    {blur.inView && (
                      <>
                        <div className="subTitle blurNumber fontWeight700">
                          <span>{capitalize("concept")}</span>
                        </div>
                        <div className="blurTitle italic">
                          <span>
                            Questioning the "existing ways."
                            <br />
                            Loving the creator culture.
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="rightParent absolute" ref={blur2.ref}>
                  {blur2.inView && (
                    <div className="left flex column h-full gap-20">
                      <div className="subTitle blurNumber flex column">
                        <span>既存の仕組みに向き合い、</span>
                        <span>破壊的イノベーションを。</span>
                      </div>
                      <div className="ja blurTitle">
                        <span>
                          世界には受け継がれるべき文化や仕組みとともに、一度解体して再構築するべきものが溢れています。わたしたちは現存するコンテキストを読み解き、web3の理念に根ざした『破壊的イノベーション』を提起し、戦いつづけます。
                        </span>
                      </div>
                      <div className="en blurTitle">
                        <span>
                          In the world, there are traditions and systems that
                          should be passed down, but there are also things that
                          need to be dismantled and rebuilt. We decipher the
                          existing context and strive for "disruptive
                          innovation" rooted in the principles of web3,
                          continuing our battle.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="sectionTitle flex justify-start align-start w-full h-full"
              ref={blur3.ref}
            >
              <div className="blankleft"></div>
              <div className="right flex column w-full h-full gap-20">
                {blur3.inView && (
                  <>
                    <div className="flex align-center justify-start blurNumber fontWeight700">
                      <span>{capitalize("founder")}</span>
                    </div>
                    <div className="flex align-center justify-start blurTitle italic">
                      <span>
                        Continuing to challenge,
                        <br />
                        paving the way for the future.
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="memberContent flex column alugn-center justify-center w-full h-full">
              <div className="memberImage flex justify-start align-start w-full">
                <img loading="lazy" src={SeiryuSasaki} alt="" />
              </div>
              <div className="memberProfile flex align-center jusfity-center column">
                <div className="director flex align-center justify-start w-full">
                  <span>{capitalize("ceo")}</span>
                  <span className="twitter">
                    <a
                      href="https://twitter.com/0xBTL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 27 22"
                      >
                        <path
                          className="snsIcon"
                          id="white_background"
                          d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                          transform="translate(-1 -1.497)"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
                <div className="name flex w-full ">
                  <div className="ja">
                    <span>佐々木&nbsp;清龍(BATTLE)</span>
                  </div>
                  <div className="en line-height-1">
                    <span>
                      {iniCapitalize("sasaki ") +
                        iniCapitalize("seiryu ") +
                        capitalize("(battle)")}
                    </span>
                  </div>
                </div>
                <div className="eachDiscription flex gap-20">
                  <div className="ja line-height-13">
                    <span>
                      コンテンツ業界に2010年から従事しており、2014年からデジタル領域に参画。2018年に独⽴、ブロックチェーン事業にも携わる。2021年7⽉に「従来の常識と戦う、web3組織」として”THE
                      BATTLE”を設⽴。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      I have been working in the content industry since 2010 and
                      have been involved in the digital industry since 2014. I
                      went independent in 2018 and got involved in the
                      blockchain projects. In July 2021, I established “THE
                      BATTLE” as a “Web3 organization that fights against
                      conventional wisdom.”
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="CompanyDetailSecond" className="flex">
          <div className="CompanyDetailContainer flex align-center justify-center">
            {/* CTO */}
            <div className="memberContent flex column align-center justify-center w-full h-full">
              <div className="memberImage flex justify-start align-start w-full">
                <img loading="lazy" src={syou} alt="" />
              </div>
              <div className="memberProfile flex align-center jusfity-center column">
                <div className="director flex align-center justify-start w-full">
                  <span>{capitalize("cto")}</span>
                  <span className="twitter">
                    <a
                      href="https://twitter.com/nft_syou"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 27 22"
                      >
                        <path
                          className="snsIcon"
                          id="white_background"
                          d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                          transform="translate(-1 -1.497)"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
                <div className="name flex w-full ">
                  <div className="ja line-height-1">
                    <span>syou</span>
                  </div>
                  {/* <div className="en line-height-1">
                    <span>syou</span>
                  </div> */}
                </div>
                <div className="eachDiscription flex gap-20">
                  <div className="ja line-height-13">
                    <span>
                      ブロックチェーン技術に精通し、2022年より『PixelHeroesDAO』などを含む20以上のweb3プロジェクトに携わる。国内最大級のジェネラティブNFTプロジェクト『CryptoNinja
                      Partners(CNP)』ではリードエンジニアとしても活躍し、その技術的な洞察力と実行力で業界内で高い評価を受けている。2022年1月にTHE
                      BATTLEに参画。培った知識と技術を最大限に活用し、ブロックチェーンの可能性を具現化しつづけ、同社の革新的な動きに貢献。後進の育成にも務めている。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      With deep blockchain knowledge, I've worked on 20+ web3
                      projects, including "Pixel Heroes DAO" since 2022. As lead
                      engineer, I've excelled at Japan's largest generative NFT
                      project, "CryptoNinja Partners (CNP)," earning industry
                      acclaim for my technical insights and execution. I joined
                      THE BATTLE in Jan 2022 to maximize blockchain potential,
                      drive innovation, and mentor emerging talent.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 会社メンバーエリア */}
          <div
            className="memberIntroduce flex align-center justify-center w-full h-full"
            ref={blur4.ref}
          >
            <div className="left flex column h-full gap-20">
              {blur4.inView && (
                <>
                  <div className="subTitle blurNumber fontWeight700">
                    <span>{capitalize("member")}</span>
                  </div>
                  <div className="blurTitle italic">
                    <span>
                      Gathering of creative professionals from various fields.
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="right flex align-start justify-center h-full">
              <div className="separate flex align-cneter justify-center w-full h-full">
                {/* はるユさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={haruyu} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>はるユ</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/ha_ru_ha_ru1192"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="vertical-middle"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Illustrator</span>
                  </div>
                </div>
                {/* minuiさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={minui} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>minui</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/MinuiNft"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Illustrator</span>
                  </div>
                </div>
                {/* hagiさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={hagi} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>hagi</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/hagi_artnft"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Illustrator</span>
                  </div>
                </div>
                {/* kudoさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={kudo} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>kudoshun</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/kdooshun"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Designer</span>
                  </div>
                </div>
                {/* shihoさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={shiho} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>SHIHO</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/shiho_shigun"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Moderator</span>
                  </div>
                </div>
                {/* Kazuさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={kazu} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>Kazu</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/Kazuzxc"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Moderator</span>
                  </div>
                </div>
                {/* rayさん */}
                <div className="content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src={ray} alt="" className="" />
                  </div>
                  <div className="name flex">
                    <span>ray</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a
                          href="https://twitter.com/rayrayra1n"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>Engineer</span>
                  </div>
                </div>
                {/* 空き */}
                <div className="invisible content flex column align-start justify-start">
                  <div className="img flex align-center justify-start">
                    <img loading="lazy" src="" alt="" className="" />
                  </div>
                  <div className="name">
                    <span>Taro Suzuki</span>
                    <span className="flex align-center justify-center">
                      <span className="twitter">
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="13"
                            viewBox="0 0 27 22"
                          >
                            <path
                              className="snsIcon"
                              id="white_background"
                              d="M25.956,7.076c.017.243.017.486.017.732A16.05,16.05,0,0,1,9.743,23.9v0A16.244,16.244,0,0,1,1,21.363a11.7,11.7,0,0,0,1.358.082,11.514,11.514,0,0,0,7.084-2.427A5.706,5.706,0,0,1,4.113,15.09a5.731,5.731,0,0,0,2.575-.1A5.67,5.67,0,0,1,2.113,9.447V9.375a5.7,5.7,0,0,0,2.589.708A5.637,5.637,0,0,1,2.936,2.529,16.234,16.234,0,0,0,14.691,8.44a5.632,5.632,0,0,1,1.65-5.405,5.743,5.743,0,0,1,8.07.245,11.5,11.5,0,0,0,3.622-1.373,5.687,5.687,0,0,1-2.507,3.129A11.417,11.417,0,0,0,28.8,4.145,11.536,11.536,0,0,1,25.956,7.076Z"
                              transform="translate(-1 -1.497)"
                            />
                          </svg>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="director">
                    <span>CEO</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* パートナー会社エリア */}
          <div
            className="partnerIntroduce flex align-center justify-center w-full h-full"
            ref={blur5.ref}
          >
            <div className="left flex column h-full gap-20">
              {blur5.inView && (
                <>
                  <div className="subTitle blurNumber fontWeight700">
                    <span>{capitalize("partner")}</span>
                  </div>
                  <div className="blurTitle italic">
                    <span>
                      For the future of web3,
                      <br />
                      Thinking together, building continuously
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="middle flex align-start justify-start h-full">
              <div className="partnerSeparate flex column align-start justify-between h-full w-full">
                <div className="upper flex">
                  {/* 1社分の紹介 ここから */}
                  <div className="partnerContent flex column align-start justify-start">
                    <div className="flex align-center justify-center">
                      <img loading="lazy" src={microsoft} alt="" />
                    </div>
                    <div className="subTitle">
                      <span>{iniCapitalize("microsoft")}</span>
                    </div>
                  </div>
                  {/* ここまで */}
                  <div className="partnerContent flex column align-start justify-start">
                    <div className="flex align-center justify-center">
                      <img loading="lazy" src={abcanimation02} alt="" />
                    </div>
                    <div className="subTitle">
                      <span>{capitalize("abc")}&nbsp;animation</span>
                    </div>
                  </div>
                </div>
                <div className="downer flex">
                  <div className="partnerContent flex column align-start justify-start">
                    <div className="flex align-center justify-center">
                      <img loading="lazy" src={hikkiy} alt="" />
                    </div>
                    <div className="subTitle">
                      <span>{capitalize("hikky")}</span>
                    </div>
                  </div>
                  <div className="partnerContent flex column align-start justify-start">
                    <div className="flex align-center justify-center">
                      <img loading="lazy" src={fanworks} alt="" />
                    </div>
                    <div className="subTitle">
                      <span>{capitalize("fan works")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right flex align-end justify-center h-full">
              <div className="flex align-start justify-center w-full">
                <span>and&nbsp;more...</span>
              </div>
            </div>
          </div>
          {/* 弊社情報エリア */}
          <div
            className="ourCompanyIntroduce flex align-center justify-center w-full h-full"
            ref={blur6.ref}
          >
            <div className="left flex align-start justify-start column h-full gap-20">
              {blur6.inView && (
                <>
                  <div className="subTitle blurNumber fontWeight700">
                    <span>{capitalize("about")}</span>
                  </div>
                  {/* <div className="blurTitle italic">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    </span>
                  </div> */}
                </>
              )}
            </div>
            <div className="right flex column align-start justify-between h-full w-full">
              {/*  */}
              <div className="first flex column gap-12">
                <div className="eachProject">
                  <span>{capitalize("firm name")}</span>
                </div>
                <div className="eachName">
                  <span>{capitalize("the battle")}</span>
                </div>
              </div>
              {/*  */}
              <div className="second flex column gap-12">
                <div className="eachProject">
                  <span>{capitalize("date of establishment")}</span>
                </div>
                <div className="eachName">
                  <span>2021/07/01</span>
                </div>
              </div>
              <div className="third flex column gap-12">
                <div className="eachProject">
                  <span>{capitalize("ceo/") + iniCapitalize("founder")}</span>
                </div>
                <div className="eachName">
                  <span>
                    {iniCapitalize("seiryu ") + iniCapitalize("sasaki")}
                  </span>
                </div>
              </div>
              <div className="forth flex column gap-12">
                <div className="eachProject">
                  <span>{capitalize("business overview")}</span>
                </div>
                <div className="eachName">
                  <span>
                    {iniCapitalize("digital ") + iniCapitalize("creative")}
                  </span>
                </div>
              </div>
              <div className="fifth flex column gap-12">
                <div className="eachProject">
                  <span>{capitalize("address")}</span>
                </div>
                <div className="eachName">
                  <span>
                    TCIC-No23 2-41-17 Yayoicho, Nakano-ku, Tokyo 164-0013 JAPAN
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  } else {
  }
};

export default CompanyDetail;
