import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const ViewDetail = ({ useWindowWidth, name, customRef }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();

  if (windowWidth > 496) {
    if (name === "news") {
      return (
        <section className="ViewDetail nvd flex align-center justify-center h-full" ref={customRef}>
          <div className="ProjectChildrenLinkDetail relative flex align-center justify-center h-full">
            <div className="Maquee absolute w-full h-full">
              <div className="marquee_container absolute">
                <Marquee speed={10} autoFill={true} className="marquee_text">
                  <div className="user-select-none">
                    <Link to="/news">
                      {capitalize("view all news")}&nbsp;
                    </Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (name === "projects") {
      return (
        <section className="ViewDetail pvd flex align-center justify-center h-full">
          <div className="ProjectChildrenLinkDetail relative flex align-center justify-center h-full">
            <div className="Maquee absolute w-full h-full">
              <div className="marquee_container absolute">
                <Marquee speed={10} autoFill={true} className="marquee_text">
                  <div className="user-select-none">
                    <Link to="/projects">
                      {capitalize("view all projects")}&nbsp;
                    </Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (name === "company") {
      return (
        <section className="ViewDetail cvd flex align-center justify-center h-full">
          <div className="ProjectChildrenLinkDetail relative flex align-center justify-center h-full">
            <div className="Maquee absolute w-full h-full">
              <div className="marquee_container absolute">
                <Marquee speed={10} autoFill={true} className="marquee_text">
                  <div className="user-select-none">
                    <Link to="/company">
                      {capitalize("member and partner")}&nbsp;
                    </Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (name === "recruit") {
      return (
        <section className="ViewDetail rvd flex align-center justify-center h-full">
          <div className="ProjectChildrenLinkDetail relative flex align-center justify-center h-full">
            <div className="Maquee absolute w-full h-full">
              <div className="marquee_container absolute">
                <Marquee speed={10} autoFill={true} className="marquee_text flex align-center justify-center">
                  <div className="user-select-none">
                    <Link to="/recruit">
                      {capitalize("view detail")}&nbsp;
                    </Link>
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
};

export default ViewDetail;
