import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import LocomotiveScroll from "locomotive-scroll";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { SliderButton } from "@typeform/embed-react";

import MainVisual from "./components/MainVisual";
import TopNews from "./components/MainNews";
import Project from "./components/MainProject";
import ProjectDetail from "./components/MainProjectDetail";
import ViewDetail from "./components/ViewDetail";
import Company from "./components/MainCompany";
// import Recruit from "./components/MainRecruit";
import Contact from "./components/MainContact";
import NewsPage from "./components/NewsPage";
import ProjectPage from "./components/ProjectPage";
import CompanyPage from "./components/CompanyPage";
import CompanyDetail from "./components/CompanyDetail";
import RecruitPage from "./components/RecruitPage";
import RecruitDetail from "./components/RecruitDetail";
import ContactPage from "./components/ContactPage";
import EachProject from "./components/EachProject";
import EachRecruit from "./components/EachRecruit";
import SPHeader from "./components/SPHeader";
import Topic1 from "./components/Topic1";
import Topic2 from "./components/Topic2";
import Topic3 from "./components/Topic3";
import Topic4 from "./components/Topic4";

import battleLogo from "./compressed/battleLogo.webp";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [close, setClose] = useState(true);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(()=>{
    if(canScroll) return
    setTimeout(() => {
      setCanScroll(true)
    }, 5000);
  })

  const scrollCallback = useCallback((node) => {
    if (node !== null) {
      node.addEventListener("wheel", (e) => {
        if (Math.abs(e.deltaY) < Math.abs(e.deltaX)) return;
        e.preventDefault();
        if(!canScroll) return
        node.scrollLeft += e.deltaY;
      });
    }
  }, [canScroll]);

  const wrapperRef = useRef(null);

  // ルーターの設定
  const location = useLocation();

  // 各コンポーネントに遷移するための参照のためのref
  const scrollNewsRef = useRef(null);
  const scrollProjectRef = useRef(null);
  const scrollCompanyRef = useRef(null);
  const scrollRecruitRef = useRef(null);

  // 各コンポーネントに遷移する関数
  const scrollNews = () => {
    scrollNewsRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  };
  const scrollProject = () => {
    scrollProjectRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  };
  const scrollCompany = () => {
    scrollCompanyRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  };
  const scrollRecruit = () => {
    scrollRecruitRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
  };

  // ウィンドウ幅を取得
  const useWindowWidth = () => {
    const [widthSize, setWidthSize] = useState(0);

    useLayoutEffect(() => {
      const updateSize = () => {
        setWidthSize(window.innerWidth);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);

    return widthSize;
  };
  const windowWidth = useWindowWidth();

  // 慣性スクロール
  // const scroll = new LocomotiveScroll({
  //   el: wrapperRef.current,
  //   smooth: true,
  // });

  // オブザーバーの関数定義
  // ニュースページに突入したらヘッダーとcontactの追従
  const blur = useInView({
    threshold: 0, //トップビジュアルが見えた瞬間に検知
    triggerOnce: false,
  });
  //コンタクトページに突入したらヘッダーとcontactが消える
  const contactBlur = useInView({
    threshold: 0, //コンタクトエリアが見えた瞬間に検知
    triggerOnce: false,
  });
  //コンタクトページが100vwになる直前にヘッダーが消える
  const contactDetailBlur = useInView({
    threshold: 0.9, //コンタクトエリアが見えた瞬間に検知
    triggerOnce: false,
  });

  // 大文字に変換する関数
  const capitalize = (text) => text.toUpperCase();

  // SPヘッダーメニュー切り替え関数
  const handlerToggle = () => {
    setClose(!close);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoaded(true);
  //   }, 6000); // 6秒待つ
  //   return () => clearTimeout(timer); // コンポーネントがアンマウントされたときにタイマーをクリア
  // }, []); // 空の配列を依存性リストとして指定することで、このuseEffectはコンポーネントがマウントされたときに一度だけ実行される

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        {windowWidth > 496 && (
          <>
            <Route
              path="/"
              element={
                <div className="area" ref={scrollCallback}>
                  <div
                    className={`wrapper ${loaded ? "" : "overflow-hidden"}`}
                    id="wrapper"
                    ref={wrapperRef}
                  >
                    <div ref={blur.ref}>
                      <MainVisual useWindowWidth={useWindowWidth} />
                    </div>
                    <div className="flex">
                      {/*------ 追従ヘッダー------ */}
                      <div
                        className={`mainHeader flex column align-center justify-between ${
                          blur.inView ? "invisible" : "visible"
                        } ${contactBlur.inView ? "invisible" : ""}`}
                      >
                        <div className="headerImage flex align-center justify-center">
                          <Link to="/">
                            <img loading="lazy" src={battleLogo} alt="" />
                          </Link>
                        </div>
                        <ul className="flex align-center justify-center vertical_rl">
                          <li>
                            <a href="/">{capitalize("home")}</a>
                          </li>
                          <li>
                            <Link to="/news">{capitalize("news")}</Link>
                          </li>
                          <li>
                            <Link to="/projects">{capitalize("projects")}</Link>
                          </li>
                          <li>
                            <Link to="/company">{capitalize("company")}</Link>
                          </li>
                          {/* <li>
                            <Link to="/recruit">{capitalize("recruit")}</Link>
                          </li> */}
                        </ul>
                      </div>
                      <div
                        className={`matrix fixed_detailPage appBtn blurNumberDelay ${contactBlur.inView ? "" : ""}`}
                      >
                        <div className="flex align-center justify-center">
                          <SliderButton
                            id="wJm1bGdB"
                            className="typeformBtn inn inn1 cursor mix_difference"
                          >
                            {capitalize("contact")}
                          </SliderButton>
                        </div>
                      </div>
                      <TopNews
                        scrollNewsRef={scrollNewsRef}
                        useWindowWidth={useWindowWidth}
                        newsBlur={blur}
                      />
                      <ViewDetail useWindowWidth={useWindowWidth} name="news" />
                      <Project
                        scrollProjectRef={scrollProjectRef}
                        useWindowWidth={useWindowWidth}
                        projectBlur={blur}
                      />
                      {/* 各プロジェクト */}
                      <ProjectDetail
                        last={false}
                        useWindowWidth={useWindowWidth}
                        projectNumber="first"
                      />
                      <ProjectDetail
                        last={false}
                        useWindowWidth={useWindowWidth}
                        projectNumber="second"
                      />
                      <ProjectDetail
                        last={false}
                        useWindowWidth={useWindowWidth}
                        projectNumber="third"
                      />
                      <ProjectDetail
                        last={false}
                        useWindowWidth={useWindowWidth}
                        projectNumber="forth"
                      />
                      <ProjectDetail
                        last={true}
                        useWindowWidth={useWindowWidth}
                      />
                      <ViewDetail
                        useWindowWidth={useWindowWidth}
                        name="projects"
                      />
                      <Company
                        scrollCompanyRef={scrollCompanyRef}
                        useWindowWidth={useWindowWidth}
                        companyBlur={blur}
                      />
                      <ViewDetail
                        useWindowWidth={useWindowWidth}
                        name="company"
                      />
                      {/* <Recruit
                        scrollRecruitRef={scrollRecruitRef}
                        useWindowWidth={useWindowWidth}
                        recruitBlur={blur}
                      />
                      <ViewDetail
                        useWindowWidth={useWindowWidth}
                        name="recruit"
                      /> */}
                    </div>
                    <div className="flex" ref={contactBlur.ref}>
                      <Contact
                        scrollNews={scrollNews}
                        scrollProject={scrollProject}
                        scrollCompany={scrollCompany}
                        scrollRecruit={scrollRecruit}
                        useWindowWidth={useWindowWidth}
                        page="main"
                      />
                    </div>
                  </div>
                </div>
              }
            ></Route>
            <Route
              path="/news"
              element={
                <div className="area" ref={scrollCallback}>
                  <div className="newsWrapper" id="wrapper">
                    <NewsPage
                      useWindowWidth={useWindowWidth}
                      contactDetailBlurInView={contactDetailBlur.inView}
                    />
                    <Contact
                      useWindowWidth={useWindowWidth}
                      page="detail"
                      sectionPage="news"
                      contactDetailBlurRef={contactDetailBlur.ref}
                    />
                    <div className="fixed_detailPage newsBtn">
                      <div className="flex align-center justify-center">
                        <SliderButton
                          id="wJm1bGdB"
                          className="typeformBtn mix_difference"
                        >
                          {capitalize("contact")}
                        </SliderButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            ></Route>
            <Route
              path="/projects"
              element={
                <div className="area" ref={scrollCallback}>
                  <div className="projectWrapper" id="wrapper">
                    <ProjectPage
                      useWindowWidth={useWindowWidth}
                      contactDetailBlurInView={contactDetailBlur.inView}
                      page="detail"
                    />
                    <ProjectDetail
                      last={false}
                      useWindowWidth={useWindowWidth}
                      projectNumber="first"
                      page="detail"
                    />
                    <ProjectDetail
                      last={false}
                      useWindowWidth={useWindowWidth}
                      projectNumber="second"
                    />
                    <ProjectDetail
                      last={false}
                      useWindowWidth={useWindowWidth}
                      projectNumber="third"
                    />
                    <ProjectDetail
                      last={false}
                      useWindowWidth={useWindowWidth}
                      projectNumber="forth"
                    />
                    <ProjectDetail
                      last={true}
                      useWindowWidth={useWindowWidth}
                    />
                    <Contact
                      useWindowWidth={useWindowWidth}
                      page="detail"
                      sectionPage="projects"
                      contactDetailBlurRef={contactDetailBlur.ref}
                    />
                    <div className="fixed_detailPage projectsBtn">
                      <div className="flex align-center justify-center">
                        <SliderButton
                          id="wJm1bGdB"
                          className="typeformBtn mix_difference"
                        >
                          {capitalize("contact")}
                        </SliderButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            ></Route>
            <Route
              path="/company"
              element={
                <div className="area" ref={scrollCallback}>
                  <div className="companyWrapper" id="wrapper">
                    <CompanyPage
                      useWindowWidth={useWindowWidth}
                      contactDetailBlurInView={contactDetailBlur.inView}
                    />
                    <CompanyDetail
                      first={true}
                      useWindowWidth={useWindowWidth}
                    />
                    <CompanyDetail
                      first={false}
                      useWindowWidth={useWindowWidth}
                    />
                    <Contact
                      useWindowWidth={useWindowWidth}
                      page="detail"
                      sectionPage="company"
                      contactDetailBlurRef={contactDetailBlur.ref}
                    />
                    <div className="fixed_detailPage companyBtn">
                      <div className="flex align-center justify-center">
                        <SliderButton id="wJm1bGdB" className="typeformBtn">
                          {capitalize("contact")}
                        </SliderButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            ></Route>
            <Route
              path="/recruit"
              element={
                <div className="area" ref={scrollCallback}>
                  <div className="recruitWrapper" id="wrapper">
                    <RecruitPage
                      useWindowWidth={useWindowWidth}
                      contactDetailBlurInView={contactDetailBlur.inView}
                    />
                    <RecruitDetail
                      first={true}
                      useWindowWidth={useWindowWidth}
                    />
                    <RecruitDetail
                      first={false}
                      useWindowWidth={useWindowWidth}
                    />
                    <Contact
                      useWindowWidth={useWindowWidth}
                      page="detail"
                      sectionPage="recruit"
                      contactDetailBlurRef={contactDetailBlur.ref}
                    />
                    <div className="fixed_detailPage recruitBtn">
                      <div className="flex align-center justify-center">
                        <SliderButton
                          id="wJm1bGdB"
                          className="typeformBtn mix_difference"
                        >
                          {capitalize("contact")}
                        </SliderButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            ></Route>
            <Route
              path="/contact"
              element={
                <div className="area" ref={scrollCallback}>
                  <div className="contactWrapper" id="wrapper">
                    <ContactPage useWindowWidth={useWindowWidth} />
                  </div>
                </div>
              }
            ></Route>
            {/* 詳細ページ */}
            <Route
              path="/news/topic-01"
              element={
                <div className="eachContainer">
                  <Topic1 useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
            <Route
              path="/news/topic-02"
              element={
                <div className="eachContainer">
                  <Topic2 useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
            <Route
              path="/news/topic-03"
              element={
                <div className="eachContainer">
                  <Topic3 useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
            <Route
              path="/news/topic-04"
              element={
                <div className="eachContainer">
                  <Topic4 useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
            <Route
              path="/projects/selfies"
              element={
                <div className="eachContainer">
                  <EachProject useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
            <Route
              path="/recruit/design-engineer"
              element={
                <div className="eachContainer">
                  <EachRecruit useWindowWidth={useWindowWidth} />
                </div>
              }
            ></Route>
          </>
        )}
        {windowWidth <= 496 && (
          <>
            <Route
              path="/"
              element={
                <>
                  {close ? (
                    <div className="spWrapper">
                      <div ref={blur.ref}>
                        <MainVisual useWindowWidth={useWindowWidth} />
                      </div>
                      <div className="spMainHeaderArea flex column">
                        {/* ----追従ヘッダー---- */}
                        <header
                          className={`fixed flex align-center justify-between w-full ${
                            blur.inView ? "spInvisible" : "spVisible"
                          }`}
                        >
                          <div className="logo flex align-center justify-center">
                            <a href="/">
                              <img loading="lazy" src={battleLogo} alt="" className="w-80" />
                            </a>
                          </div>
                          <div className="menuBtn">
                            <button onClick={handlerToggle}>
                              {capitalize("menu")}
                            </button>
                          </div>
                        </header>
                        <TopNews
                          scrollNewsRef={scrollNewsRef}
                          useWindowWidth={useWindowWidth}
                        />
                        <Project
                          scrollProjectRef={scrollProjectRef}
                          useWindowWidth={useWindowWidth}
                        />
                        <ProjectDetail
                          last={false}
                          useWindowWidth={useWindowWidth}
                        />
                        <ProjectDetail
                          last={true}
                          useWindowWidth={useWindowWidth}
                        />
                        <Company
                          scrollCompanyRef={scrollCompanyRef}
                          useWindowWidth={useWindowWidth}
                        />
                        {/* <Recruit
                          scrollRecruitRef={scrollRecruitRef}
                          useWindowWidth={useWindowWidth}
                        /> */}
                        <Contact
                          scrollNews={scrollNews}
                          scrollProject={scrollProject}
                          scrollCompany={scrollCompany}
                          scrollRecruit={scrollRecruit}
                          useWindowWidth={useWindowWidth}
                        />
                        <div
                          className={`contactText fixed mix_difference`}
                        >
                          <SliderButton id="wJm1bGdB" className="SPtypeformBtn blurNumberDelay">
                            {capitalize("contact")}
                          </SliderButton>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="home" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/news"
              element={
                <>
                  {close ? (
                    <div className="spWrapper">
                      <NewsPage
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                      <Contact useWindowWidth={useWindowWidth} sectionPage="news"/>
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="news" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/projects"
              element={
                <>
                  {close ? (
                    <div className="spWrapper">
                      <ProjectPage
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                      <Contact useWindowWidth={useWindowWidth} sectionPage="projects"/>
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="projects" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/company"
              element={
                <>
                  {close ? (
                    <div className="spWrapper">
                      <CompanyPage
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                      <Contact useWindowWidth={useWindowWidth} sectionPage="company"/>
                    </div>
                  ) : (
                    <SPHeader
                      handlerToggle={handlerToggle}
                      logo="white"
                      page="company"
                    />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/recruit"
              element={
                <>
                  {close ? (
                    <div className="spWrapper">
                      <RecruitPage
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                      <RecruitDetail
                        first={false}
                        useWindowWidth={useWindowWidth}
                      />
                      <Contact useWindowWidth={useWindowWidth} sectionPage="recruit"/>
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="recruit" />
                  )}
                </>
              }
            ></Route>
            {/* 詳細ページ */}
            <Route
              path="/news/topic-01"
              element={
                <>
                  {close ? (
                    <div className="eachContainer">
                      <Topic1
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="newstopics" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/news/topic-02"
              element={
                <>
                  {close ? (
                    <div className="eachContainer">
                      <Topic2
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="newstopics" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/news/topic-03"
              element={
                <>
                  {close ? (
                    <div className="eachContainer">
                      <Topic3
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="newstopics" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/news/topic-04"
              element={
                <>
                  {close ? (
                    <div className="eachContainer">
                      <Topic4
                        useWindowWidth={useWindowWidth}
                        handlerToggle={handlerToggle}
                      />
                    </div>
                  ) : (
                    <SPHeader handlerToggle={handlerToggle} page="newstopics" />
                  )}
                </>
              }
            ></Route>
            <Route
              path="/projects/selfies"
              element={
                <div className="eachContainer">
                  <EachProject
                    useWindowWidth={useWindowWidth}
                    handlerToggle={handlerToggle}
                  />
                </div>
              }
            ></Route>
            <Route
              path="/recruit/design-engineer"
              element={
                <div className="eachContainer">
                  <EachRecruit
                    useWindowWidth={useWindowWidth}
                    handlerToggle={handlerToggle}
                  />
                </div>
              }
            ></Route>
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
