import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SliderButton } from "@typeform/embed-react";

import ProjectPage01 from "../compressed/ProjectPage01.webp";
import battleLogo from "../compressed/battleLogo.webp";
import SPProject01 from "../compressed/SPProject01.webp";

import corp_wagmi_2 from "../images/corp_wagmi_2.png";
import SPProjectsDetail01 from "../compressed/SPProjectsDetail01.webp";
import corp_CAL_2 from "../images/corp_CAL_2.png";
import corp_sakuya_2 from "../images/corp_sakuya_2.png";
import corp_NFTFESTA_2 from "../images/corp_NFTFESTA_2.png";
import corp_BATTLEX_2 from "../images/corp_BATTLEX_2.png";

const ProjectPage = ({
  useWindowWidth,
  handlerToggle,
  contactDetailBlurInView,
  page,
}) => {
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const windowWidth = useWindowWidth();
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section id="ProjectPage" className="flex" ref={blur.ref}>
        {contactDetailBlurInView ? (
          <></>
        ) : (
          <header className="flex column align-center justify-between">
            <div className="headerImage flex align-center justify-center">
              <Link to="/">
                <img loading="lazy" src={battleLogo} alt="" />
              </Link>
            </div>
            <ul className="flex align-center justify-center vertical_rl">
              <li>
                <Link to="/">{capitalize("home")}</Link>
              </li>
              <li>
                <Link to="/news">{capitalize("news")}</Link>
              </li>
              <li>
                <Link to="/projects" className="strike-out-line">
                  {capitalize("projects")}
                </Link>
              </li>
              <li>
                <Link to="/company">{capitalize("company")}</Link>
              </li>
              {/* <li>
                <Link to="/recruit">{capitalize("recruit")}</Link>
              </li> */}
            </ul>
          </header>
        )}
        <div
          className={`Container page_contanier flex ${
            page === "detail" ? "ProjectChildrenFirstContainer" : ""
          }`}
        >
          <div className="upper flex column justify-between w-full h-full">
            {blur.inView && (
              <div className="BigNumber blurNumber w-full user-select-none">
                <h1 className="w-full">02</h1>
              </div>
            )}
            <div className="downer flex justify-center w-full">
              <div
                className={`subTitle flex align-end ${
                  blur.inView ? "blurTitle" : "invisible"
                }`}
              >
                <span className="user-select-none">
                  {capitalize("projects")}
                </span>
              </div>
              {blur.inView && (
                <div className="text flex column align-end justify-end gap-20 blurText">
                  <div className="jaText">
                    <span>
                      わたしたちはイラスト、アニメ、音楽――すべてのクリエイティブを愛しています。各領域のプロフェッショナルとともに、web3領域における0→1のIP創出から育成、フロントエンドの制作からブロックチェーンのコントラクト開発に至るまで一気通貫で行います。
                    </span>
                  </div>
                  <div className="enText">
                    <span>
                      We love illustration, anime, music, and all forms of
                      creativity. We work with professionals in each field to
                      create and nurture IP from the start in the entertainment
                      field. We work on everything from front-end production in
                      the web2 area to blockchain contract development in the
                      web3 area.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="NewsPageLeft flex column">
            <img loading="lazy" src={ProjectPage01} alt="" className="w-full" />
            {/* <div className="flex align-center justify-center">
              <span>
                <Link to="/contact">{capitalize("contact")}</Link>
              </span>
            </div> */}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spProjectPage" ref={blur.ref}>
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed mix_difference">
            <SliderButton id="wJm1bGdB" className="SPtypeformBtn">
              {capitalize("contact")}
            </SliderButton>
          </div>
          <div className="spDetailDowner flex column relative">
            {blur.inView && (
              <div className="titlenumber blurNumber absolute">
                <span>02</span>
              </div>
            )}
            <div className="titleimage relative">
              <div
                className={`absolute mix_difference ${
                  blur.inView ? "blurTitle" : ""
                }`}
              >
                <span>{capitalize("projects")}</span>
              </div>
              <div className="">
                <img
                  loading="lazy"
                  src={SPProject01}
                  alt=""
                  className="w-full"
                />
              </div>
            </div>
            {blur.inView && (
              <div className="titletext flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    わたしたちはイラスト、アニメ、音楽――すべてのクリエイティブを愛しています。各領域のプロフェッショナルとともに、web3領域における0→1のIP創出から育成、フロントエンドの制作からブロックチェーンのコントラクト開発に至るまで一気通貫で行います。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    We love illustration, anime, music, and all forms of
                    creativity. We work with professionals in each field to
                    create and nurture IP from the start in the entertainment
                    field. We work on everything from front-end production in
                    the web2 area to blockchain contract development in the web3
                    area.
                  </span>
                </div>
              </div>
            )}

            <div className="subcontent flex column gap-20">
              <div className="flex column">
                <div className="projectsList flex column">
                  <span>{capitalize("projects")}</span>
                  <span>{capitalize("list")}</span>
                </div>
              </div>
              <div className="w-60">
                <span>
                  Shaping the future of web3 together with our project
                </span>
              </div>
            </div>
            <div className="content project flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="image">
                  <img
                    loading="lazy"
                    src={corp_wagmi_2}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="projectNumber flex align-center justify-start gap-20">
                  <div className="flex column">
                    <div className="child relative">
                      <span>01</span>
                    </div>
                    <div className="">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="projectName wagmi">
                    <span>
                      {capitalize("wagmi ") + iniCapitalize("records")}
                    </span>
                  </div>
                </div>
                <div className="parjectSPtext w-90 flex column gap-20">
                  <div className="en">
                    <span>
                      Collaborative Project with a major anime production
                      company
                    </span>
                  </div>
                  <div className="ja">
                    <span>
                      大手アニメーション製作会社との
                      <br />
                      共同事業
                    </span>
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      朝日放送グループホールディングスの子会社であるABCアニメーション社とともに推し進める新規IPの開発と育成を中心とした共同プロジェクト。web3ならではのコミュニティ参加型のIP共創を目指す。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      Collaborative project focusing on the development and
                      nurturing of new IP that is being promoted together with
                      ABC Animation, Inc. a subsidiary of Asahi Broadcasting
                      Group Holdings, Inc. The project aims to co-create an IP
                      together with a community, which is unique to web3.
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link to="/projects/selfies">{capitalize("all view")}</Link>
                </span>
              </div> */}
            </div>
            <div className="content project flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="image">
                  <img
                    loading="lazy"
                    src={SPProjectsDetail01}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="projectNumber flex align-center justify-start gap-20">
                  <div className="flex column">
                    <div className="child relative">
                      <span>02</span>
                    </div>
                    <div className="">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="projectName">
                    <span>{iniCapitalize("selfies")}</span>
                  </div>
                </div>
                <div className="parjectSPtext w-90 flex column gap-20">
                  <div className="en">
                    <span>Portraying the daily life of “Fanfare”</span>
                  </div>
                  <div className="ja">
                    <span>”Fanfare”の日常を描く</span>
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      無名のアイドルグループ“Fanfare"が織りなすNFTプロジェクト『Selfies』。10人のアイドルたちは一流のアイドルを目指して活動し、自分たちの存在を世界に知らせるために自撮り写真をNFTにします。ABCアニメーションとの共創プロジェクト第一弾。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      ”Selfies" is an NFT project by an unknown idol group
                      "Fanfare". For them to be known worldwide, Fanfare has
                      started an activity to deliver their self-portrait NFT
                      photos called "Selfies." This is the first collaborative
                      project with ABC Animation, Inc.
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link>{capitalize("all view")}</Link>
                </span>
              </div> */}
            </div>
            {/* CryptoAnime Labs */}
            <div className="content project flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="image">
                  <img
                    loading="lazy"
                    src={corp_CAL_2}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="projectNumber flex align-center justify-start gap-20">
                  <div className="flex column">
                    <div className="child relative">
                      <span>03</span>
                    </div>
                    <div className="">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="projectName cal">
                    <span>
                      {iniCapitalize("Crypto") +
                        iniCapitalize("anime ") +
                        iniCapitalize("labs")}
                    </span>
                  </div>
                </div>
                <div className="parjectSPtext w-90 flex column gap-20">
                  <div className="en">
                    <span>Striving to create an anime of the web3 era.</span>
                  </div>
                  <div className="ja">
                    <span>web3時代のアニメづくりを目指して</span>
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      『web3時代のアニメ制作委員会』を謳うLLPによる実証実験プロジェクト。既存のアニメーション制作とは異なる、クリエイターとファン、コミュニティが一体となって世界中にアニメ作品を届ける仕組みづくりを推し進めます。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      This is a demonstration project by LLP, which claims to be
                      the Anime of Web3 era. The project is different from
                      existing production, and will promote the creation of a
                      system in which creators, fans, and the community work
                      together to deliver anime to the world.
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link>{capitalize("all view")}</Link>
                </span>
              </div> */}
            </div>
            {/*  */}
            <div className="content project flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="image">
                  <img
                    loading="lazy"
                    src={corp_sakuya_2}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="projectNumber flex align-center justify-start gap-20">
                  <div className="flex column">
                    <div className="child relative">
                      <span>04</span>
                    </div>
                    <div className="">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="projectName spShinobanai flex column">
                    <span>『忍ばない！</span>
                    <span>クリプトニンジャ咲耶』</span>
                  </div>
                </div>
                <div className="parjectSPtext w-90 flex column gap-20">
                  <div className="en">
                    <span>
                      The world’s first TV anime adaptation based on an NFT
                      project.
                    </span>
                  </div>
                  <div className="ja">
                    <span>
                      NFTを原作とした、
                      <br />
                      世界初のTVアニメ化
                    </span>
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      キャラクター系アニメの話題作を続々と世に送り出すファンワークス社とともに制作を進めるアニメ制作プロジェクト。NFT原作としては世界初のTVアニメシリーズ化を実現し、2023年10月より放送を開始いたします。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      This is an anime production project that is being produced
                      in collaboration with Fanworks Inc., a company that has
                      released a series of high-profile character-based
                      animation works. This is the world's first animated TV
                      series based on NFT original work, and will begin
                      broadcasting in Oct 2023.
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link>{capitalize("all view")}</Link>
                </span>
              </div> */}
            </div>
            {/* BattleX */}
            <div className="content project flex column align-start justify-center w-full h-full gap-50">
              <div className="flex column align-start justify-center gap-30">
                <div className="image">
                  <img
                    loading="lazy"
                    src={corp_BATTLEX_2}
                    alt=""
                    className="w-full"
                  />
                </div>
                <div className="projectNumber flex align-center justify-start gap-20">
                  <div className="flex column">
                    <div className="child relative">
                      <span>05</span>
                    </div>
                    <div className="">
                      <span>05</span>
                    </div>
                  </div>
                  <div className="projectName">
                    <span>{iniCapitalize("battle") + capitalize("x")}</span>
                  </div>
                </div>
                <div className="parjectSPtext w-90 flex column gap-20">
                  <div className="en">
                    <span>New project, initiated.</span>
                  </div>
                  <div className="ja">
                    <span>新プロジェクト、始動。</span>
                  </div>
                </div>
                <div className="text flex column gap-20">
                  <div className="ja">
                    <span>
                      私たちにはひとりひとりに物語がある。物語は自分のものでもあり、自分を認識する様々な観測者のものでもある。
                    </span>
                  </div>
                  <div className="en">
                    <span>
                      We all have our own story. A story that belongs to us, but
                      it also belongs to various observers who perceive us.
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="allview flex align-center justify-end w-full">
                <span>
                  <Link>{capitalize("all view")}</Link>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default ProjectPage;
