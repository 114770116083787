import React from "react";
import { SliderButton } from "@typeform/embed-react";
import { Link } from "react-router-dom";

import battleLogo from "../compressed/battleLogo.webp";
import battleLogoWhite from "../compressed/battleLogoWhite.webp";

const SPHeader = ({ handlerToggle, logo = "black", page }) => {
  // 大文字に変換する関数
  const capitalize = (text) => text.toUpperCase();

  return (
    <div className="spToggleWrapper flex">
      <header className={`flex align-center justify-between w-full spVisible ${
          page === "projects" ? "spHPJ" : ""
        } ${page === "company" ? "spHCP" : ""} ${
          page === "recruit" ? "spHR" : ""
        }`}>
        <div className="logo flex align-center justify-center">
          <a href="/">
            {logo === "black" && (
              <img loading="lazy" src={battleLogo} alt="" className="w-80" />
            )}
            {logo === "white" && (
              <img loading="lazy" src={battleLogoWhite} alt="" className="w-80" />
            )}
          </a>
        </div>
        {logo === "black" && (
          <div className="menuBtn black">
            <button onClick={handlerToggle} className="black">
              {capitalize("close")}
            </button>
          </div>
        )}
        {logo === "white" && (
          <div className="menuBtn">
            <button onClick={handlerToggle} className="white">{capitalize("close")}</button>
          </div>
        )}
      </header>
      <div className="toggleContactText bottom0 fixed blurSPNumber6">
        <SliderButton id="wJm1bGdB" className={`SPtypeformBtnBlack cursor ${page === "company" ? "spWhite" : ""}`}>
          {capitalize("contact")}
        </SliderButton>
      </div>
      <div
        className={`spHeaderMenu flex column align-start justify-center w-full h-full ${
          page === "projects" ? "spHPJ" : ""
        } ${page === "company" ? "spHCP" : ""} ${
          page === "recruit" ? "spHR" : ""
        }`}
      >
        <div className="flex align-start w-full blurSPNumber1">
          {page === "home" ? (
            <span onClick={handlerToggle}>{capitalize("home")}</span>
          ) : (
            <span onClick={handlerToggle}>
              <Link to="/">{capitalize("home")}</Link>
            </span>
          )}
        </div>
        <div className="flex align-start w-full blurSPNumber2">
          {page === "news" ? (
            <span onClick={handlerToggle}>{capitalize("news")}</span>
          ) : (
            <span onClick={handlerToggle}>
              <Link to="/news">{capitalize("news")}</Link>
            </span>
          )}
        </div>
        <div className="flex align-start w-full blurSPNumber3">
          {page === "projects" ? (
            <span onClick={handlerToggle}>{capitalize("projects")}</span>
          ) : (
            <span onClick={handlerToggle}>
              <Link to="/projects">{capitalize("projects")}</Link>
            </span>
          )}
        </div>
        <div className="flex align-start w-full blurSPNumber4">
          {page === "company" ? (
            <span onClick={handlerToggle}>{capitalize("company")}</span>
          ) : (
            <span onClick={handlerToggle}>
              <Link to="/company">{capitalize("company")}</Link>
            </span>
          )}
        </div>
        {/* <div className="flex align-start w-full blurSPNumber5">
          {page === "recruit" ? (
            <span onClick={handlerToggle}>{capitalize("recruit")}</span>
          ) : (
            <span onClick={handlerToggle}>
              <Link to="/recruit">{capitalize("recruit")}</Link>
            </span>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default SPHeader;
