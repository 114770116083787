// import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import corp_wagmi_4 from "../compressed/corp_wagmi_4.webp";
import Selfies from "../compressed/Selfies.webp";
import corp_CAL_4 from "../compressed/corp_CAL_4.webp";
import corp_sakuya_4 from "../compressed/corp_sakuya_4.webp";
import corp_NFTFESTA_4 from "../compressed/corp_nftfesta_4.webp";
import corp_BATTLEX_4 from "../compressed/corp_BATTLEX_4.webp";

import corp_wagmi_2 from "../compressed/corp_wagmi_2.webp";
import SPProjectsDetail01 from "../compressed/SPProjectsDetail01.webp";
import corp_CAL_2 from "../compressed/corp_CAL_2.webp";
import corp_sakuya_2 from "../compressed/corp_sakuya_2.webp";
import corp_NFTFESTA_2 from "../compressed/corp_NFTFESTA_2.webp";
import corp_BATTLEX_2 from "../compressed/corp_BATTLEX_2.webp";

const ProjectDetail = ({ last, useWindowWidth, projectNumber, page }) => {
  const capitalize = (text) => text.toUpperCase();
  const iniCapitalize = (text) => text.charAt(0).toUpperCase() + text.substr(1);
  const windowWidth = useWindowWidth();

  const blur = useInView({
    threshold: 0.1, //10%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 900) {
    // 05/05
    if (last) {
      return (
        <>
          <section id="ProjectDetail" className="scrollContent" ref={blur.ref}>
            <div className="ProjectChildrenContainer flex  Cormorant_Garamond relative h-full w-full">
              <div className="ProjectChildrenLeft">
                <img
                  loading="lazy"
                  src={corp_BATTLEX_4}
                  alt=""
                  className="h-full"
                />
              </div>
              {/* BattleX */}
              <div className="ProjectChildrenRight flex column justify-start relative h-full">
                <div className="upperContent flex align-start column">
                  {blur.inView && (
                    <div className="projectNumber flex column blurNumber">
                      <div className="numerator relative">05</div>
                      <div className="denominator">05</div>
                    </div>
                  )}
                  <div className="projectName">
                    <div className={blur.inView ? "blurTitle" : "invisible"}>
                      {iniCapitalize("Battle") + capitalize("x")}
                    </div>
                  </div>
                </div>
                <div className="downerContent flex">
                  <div className="left flex column">
                    <div className="projectText flex column">
                      <div className="subDiscription italic">
                        <span>New project, initiated.</span>
                      </div>
                      <div className="subDiscription">
                        <span>新プロジェクト、始動。</span>
                      </div>
                      <div className="flex gap-20">
                        <div className="jaText">
                          <span>
                            私たちにはひとりひとりに物語がある。物語は自分のものでもあり、自分を認識する様々な観測者のものでもある。
                          </span>
                        </div>
                        <div className="enText">
                          <span>
                            We all have our own story. A story that belongs to
                            us, but it also belongs to various observers who
                            perceive us.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="right viewMore flex">
                      <div className="flex align-end">
                        <Link to="/projects/selfies">
                          {capitalize("view more")}
                        </Link>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </section>
        </>
      );
    } else {
      // 01~05/05
      return (
        <>
          {page === "detail" && (
            <section ref={blur.ref}>
              {blur.inView && (
                <div className="projectsList flex h-full">
                  <div className="projectsListBorder flex align-start justify-start column gap-20">
                    <div className="flex align-center justify-start blurNumber">
                      <span>{capitalize("projects list")}</span>
                    </div>
                    <div className="flex align-center justify-start blurTitle">
                      <span>
                        Shaping the future of web3 together with our project
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </section>
          )}
          <section id="ProjectDetail" className="scrollContent" ref={blur.ref}>
            <div className="ProjectChildrenContainer flex  Cormorant_Garamond relative h-full w-full">
              <div className="ProjectChildrenLeft">
                {projectNumber === "first" && (
                  <img
                    loading="lazy"
                    src={corp_wagmi_4}
                    alt=""
                    className="h-full"
                  />
                )}
                {projectNumber === "second" && (
                  <img loading="lazy" src={Selfies} alt="" className="h-full" />
                )}
                {projectNumber === "third" && (
                  <img
                    loading="lazy"
                    src={corp_CAL_4}
                    alt=""
                    className="h-full"
                  />
                )}
                {projectNumber === "forth" && (
                  <img
                    loading="lazy"
                    src={corp_sakuya_4}
                    alt=""
                    className="h-full"
                  />
                )}
                {projectNumber === "fifth" && (
                  <img
                    loading="lazy"
                    src={corp_NFTFESTA_4}
                    alt=""
                    className="h-full"
                  />
                )}
              </div>
              {/* WAGMI 英語 */}
              {projectNumber === "first" && (
                <div className="ProjectChildrenRight flex column justify-start relative h-full">
                  <div className="upperContent flex align-start column">
                    {blur.inView && (
                      <div className="projectNumber flex column blurNumber">
                        <div className="numerator relative">01</div>
                        <div className="denominator">05</div>
                      </div>
                    )}
                    <div className="projectName">
                      <div className={blur.inView ? "blurTitle" : "invisible"}>
                        {capitalize("wagmi ") + iniCapitalize("records")}
                      </div>
                    </div>
                  </div>
                  <div className="downerContent flex">
                    <div className="left flex column">
                      <div className="projectText flex column">
                        <div className="subDiscription italic">
                          <span>
                            Collaborative Project with a major anime production
                            company
                          </span>
                        </div>
                        <div className="subDiscription">
                          <span>大手アニメーション製作会社との共同事業</span>
                        </div>
                        <div className="flex gap-20">
                          <div className="jaText">
                            <span>
                              朝日放送グループホールディングスの子会社であるABCアニメーション社とともに推し進める新規IPの開発と育成を中心とした共同プロジェクト。web3ならではのコミュニティ参加型のIP共創を目指す。
                            </span>
                          </div>
                          <div className="enText">
                            <span>
                              Collaborative project focusing on the development
                              and nurturing of new IP that is being promoted
                              together with ABC Animation, Inc. a subsidiary of
                              Asahi Broadcasting Group Holdings, Inc. The
                              project aims to co-create an IP together with a
                              community, which is unique to web3.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="right viewMore flex">
                      <div className="flex align-end">
                        <Link to="/projects/selfies">
                          {capitalize("view more")}
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
              {/* Selfies */}
              {projectNumber === "second" && (
                <div className="ProjectChildrenRight flex column justify-start relative h-full">
                  <div className="upperContent flex align-start column">
                    {blur.inView && (
                      <div className="projectNumber flex column blurNumber">
                        {projectNumber === "second" && (
                          <div className="numerator relative">02</div>
                        )}
                        <div className="denominator">05</div>
                      </div>
                    )}
                    {projectNumber === "second" && (
                      <div className="projectName">
                        <div
                          className={blur.inView ? "blurTitle" : "invisible"}
                        >
                          {iniCapitalize("Selfies")}
                        </div>
                      </div>
                    )}
                  </div>
                  {projectNumber === "second" && (
                    <div className="downerContent flex">
                      <div className="left flex column">
                        <div className="projectText flex column">
                          <div className="subDiscription italic">
                            <span>Portraying the daily life of “Fanfare”</span>
                          </div>
                          <div className="subDiscription">
                            <span>”Fanfare”の日常を描く</span>
                          </div>
                          <div className="flex gap-20">
                            <div className="jaText">
                              <span>
                                無名のアイドルグループ“Fanfare"が織りなすNFTプロジェクト『Selfies』。10人のアイドルたちは一流のアイドルを目指して活動し、自分たちの存在を世界に知らせるために自撮り写真をNFTにします。ABCアニメーションとの共創プロジェクト第一弾。
                              </span>
                            </div>
                            <div className="enText">
                              <span>
                                ”Selfies" is an NFT project by an unknown idol
                                group "Fanfare". For them to be known worldwide,
                                Fanfare has started an activity to deliver their
                                self-portrait NFT photos called "Selfies." This
                                is the first collaborative project with ABC
                                Animation, Inc.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="right viewMore flex">
                      <div className="flex align-end">
                        <Link to="/projects/selfies">
                          {capitalize("view more")}
                        </Link>
                      </div>
                    </div> */}
                    </div>
                  )}
                </div>
              )}
              {/*  */}
              {projectNumber === "third" && (
                <div className="ProjectChildrenRight flex column justify-start relative h-full">
                  <div className="upperContent flex align-start column">
                    {blur.inView && (
                      <div className="projectNumber flex column blurNumber">
                        {projectNumber === "third" && (
                          <div className="numerator relative">03</div>
                        )}
                        <div className="denominator">05</div>
                      </div>
                    )}
                    {projectNumber === "third" && (
                      <div className="projectName">
                        <div
                          className={blur.inView ? "blurTitle" : "invisible"}
                        >
                          {iniCapitalize("Crypto") +
                            iniCapitalize("anime ") +
                            iniCapitalize("labs")}
                        </div>
                      </div>
                    )}
                  </div>
                  {projectNumber === "third" && (
                    <div className="downerContent flex">
                      <div className="left flex column">
                        <div className="projectText flex column">
                          <div className="subDiscription italic">
                            <span>
                              Striving to create an anime of the web3 era.
                            </span>
                          </div>
                          <div className="subDiscription">
                            <span>web3時代のアニメづくりを目指して</span>
                          </div>
                          <div className="flex gap-20">
                            <div className="jaText">
                              <span>
                                『web3時代のアニメ制作委員会』を謳うLLPによる実証実験プロジェクト。既存のアニメーション制作とは異なる、クリエイターとファン、コミュニティが一体となって世界中にアニメ作品を届ける仕組みづくりを推し進めます。
                              </span>
                            </div>
                            <div className="enText">
                              <span>
                                This is a demonstration project by LLP, which
                                claims to be the Anime of Web3 era. The project
                                is different from existing production, and will
                                promote the creation of a system in which
                                creators, fans, and the community work together
                                to deliver anime to the world.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="right viewMore flex">
                      <div className="flex align-end">
                        <Link to="/projects/selfies">
                          {capitalize("view more")}
                        </Link>
                      </div>
                    </div> */}
                    </div>
                  )}
                </div>
              )}
              {/* 『忍ばない！クリプトニンジャ咲耶』 */}
              {projectNumber === "forth" && (
                <div className="ProjectChildrenRight flex column justify-start relative h-full">
                  <div className="upperContent CALContainer flex align-start column">
                    {blur.inView && (
                      <div className="projectNumber flex column blurNumber">
                        {projectNumber === "forth" && (
                          <div className="numerator relative">04</div>
                        )}
                        <div className="denominator">05</div>
                      </div>
                    )}
                    {projectNumber === "forth" && (
                      <div className="projectName shinobanai">
                        <div
                          className={blur.inView ? "blurTitle" : "invisible"}
                        >
                          『忍ばない！
                        </div>
                        <div
                          className={blur.inView ? "blurTitle" : "invisible"}
                        >
                          クリプトニンジャ咲耶』
                        </div>
                      </div>
                    )}
                  </div>
                  {projectNumber === "forth" && (
                    <div className="downerContent flex">
                      <div className="left flex column">
                        <div className="projectText flex column">
                          <div className="subDiscription italic">
                            <span>
                              The world’s first TV anime adaptation based on an
                              NFT project.
                            </span>
                          </div>
                          <div className="subDiscription">
                            <span>NFTを原作とした、世界初のTVアニメ化</span>
                          </div>
                          <div className="flex gap-20">
                            <div className="jaText">
                              <span>
                                キャラクター系アニメの話題作を続々と世に送り出すファンワークス社とともに制作を進めるアニメ制作プロジェクト。NFT原作としては世界初のTVアニメシリーズ化を実現し、2023年10月より放送を開始いたします。
                              </span>
                            </div>
                            <div className="enText">
                              <span>
                                This is an anime production project that is
                                being produced in collaboration with Fanworks
                                Inc., a company that has released a series of
                                high-profile character-based animation works.
                                This is the world's first animated TV series
                                based on NFT original work, and will begin
                                broadcasting in Oct 2023.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </>
      );
    }
  } else if (windowWidth > 496) {
    return (
      <>
        {page === "detail" && (
          <section ref={blur.ref} className="superContainer">
            {blur.inView && (
              <div className="projectsList flex h-full">
                <div className="projectsListBorder flex align-start justify-start column gap-20">
                  <div className="flex align-center justify-start blurNumber">
                    <span>{capitalize("projects list")}</span>
                  </div>
                  <div className="flex align-center justify-start blurTitle">
                    <span>
                      Shaping the future of web3 together with our project
                    </span>
                  </div>
                </div>
              </div>
            )}
          </section>
        )}
        <section className="ProjectsNarrowDetail">
          <div className="ProjectsNarrowDetailContainer h-full w-full">
            {/* WAGMI Records */}
            {projectNumber === "first" && (
              <div className="eachNarrowContainer flex column">
                <div className="topContent flex column">
                  <div className="image flex align-center justify-center w-full">
                    <img
                      loading="lazy"
                      src={corp_wagmi_2}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="title flex align-start justify-start w-full">
                    <div className="titleNumber flex column">
                      <span className="first">01</span>
                      <span>05</span>
                    </div>
                    <div className="flex align-center justify-center h-full">
                      <div className="projectName">
                        <span>
                          {capitalize("wagmi ") + iniCapitalize("records")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent flex column">
                  <div className="projectsSubTitle flex column">
                    <div className="enTitle italic">
                      <span>
                        Collaborative Project with a major anime production
                        company
                      </span>
                    </div>
                    <div className="jaTitle">
                      <span>大手アニメーション製作会社との共同事業</span>
                    </div>
                  </div>
                  <div className="projectsText flex column">
                    <div className="ja">
                      <span>
                        朝日放送グループホールディングスの子会社であるABCアニメーション社とともに推し進める新規IPの開発と育成を中心とした共同プロジェクト。web3ならではのコミュニティ参加型のIP共創を目指す。
                      </span>
                    </div>
                    <div className="en">
                      Collaborative project focusing on the development and
                      nurturing of new IP that is being promoted together with
                      ABC Animation, Inc. a subsidiary of Asahi Broadcasting
                      Group Holdings, Inc. The project aims to co-create an IP
                      together with a community, which is unique to web3.
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Selfies */}
            {projectNumber === "second" && (
              <div className="eachNarrowContainer flex column">
                <div className="topContent flex column">
                  <div className="image flex align-center justify-center w-full">
                    <img
                      loading="lazy"
                      src={SPProjectsDetail01}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="title flex align-start justify-start w-full">
                    <div className="titleNumber flex column">
                      <span className="first">02</span>
                      <span>05</span>
                    </div>
                    <div className="flex align-center justify-center h-full">
                      <div className="projectName">
                        <span>{iniCapitalize("selfies")}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent flex column">
                  <div className="projectsSubTitle flex column">
                    <div className="enTitle italic">
                      <span>Portraying the daily life of “Fanfare”</span>
                    </div>
                    <div className="jaTitle">
                      <span>”Fanfare”の日常を描く</span>
                    </div>
                  </div>
                  <div className="projectsText flex column">
                    <div className="ja">
                      <span>
                        無名のアイドルグループ“Fanfare"が織りなすNFTプロジェクト『Selfies』。10人のアイドルたちは一流のアイドルを目指して活動し、自分たちの存在を世界に知らせるために自撮り写真をNFTにします。ABCアニメーションとの共創プロジェクト第一弾。
                      </span>
                    </div>
                    <div className="en">
                      ”Selfies" is an NFT project by an unknown idol group
                      "Fanfare". For them to be known worldwide, Fanfare has
                      started an activity to deliver their self-portrait NFT
                      photos called "Selfies." This is the first collaborative
                      project with ABC Animation, Inc.
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* CAL */}
            {projectNumber === "third" && (
              <div className="eachNarrowContainer flex column">
                <div className="topContent flex column">
                  <div className="image flex align-center justify-center w-full">
                    <img
                      loading="lazy"
                      src={corp_CAL_2}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="title flex align-start justify-start w-full">
                    <div className="titleNumber flex column">
                      <span className="first">03</span>
                      <span>05</span>
                    </div>
                    <div className="flex align-center justify-center h-full">
                      <div className="projectName">
                        <span>
                          {iniCapitalize("Crypto") +
                            iniCapitalize("anime ") +
                            iniCapitalize("labs")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent flex column">
                  <div className="projectsSubTitle flex column">
                    <div className="enTitle italic">
                      <span>Striving to create an anime of the web3 era.</span>
                    </div>
                    <div className="jaTitle">
                      <span>web3時代のアニメづくりを目指して</span>
                    </div>
                  </div>
                  <div className="projectsText flex column">
                    <div className="ja">
                      <span>
                        『web3時代のアニメ制作委員会』を謳うLLPによる実証実験プロジェクト。既存のアニメーション制作とは異なる、クリエイターとファン、コミュニティが一体となって世界中にアニメ作品を届ける仕組みづくりを推し進めます。
                      </span>
                    </div>
                    <div className="en">
                      This is a demonstration project by LLP, which claims to be
                      the Anime of Web3 era. The project is different from
                      existing production, and will promote the creation of a
                      system in which creators, fans, and the community work
                      together to deliver anime to the world.
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* 『忍ばない！ */}
            {projectNumber === "forth" && (
              <div className="eachNarrowContainer flex column">
                <div className="topContent flex column">
                  <div className="image flex align-center justify-center w-full">
                    <img
                      loading="lazy"
                      src={corp_sakuya_2}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="title flex align-start justify-start w-full">
                    <div className="titleNumber flex column">
                      <span className="first">04</span>
                      <span>05</span>
                    </div>
                    <div className="flex align-center justify-center h-full">
                      <div className="projectName narrowShinobanai">
                        <span className="flex column">
                          <span>『忍ばない！</span>
                          <span>クリプトニンジャ咲耶』</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent flex column">
                  <div className="projectsSubTitle flex column">
                    <div className="enTitle italic">
                      <span>
                        The world’s first TV anime adaptation based on an NFT
                        project.
                      </span>
                    </div>
                    <div className="jaTitle">
                      <span>NFTを原作とした、世界初のTVアニメ化</span>
                    </div>
                  </div>
                  <div className="projectsText flex column">
                    <div className="ja">
                      <span>
                        キャラクター系アニメの話題作を続々と世に送り出すファンワークス社とともに制作を進めるアニメ制作プロジェクト。NFT原作としては世界初のTVアニメシリーズ化を実現し、2023年10月より放送を開始いたします。
                      </span>
                    </div>
                    <div className="en">
                      This is an anime production project that is being produced
                      in collaboration with Fanworks Inc., a company that has
                      released a series of high-profile character-based
                      animation works. This is the world's first animated TV
                      series based on NFT original work, and will begin
                      broadcasting in Oct 2023.
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* BattleX */}
            {last === true && (
              <div className="eachNarrowContainer flex column">
                <div className="topContent flex column">
                  <div className="image flex align-center justify-center w-full">
                    <img
                      loading="lazy"
                      src={corp_BATTLEX_2}
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="title flex align-start justify-start w-full">
                    <div className="titleNumber flex column">
                      <span className="first">05</span>
                      <span>05</span>
                    </div>
                    <div className="flex align-center justify-center h-full">
                      <div className="projectName">
                        <span>
                          {iniCapitalize("battle") + iniCapitalize("x")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomContent flex column">
                  <div className="projectsSubTitle flex column">
                    <div className="enTitle italic">
                      <span>New project, initiated.</span>
                    </div>
                    <div className="jaTitle">
                      <span>新プロジェクト、始動。</span>
                    </div>
                  </div>
                  <div className="projectsText flex column">
                    <div className="ja">
                      <span>
                        私たちにはひとりひとりに物語がある。物語は自分のものでもあり、自分を認識する様々な観測者のものでもある。
                      </span>
                    </div>
                    <div className="en">
                      We all have our own story. A story that belongs to us, but
                      it also belongs to various observers who perceive us.
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
};

export default ProjectDetail;
