import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useInView } from "react-intersection-observer";

import articleImage2 from "../compressed/articleImage2.webp";
import articleImage3 from "../compressed/articleImage3.webp";
import articleImage4 from "../compressed/articleImage4.webp";
import articleImage5 from "../compressed/articleImage5.webp";

const TopNews = ({ scrollNewsRef, useWindowWidth }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const blur = useInView({
    threshold: 0.1, //10%見えたら検知
    triggerOnce: true,
  });

  if (windowWidth > 496) {
    return (
      <section id="TopNews" className="h-full flex" ref={scrollNewsRef}>
        <div className="flex" ref={blur.ref}>
          <div className="TopNewsContainer flex align-center justify-between Cormorant_Garamond relative">
            {blur.inView && (
              <div className="BigNumber blurNumber absolute user-select-none">
                <h1>01</h1>
              </div>
            )}
            <div className="TopNewsLeft flex column justify-end h-full">
              <div className="TopNewsText">
                <div
                  className={`subTitle ${
                    blur.inView ? "blurTitle" : "invisible"
                  }`}
                >
                  <span className="user-select-none">{capitalize("news")}</span>
                </div>
                {blur.inView && (
                  <div className="flex column gap-5 blurText">
                    <div className="jaText">
                      <span>
                        わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                      </span>
                    </div>
                    <div className="enText">
                      <span>
                        We continue to explore the possibilities of "web3 x
                        entertainment" utilizing blockchain and NFT
                        technologies. We sincerely hope that the "things" we
                        create will contribute to the continuation and
                        development of Japan's creative culture, and by
                        extension, the sustainability of Otaku culture around
                        the world.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="TopNewsRight flex column justify-start align-center h-full">
              <div>
                {/* 各ニュースここから OK */}
                <div className="newsTopics">
                  <div className="newsDetail flex">
                    <Link to="/news/topic-01" className="newsDetailChild flex">
                      <div className="date">
                        <div className="flex align-start w-full">
                          <span>2023</span>
                        </div>
                        <div className="flex align-start w-full">
                          <span>06.02</span>
                        </div>
                      </div>
                      <div className="article flex column">
                        <div>
                          <span>
                            NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                          </span>
                        </div>
                        <div>
                          <span>
                            The world's first TV anime series born from NFTs is
                            now a reality. The series, entitled "Shinobanai!
                            CryptoNinja Sakura," is based on the original
                            "CryptoNinja" and is set to premiere in October
                            2023.
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* ここまで */}
                {/* OK */}
                <div className="newsTopics">
                  <div className="newsDetail flex">
                    <Link to="/news/topic-02" className="newsDetailChild flex">
                      <div className="date">
                        <div className="flex align-start w-full">
                          <span>2023</span>
                        </div>
                        <div className="flex align-start w-full">
                          <span>04.27</span>
                        </div>
                      </div>
                      <div className="article flex column">
                        <div>
                          <span>
                            アニメーション事業を手掛ける「ABCアニメーション」とweb3法人「THE
                            BATTLE」が、NFTからIP展開を行う新法人「WAGMI
                            Records」設立と第一弾プロジェクトの採用を発表
                          </span>
                        </div>
                        <div>
                          <span>
                            The animation business, "ABC Animation," and the
                            web3 corporation, "THE BATTLE," have announced the
                            establishment of a new corporation, "WAGMI Records,"
                            aimed at IP development from NFTs, as well as the
                            adoption of their first project.{" "}
                          </span>
                        </div>
                      </div>
                    </Link>
                    {/* <div className="image">
                      <div className="flex align-center justify-center">
                        <img loading="lazy" src={articleImage1} alt="" className="w-80" /> 
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* OK */}
                <div className="newsTopics last">
                  <div className="newsDetail flex">
                    <Link to="/news/topic-04" className="newsDetailChild flex">
                      <div className="date">
                        <div className="flex align-start w-full">
                          <span>2022</span>
                        </div>
                        <div className="flex align-start w-full">
                          <span>11.14</span>
                        </div>
                      </div>
                      <div className="article flex column">
                        <div>
                          <span>
                            NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                          </span>
                        </div>
                        <div>
                          <span>
                            Next Generation Crowdfunding using NFT raises a
                            total of 38 Million yen in one minute. Realized
                            immediate cash arrival with crypto assets and
                            intermediate margin such as settlement fees.
                          </span>
                        </div>
                      </div>
                    </Link>
                    {/* <div className="image">
                      <div className="flex align-center justify-center">
                        <img loading="lazy" src={articleImage1} alt="" className="w-80" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="allView">
              <div className="flex justify-end text-decoration-underline">
                <Link to="/news">{capitalize("all view")}</Link>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spTopNews w-full h-full" ref={blur.ref}>
        <div className="container flex column w-full h-full relative">
          {blur.inView && (
            <div className="spNumber absolute user-select-none">
              <span>01</span>
            </div>
          )}
          <div className="content flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column">
              <div
                className={`spTitle ${blur.inView ? "blurTitle" : "invisible"}`}
              >
                <span className="user-select-none">{capitalize("news")}</span>
              </div>
            </div>
            {blur.inView && (
              <div className="flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    We continue to explore the possibilities of "web3 x
                    entertainment" utilizing blockchain and NFT technologies. We
                    sincerely hope that the "things" we create will contribute
                    to the continuation and development of Japan's creative
                    culture, and by extension, the sustainability of Otaku
                    culture around the world.
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* ニュース紹介エリア */}
          <div className="content flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column align-start justify-center gap-20">
              <div className="year w-full">
                <span>2023</span>
              </div>
              <div className="date-image flex align-start justify-between w-full">
                <div className="date flex">
                  <span className="first">06.02</span>
                  <span className="second">(fri)</span>
                </div>
                <div className="image">
                  <img loading="lazy" src={articleImage2} alt="" className="w-full" />
                </div>
              </div>
              <div className="text flex column gap-20">
                <div className="ja">
                  <span>
                    NFT発のTVアニメシリーズが世界初で実現。原作『CryptoNinja』をオリジナルストーリーで描く『忍ばない！クリプトニンジャ咲耶』が2023年10月より放送開始
                  </span>
                </div>
                <div className="en">
                  <span>
                    The world's first TV anime series born from NFTs is now a
                    reality. The series, entitled "Shinobanai! CryptoNinja
                    Sakura," is based on the original "CryptoNinja" and is set
                    to premiere in October 2023.
                  </span>
                </div>
              </div>
            </div>
            <div className="allview flex align-center justify-end w-full">
              <span>
                <Link to="/news/topic-01">{capitalize("all view")}</Link>
              </span>
            </div>
          </div>
          {/* OK */}
          <div className="content flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column align-start justify-center gap-20">
              <div className="year w-full">
                <span>2023</span>
              </div>
              <div className="date-image flex align-start justify-between w-full">
                <div className="date flex">
                  <span className="first">04.27</span>
                  <span className="second">(thu)</span>
                </div>
                <div className="image">
                  <img loading="lazy" src={articleImage4} alt="" className="w-full" />
                </div>
              </div>
              <div className="text flex column gap-20">
                <div className="ja">
                  <span>
                    アニメーション事業を手掛ける「ABCアニメーション」とweb3法人「THE
                    BATTLE」が、NFTからIP展開を行う新法人「WAGMI
                    Records」設立と第一弾プロジェクトの採用を発表
                  </span>
                </div>
                <div className="en">
                  <span>
                    The animation business, "ABC Animation," and the web3
                    corporation, "THE BATTLE," have announced the establishment
                    of a new corporation, "WAGMI Records," aimed at IP
                    development from NFTs, as well as the adoption of their
                    first project.
                  </span>
                </div>
              </div>
            </div>
            <div className="allview flex align-center justify-end w-full">
              <span>
                <Link to="/news/topic-02">{capitalize("all view")}</Link>
              </span>
            </div>
          </div>
          {/* OK */}
          {/* <div className="content flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column align-start justify-center gap-20">
              <div className="year w-full">
                <span>2022</span>
              </div>
              <div className="date-image flex align-start justify-between w-full">
                <div className="date flex">
                  <span className="first">12.09</span>
                  <span className="second">(fri)</span>
                </div>
                <div className="image">
                  <img loading="lazy" src={articleImage3} alt="" className="w-full" />
                </div>
              </div>
              <div className="text flex column gap-20">
                <div className="ja">
                  <span>
                    フルオンチェーンゲーム『Isekai
                    Battle』メインネットで探索モードが解禁。未公開の新キャラが貰える「種」集めランキングイベント開催
                  </span>
                </div>
                <div className="en">
                  <span>
                    Full-on-chain game "Isekai Battle" exploration mode is
                    unlocked on the main net. A ranking event for collecting
                    "seeds" to receive an unrevealed new character is held.
                  </span>
                </div>
              </div>
            </div>
            <div className="allview flex align-center justify-end w-full">
              <span>
                <Link to="/news/topic-03">{capitalize("all view")}</Link>
              </span>
            </div>
          </div> */}
          {/* OK */}
          <div className="content lastContent flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column align-start justify-center gap-20">
              <div className="year w-full">
                <span>2022</span>
              </div>
              <div className="date-image flex align-start justify-between w-full">
                <div className="date flex">
                  <span className="first">11.14</span>
                  <span className="second">(mon)</span>
                </div>
                <div className="image">
                  <img loading="lazy" src={articleImage5} alt="" className="w-full" />
                </div>
              </div>
              <div className="text flex column gap-20">
                <div className="ja">
                  <span>
                    NFTを活用した次世代型クラウドファンディング、1分で累計3,800万円を調達。暗号資産による即時着金、決済手数料等の中抜きゼロを実現
                  </span>
                </div>
                <div className="en">
                  <span>
                    Next Generation Crowdfunding using NFT raises a total of 38
                    Million yen in one minute. Realized immediate cash arrival
                    with crypto assets and intermediate margin such as
                    settlement fees.
                  </span>
                </div>
              </div>
            </div>
            <div className="allview flex align-center justify-end w-full">
              <span>
                <Link to="/news/topic-04">{capitalize("all view")}</Link>
              </span>
            </div>
          </div>
          <div className="sp_marquee_container flex align-center jusfity-center">
            <div className="sp_marquee_content spnvd relative">
              <Marquee speed={20} className="marquee_text">
                <div className="user-select-none">
                  <Link to="/news">{capitalize("view all news ")}</Link>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default TopNews;
