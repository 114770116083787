import { Link } from "react-router-dom";
import YouTube from "react-youtube";

import articleImage1 from "../compressed/articleImage1.webp";
import battleLogo from "../compressed/battleLogo.webp";
import articleImage3 from "../compressed/articleImage3.webp";
import topic301 from "../compressed/topic301.webp";
import OtakuCoin from "../compressed/OtakuCoin.webp";
import firmLogo from "../compressed/firmLogo.webp";

const Topic3 = ({ useWindowWidth, handlerToggle }) => {
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const opts = {
    height: "160",
    width: "300",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  if (windowWidth > 496) {
    return (
      <section id="EachNews" className="">
        <div className="eachWrapper w-full h-full">
          <aside className="sidebar flex gap10">
            <div className="header widget_sticky">
              <header className="flex column align-center justify-between">
                <div className="headerImage flex align-center justify-center">
                  <Link to="/">
                    <img loading="lazy" src={battleLogo} alt="" />
                  </Link>
                </div>
                <ul className="flex align-center justify-center vertical_rl">
                  <li>
                    <Link to="/">{capitalize("home")}</Link>
                  </li>
                  <li>
                    <Link to="/news">{capitalize("news")}</Link>
                  </li>
                  <li>
                    <Link to="/projects">{capitalize("projects")}</Link>
                  </li>
                  <li>
                    <Link to="/company">{capitalize("company")}</Link>
                  </li>
                </ul>
              </header>
            </div>
            <div className="widget widget_sticky">
              <img loading="lazy" src={articleImage3} alt="" className="w-full" />
            </div>
          </aside>
          <main className="main flex column align-start justify-start gap-100">
            {/* タイトル */}
            <div className="title flex column gap-20">
              <div className="bigTitle ja">
                <span>
                  フルオンチェーンゲーム『Isekai
                  Battle』メインネットで探索モードが解禁。未公開の新キャラが貰える「種」集めランキングイベント開催
                </span>
              </div>
              <div className="en">
                <span>
                  Full-on-chain game "Isekai Battle" exploration mode is
                  unlocked on the main net. A ranking event for collecting
                  "seeds" to receive an unrevealed new character is held.
                </span>
              </div>
            </div>
            {/* 称号「Front Runner」が貰えるスタートダッシュCPも同時開催 */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>
                    称号「Front Runner」が貰えるスタートダッシュCPも同時開催
                  </span>
                </div>
                <div className="article">
                  <span>
                    本日、株式会社THE
                    BATTLEが運営・開発を務める、フルオンチェーンゲーム『Isekai
                    Battle』がメインネットでローンチし、探索モードが解禁されたことを発表しました。探索解禁に合わせ、探索で拾える「LUKの種」の所持数で争うランキングイベントと、称号「Front
                    Runner」が貰えるスタートダッシュCPを開催いたします。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    A start dash campaign to receive the title "Front Runner"
                    will also be held at the same time.
                  </span>
                </div>
                <div className="article">
                  <span>
                    Today, "Isekai Battle," a full-on-chain game operated and
                    developed by THE BATTLE Co,. Ltd. launched on the mainnet
                    and announced that the exploration mode has been lifted. In
                    conjunction with the lifting of the exploration ban, a
                    ranking event will be held for the number of "LUK seeds"
                    that can be picked up in the exploration, and a start dash
                    CP will be held to award the title "Front Runner".
                  </span>
                </div>
              </div>
            </div>
            {/* 「種」集めランキングイベントについて(終了済み) */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>「種」集めランキングイベントについて(終了済み)</span>
                </div>
                <div className="article">
                  <span>
                    期間中に入手した「LUKの種」のClaim数で争うランキングイベント。イベント終了後のスナップショット時点で「LUKの種」の所持数が多い、上位10名様に未公開新キャラをプレゼントいたします。
                  </span>
                </div>
                <div className="article">
                  <span>
                    また、「ATKの種」「DEFの種」「LUKの種」のいずれか1つ以上所持されている方から、種ごとに1名ずつ（合計3名様）未公開新キャラクターの抽選を行います。
                  </span>
                </div>
                <div className="article">
                  <span>
                    ※マーケット（OpenSeaなど）での購入やトランスファー（送付）で獲得した「種」はイベント集計対象外となります。Claimで獲得した「種」の数のみがランキング・抽選対象となります
                    ※ランキング10位の「LUKの種」所持者が複数いる場合は抽選となります
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■イベント期間</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;探索解禁 ～ 1/8(日) 23:59</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■報酬</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;未公開新キャラ1体</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    ※Claimで獲得した「種」を所持していると自動的に応募されます
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    About the "Seeds" Collection Ranking Event ***This event has
                    been ended***
                  </span>
                </div>
                <div className="article">
                  <span>
                    A ranking event in which contestants compete for the number
                    of "LUK Seeds" Claims obtained during the event period. The
                    top 10 players with the highest number of "LUK seeds" at the
                    time of the snapshot after the event will receive an
                    undisclosed new character. In addition, from those who
                    possess at least one of the "ATK Seeds" "DEF Seeds" or "LUK
                    Seeds" we will draw one winner for each seed (3 winners in
                    total) for an undisclosed new character.
                  </span>
                </div>
                <div className="article">
                  <span>
                    In addition, from those who possess at least one of the "ATK
                    Seeds" "DEF Seeds" or "LUK Seeds" we will draw one winner
                    for each seed (3 winners in total) for an undisclosed new
                    character.
                  </span>
                </div>
                <div className="article">
                  <span>
                    *Seeds purchased on the market (OpenSea, etc.) or acquired
                    through transfers (sending) will not be counted in the
                    event. Only the number of "seeds" acquired through claiming
                    will be counted for the ranking and drawing.
                    <br />
                    *If there is more than one "LUK Seed" holder who ranks 10th,
                    the winner will be drawn by lot.
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■Event period</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;Exploration open ~ until 2023 January
                            8th (Sun.) 23:59
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■Reward</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;One new undisclosed character
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    *You will be automatically entered if you have "seeds"
                    acquired through claiming
                  </span>
                </div>
              </div>
            </div>
            {/* スタートダッシュキャンペーンについて */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>スタートダッシュキャンペーンについて</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={topic301} alt="" className="w-90" />
                </div>
                <div className="article">
                  <span>
                    期間中にキャラクターを「ノアの森」へ出発（ステーキング）させ、「武器」「防具」をClaimされた方が対象となります。
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■キャンペーン期間</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;探索解禁 ～ 12/18(日) 23:59</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■報酬</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;称号「Front Runner」</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    ※報酬配布は「称号」機能開放後になります
                    <br />
                    ※称号名は変更される場合があります
                    <br />
                    ※Claimのみで自動的に応募となります
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>
                    About the Start Dash Campaign ***This event has been
                    ended***
                  </span>
                </div>
                <div className="article">
                  <span>
                    The campaign is open to those who have departed (staked)
                    their characters to "Noah's Forest" and claimed "Weapons"
                    and "Armor" during the period.
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■Campaign period</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            &nbsp;:&nbsp;Exploration open ~ until 2022 December
                            18th (Sun.) 23:59
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>■Reward</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>&nbsp;:&nbsp;Title “Front Runner”</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="article">
                  <span>
                    *Rewards will be distributed after the "Title" function is
                    opened.
                    <br />
                    *Title names are subject to change.
                    <br />
                    *Only the "Claim" will be automatically applied for the
                    title.
                  </span>
                </div>
              </div>
            </div>
            {/* フルオンチェーンゲーム『Isekai Battle』について */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>フルオンチェーンゲーム『Isekai Battle』について</span>
                </div>
                <div className="flex align-center justify-center">
                  <YouTube
                    videoId={"J9i8KHEuT2A"}
                    opts={opts}
                    className="youtube"
                  ></YouTube>
                </div>
                <div className="article">
                  <span>
                    『Isekai Battle』は、NFTコレクション「Isekai Anime
                    Characters」から派生した、Ethereum上でプレイヤーがフラグメント（NFT）を奪い合うフルオンチェーンゲームです。ゲームをプレイするには、冒険者キャラクター（NFT）が3人以上必要です。
                  </span>
                </div>
                <div className="article">
                  <span>
                    【キャラクターNFTの購入プラットフォーム】
                    <br />
                    <a
                      href="https://opensea.io/collection/isekai-battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://opensea.io/collection/isekai-battle
                    </a>
                    <br />
                    ※現在、キャラクターNFTはプラットフォーム上の二次流通でのみ購入いただけます
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>【公式Discord】</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            <a
                              href="https://discord.com/invite/isekaibattle"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://discord.com/invite/isekaibattle
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>【公式Twitter】</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            <a
                              href="https://twitter.com/Isekai_Battle"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://twitter.com/Isekai_Battle
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>About the full-on chain game "Isekai Battle</span>
                </div>
                <div className="article">
                  <span>
                    『Isekai Battle is a full-on-chain game in which players
                    compete for fragments (NFTs) on Ethereum, derived from the
                    NFT collection "Isekai Anime Characters". To play the game,
                    you need at least 3 adventurer characters (NFT).
                  </span>
                </div>
                <div className="article">
                  <span>
                    【Platforms for purchasing character NFTs】
                    <br />
                    <a
                      href="https://opensea.io/collection/isekai-battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://opensea.io/collection/isekai-battle
                    </a>
                    <br />
                    *Currently, character NFTs can only be purchased through
                    secondary distribution on the platform
                  </span>
                </div>
                <div className="article">
                  <ul>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>【Official Discord】</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            <a
                              href="https://discord.com/invite/isekaibattle"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://discord.com/invite/isekaibattle
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex">
                        <div className="flex align-start justify-start">
                          <span>【Official Twitter】</span>
                        </div>
                        <div className="flex align-start justify-start">
                          <span>
                            <a
                              href="https://twitter.com/Isekai_Battle"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://twitter.com/Isekai_Battle
                            </a>
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 運営チーム */}
            <div className="topic flex column gap-50">
              <div className="ja flex column gap-20">
                <div className="subtitle">
                  <span>運営チーム</span>
                </div>
                <div className="subsubtitle">
                  <span>
                    【⼀般社団法⼈オタクコイン協会（企画・マーケティング）】
                  </span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-50" />
                </div>
                <div className="article">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。
                  </span>
                </div>
                <div className="article">
                  <span>
                    また、アニメをより世界に広げる活動の一環として、多言語・複数メディア同時展開によるアニメスタジオインタビューを敢行。アニメ情報サイト「アニメ！アニメ！」、Facebook2,000万人登録「Tokyo
                    Otaku
                    Mode」、中国語圏大手の「Bahamut」など、世界中のアニメニュースサイトが連携した「世界が注目するアニメ制作スタジオが切り開く未来」を企画・主導し、日頃なかなかスポットが当たらない、アニメ作品を生み出すアニメスタジオ15社以上に、アニメ制作へ懸ける思いや制作の裏話を含めたインタビュー
                    （
                    <a
                      href="http://bit.ly/AnimeInterview"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://bit.ly/AnimeInterview
                    </a>
                    ）を敢行するなど、地道な活動を続けてきています。
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>【株式会社THE BATTLE（運営・開発）】</span>
                </div>
                <div className="image flex align-center justify-center w-full">
                  <img loading="lazy" src={firmLogo} alt="" className="w-70" />
                </div>
                <div className="article">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクト、および「アニメバンク」「iconee」など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
              </div>
              <div className="en flex column gap-20">
                <div className="subtitle">
                  <span>Management Team</span>
                </div>
                <div className="subsubtitle">
                  <span>
                    Otaku Coin Association, Inc.(Planning and Marketing)
                  </span>
                </div>
                <div className="article">
                  <span>
                    Since December 2017, the Otaku Coin Association, including
                    its predecessor, the Otaku Coin Preparatory Committee, has
                    been working for the development of anime culture, aiming to
                    penetrate, introduce, and utilize blockchain technology
                    within the anime industry in order to spread Japanese anime
                    culture to the world.
                  </span>
                </div>
                <div className="article">
                  <span>
                    As part of our activities to spread anime more globally, we
                    have also conducted interviews with anime studios in
                    multiple languages and in multiple media simultaneously. The
                    anime information website "Anime! Anime!” and "Tokyo Otaku
                    Mode", which has 20 million registered users on Facebook,
                    and "Bahamut", a leading animation news site in
                    Chinese-speaking countries, have collaborated to plan and
                    lead the "The Future Opened by Animation Production Studios
                    that the World is Watching". The project has been steadily
                    continuing its activities, including interviews (
                    <a
                      href="http://bit.ly/AnimeInterview"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://bit.ly/AnimeInterview
                    </a>
                    ) with more than 15 animation studios that produce animation
                    works that are not usually highlighted, including their
                    thoughts on animation production and behind-the-scenes
                    stories about their production.
                  </span>
                </div>
                <div className="subsubtitle">
                  <span>THE BATTLE Inc. (operation and development)</span>
                </div>
                <div className="article">
                  <span>
                    THE BATTLE was founded as a "web3 organization, fighting
                    against conventional wisdom". The core members have been
                    active in the blockchain area since 2018, and are involved
                    in various creative directions. Currently, we are working on
                    an anime adaptation project of the popular NFT work
                    "CryptoNinja NFT" at the web3 type anime production
                    committee method "CryptoAnime Labs", as well as planning and
                    developing a full on-chain blockchain game "Isekai Battle".
                    We are also working on several projects centered on
                    entertainment x blockchain, including an IP creation project
                    with animation studio ABC ANIMATION, INC., "Anime Bank", and
                    "iconee".
                  </span>
                </div>
              </div>
            </div>
            {/* All view */}
            <div className="toNewsPage flex align-center justify-center w-full">
              <Link to="/news">
                <span>{capitalize("all news")}</span>
              </Link>
            </div>
          </main>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spEachNews">
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed">
            <span>{capitalize("contact")}</span>
          </div>
          <div className="spEachNewsContainer flex column gap-50">
            <div className="titleContent flex column gap-30">
              {/* <div>
                <img loading="lazy" src={articleImage1} alt="" className="w-full" />
              </div> */}
              <div className="jaTitle">
                <span>
                  フルオンチェーンゲーム『Isekai
                  Battle』メインネットで探索モードが解禁。未公開の新キャラが貰える「種」集めランキングイベント開催
                </span>
              </div>
              <div className="enTitle">
                <span>
                  Full-on-chain game "Isekai Battle" exploration mode is
                  unlocked on the main net. A ranking event for collecting
                  "seeds" to receive an unrevealed new character is held.
                </span>
              </div>
            </div>
            {/* 称号「Front Runner」が貰えるスタートダッシュCPも同時開催 */}
            <article className="content flex column gap-50">
              <div className="flex align-center justify-center">
                <img loading="lazy" src={articleImage3} alt="" className="w-80" />
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>
                    称号「Front Runner」が貰えるスタートダッシュCPも同時開催
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    本日、株式会社THE
                    BATTLEが運営・開発を務める、フルオンチェーンゲーム『Isekai
                    Battle』がメインネットでローンチし、探索モードが解禁されたことを発表しました。探索解禁に合わせ、探索で拾える「LUKの種」の所持数で争うランキングイベントと、称号「Front
                    Runner」が貰えるスタートダッシュCPを開催いたします。
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle w-70 fontWeight700">
                  <span>
                    A start dash campaign to receive the title "Front Runner"
                    will also be held at the same time.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    Today, "Isekai Battle," a full-on-chain game operated and
                    developed by THE BATTLE Co,. Ltd. launched on the mainnet
                    and announced that the exploration mode has been lifted. In
                    conjunction with the lifting of the exploration ban, a
                    ranking event will be held for the number of "LUK seeds"
                    that can be picked up in the exploration, and a start dash
                    CP will be held to award the title "Front Runner".
                  </span>
                </div>
              </div>
            </article>
            {/* 「種」集めランキングイベントについて */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>「種」集めランキングイベントについて(終了済み)</span>
                </div>
                <div className="jaContent">
                  <span>
                    期間中に入手した「LUKの種」のClaim数で争うランキングイベント。イベント終了後のスナップショット時点で「LUKの種」の所持数が多い、上位10名様に未公開新キャラをプレゼントいたします。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    また、「ATKの種」「DEFの種」「LUKの種」のいずれか1つ以上所持されている方から、種ごとに1名ずつ（合計3名様）未公開新キャラクターの抽選を行います。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    ※マーケット（OpenSeaなど）での購入やトランスファー（送付）で獲得した「種」はイベント集計対象外となります。Claimで獲得した「種」の数のみがランキング・抽選対象となります
                    <br />
                    ※ランキング10位の「LUKの種」所持者が複数いる場合は抽選となります
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>■イベント期間 探索解禁 ～ 1/8(日) 23:59</span>
                  <span>■報酬 未公開新キャラ1体</span>
                </div>
                <div className="jaContent">
                  <span>
                    ※Claimで獲得した「種」を所持していると自動的に応募されます
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle fontWeight700">
                  <span>
                    About the "Seeds" Collection Ranking Event ***This event has
                    been ended***
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    A ranking event in which contestants compete for the number
                    of "LUK Seeds" Claims obtained during the event period. The
                    top 10 players with the highest number of "LUK seeds" at the
                    time of the snapshot after the event will receive an
                    undisclosed new character.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    In addition, from those who possess at least one of the "ATK
                    Seeds" "DEF Seeds" or "LUK Seeds" we will draw one winner
                    for each seed (3 winners in total) for an undisclosed new
                    character.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    *Seeds purchased on the market (OpenSea, etc.) or acquired
                    through transfers (sending) will not be counted in the
                    event. Only the number of "seeds" acquired through claiming
                    will be counted for the ranking and drawing.
                    <br />
                    *If there is more than one "LUK Seed" holder who ranks 10th,
                    the winner will be drawn by lot.
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    ■Event period: Exploration open ~ until 2023 January 8th
                    (Sun.) 23:59
                  </span>
                  <span>■Reward: One new undisclosed character</span>
                </div>
                <div className="enContent">
                  <span>
                    *You will be automatically entered if you have "seeds"
                    acquired through claiming
                  </span>
                </div>
              </div>
            </article>
            {/* スタートダッシュキャンペーンについて */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>スタートダッシュキャンペーンについて(終了済み)</span>
                </div>
                <div>
                  <img loading="lazy" src={topic301} alt="" className="w-full" />
                </div>
                <div className="jaContent">
                  <span>
                    期間中にキャラクターを「ノアの森」へ出発（ステーキング）させ、「武器」「防具」をClaimされた方が対象となります。
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>■キャンペーン期間 探索解禁 ～ 12/18(日) 23:59</span>
                  <span>■報酬 称号「Front Runner」</span>
                </div>
                <div className="jaContent flex column">
                  <span>※報酬配布は「称号」機能開放後になります</span>
                  <span>※称号名は変更される場合があります</span>
                  <span>※Claimのみで自動的に応募となります</span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle fontWeight700">
                  <span>
                    About the Start Dash Campaign ***This event has been
                    ended***
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    The campaign is open to those who have departed (staked)
                    their characters to "Noah's Forest" and claimed "Weapons"
                    and "Armor" during the period.
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    ■Campaign period: Exploration open ~ until 2022 December
                    18th (Sun.) 23:59
                  </span>
                  <span>■Reward: Title “Front Runner”</span>
                </div>
                <div className="enContent flex column">
                  <span>
                    *Rewards will be distributed after the "Title" function is
                    opened.
                  </span>
                  <span>*Title names are subject to change.</span>
                  <span>
                    *Only the "Claim" will be automatically applied for the
                    title.
                  </span>
                </div>
              </div>
            </article>
            {/* フルオンチェーンゲーム『Isekai Battle』について */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>フルオンチェーンゲーム『Isekai Battle』について</span>
                </div>
                <div className="flex align-center justify-center w-full">
                  <YouTube
                    videoId={"J9i8KHEuT2A"}
                    opts={opts}
                    className="youtube"
                  />
                </div>
                <div className="jaContent">
                  <span>
                    『Isekai Battle』は、NFTコレクション「Isekai Anime
                    Characters」から派生した、Ethereum上でプレイヤーがフラグメント（NFT）を奪い合うフルオンチェーンゲームです。ゲームをプレイするには、冒険者キャラクター（NFT）が3人以上必要です。
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>【キャラクターNFTの購入プラットフォーム】</span>
                  <span>
                    <a
                      href="https://opensea.io/collection/isekai-battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://opensea.io/collection/isekai-battle
                    </a>
                  </span>
                  <span>
                    ※現在、キャラクターNFTはプラットフォーム上の二次流通でのみ購入いただけます
                  </span>
                </div>
                <div className="jaContent flex column">
                  <span>
                    【公式Discord】
                    <a
                      href="https://discord.com/invite/isekaibattle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://discord.com/invite/isekaibattle
                    </a>
                  </span>
                  <span>
                    【公式Twitter】
                    <a
                      href="https://twitter.com/Isekai_Battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/Isekai_Battle
                    </a>
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>About the full-on chain game "Isekai Battle</span>
                </div>
                <div className="enContent">
                  <span>
                    Isekai Battle is a full-on-chain game in which players
                    compete for fragments (NFTs) on Ethereum, derived from the
                    NFT collection "Isekai Anime Characters". To play the game,
                    you need at least 3 adventurer characters (NFT).
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>[Platforms for purchasing character NFTs] </span>
                  <span>
                    <a
                      href="https://opensea.io/collection/isekai-battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://opensea.io/collection/isekai-battle
                    </a>
                  </span>
                  <span>
                    *Currently, character NFTs can only be purchased through
                    secondary distribution on the platform
                  </span>
                </div>
                <div className="enContent flex column">
                  <span>
                    [Official Discord]
                    <a
                      href="https://discord.com/invite/isekaibattle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://discord.com/invite/isekaibattle
                    </a>
                  </span>
                  <span>
                    [Official Twitter]
                    <a
                      href="https://twitter.com/Isekai_Battle"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://twitter.com/Isekai_Battle
                    </a>
                  </span>
                </div>
              </div>
            </article>
            {/* 運営チーム */}
            <article className="content flex column gap-50">
              <div className="eachJaArticle flex column gap-20">
                <div className="jaTitle">
                  <span>運営チーム</span>
                </div>
                <div className="jaContent fontWeight500">
                  <span>
                    【⼀般社団法⼈オタクコイン協会（企画・マーケティング）】
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-60" />
                </div>
                <div className="jaContent">
                  <span>
                    オタクコイン協会は、日本のアニメ文化を世界に広げるため、ブロックチェーン技術をアニメ業界内への浸透・導入・活用を目指し、前身のオタクコイン準備委員会を含め、2017年12月よりアニメ文化発展のための活動を行ってきました。
                  </span>
                </div>
                <div className="jaContent">
                  <span>
                    また、アニメをより世界に広げる活動の一環として、多言語・複数メディア同時展開によるアニメスタジオインタビューを敢行。アニメ情報サイト「アニメ！アニメ！」、Facebook2,000万人登録「Tokyo
                    Otaku
                    Mode」、中国語圏大手の「Bahamut」など、世界中のアニメニュースサイトが連携した「世界が注目するアニメ制作スタジオが切り開く未来」を企画・主導し、日頃なかなかスポットが当たらない、アニメ作品を生み出すアニメスタジオ15社以上に、アニメ制作へ懸ける思いや制作の裏話を含めたインタビュー（
                    <a
                      href="http://bit.ly/AnimeInterview"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://bit.ly/AnimeInterview
                    </a>
                    ）を敢行するなど、地道な活動を続けてきています。
                  </span>
                </div>
              </div>
              <div className="eachJaArticle flex column gap-20">
                <div className="jaContent fontWeight500">
                  <span>【株式会社THE BATTLE（運営・開発）】</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={firmLogo} alt="" className="w-80" />
                </div>
                <div className="jaContent">
                  <span>
                    THE BATTLE
                    は「従来の常識と戦う、web3組織」として設立。コアメンバーは2018年からブロックチェーン領域で活動を開始、様々なクリエイティブディレクションを手掛けております。現在はweb3型アニメ製作委員会方式「CryptoAnime
                    Labs」にて人気NFT作品「CryptoNinja
                    NFT」のアニメ化プロジェクトを行うほか、フルオンチェーンブロックチェーンゲーム「Isekai
                    Battle」の企画開発、アニメスタジオ「ABCアニメーション」とのIP創出プロジェクト、および「アニメバンク」「iconee」など、エンタメ×ブロックチェーンを主軸に複数のプロジェクトに取り組んでいます。
                  </span>
                </div>
              </div>
            </article>
            <article className="content flex column gap-50">
              <div className="eachEnArticle flex column gap-20">
                <div className="enTitle">
                  <span>Management Team</span>
                </div>
                <div className="enContent fontWeight500">
                  <span>
                    Otaku Coin Association, Inc.(Planning and Marketing)
                  </span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={OtakuCoin} alt="" className="w-60" />
                </div>
                <div className="enContent">
                  <span>
                    Since December 2017, the Otaku Coin Association, including
                    its predecessor, the Otaku Coin Preparatory Committee, has
                    been working for the development of anime culture, aiming to
                    penetrate, introduce, and utilize blockchain technology
                    within the anime industry in order to spread Japanese anime
                    culture to the world.
                  </span>
                </div>
                <div className="enContent">
                  <span>
                    As part of our activities to spread anime more globally, we
                    have also conducted interviews with anime studios in
                    multiple languages and in multiple media simultaneously. The
                    anime information website "Anime! Anime!” and "Tokyo Otaku
                    Mode", which has 20 million registered users on Facebook,
                    and "Bahamut", a leading animation news site in
                    Chinese-speaking countries, have collaborated to plan and
                    lead the "The Future Opened by Animation Production Studios
                    that the World is Watching". The project has been steadily
                    continuing its activities, including interviews (
                    <a
                      href="http://bit.ly/AnimeInterview"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://bit.ly/AnimeInterview
                    </a>
                    ) with more than 15 animation studios that produce animation
                    works that are not usually highlighted, including their
                    thoughts on animation production and behind-the-scenes
                    stories about their production.
                  </span>
                </div>
              </div>
              <div className="eachEnArticle flex column gap-20">
                <div className="enContent fontWeight500">
                  <span>THE BATTLE Inc. (operation and development)</span>
                </div>
                <div className="flex align-center justify-center">
                  <img loading="lazy" src={firmLogo} alt="" className="w-80" />
                </div>
                <div className="enContent">
                  <span>
                    THE BATTLE was founded as a "web3 organization, fighting
                    against conventional wisdom". The core members have been
                    active in the blockchain area since 2018, and are involved
                    in various creative directions. Currently, we are working on
                    an anime adaptation project of the popular NFT work
                    "CryptoNinja NFT" at the web3 type anime production
                    committee method "CryptoAnime Labs", as well as planning and
                    developing a full on-chain blockchain game "Isekai Battle".
                    We are also working on several projects centered on
                    entertainment x blockchain, including an IP creation project
                    with animation studio ABC ANIMATION, INC., "Anime Bank", and
                    "iconee".
                  </span>
                </div>
              </div>
            </article>
            {/* All view */}
            <div className="allView flex align-center justify-center w-full">
              <span>
                <Link to="/news">{capitalize("all news")}</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Topic3;
